import React from "react";
import { Header } from "../components/Landing/header";
import { Services } from "../components/Landing/services";
import { PhilosophyQuotes } from "../components/Landing/PhilosophyQuotes";
import { Contact } from "../components/Landing/contact";
import { CoverageGrid } from "../components/Landing/CoverageGrid";
import { CustomerGrid } from "../components/Landing/CustomerGrid";
import JsonData from "../data/data.json";
import "../styles/Landing.css";
import TryNow from "../components/Landing/TryNow";
import LandingSection from "../components/Landing/LandingSection";
import UserTestimonials from "../components/Landing/UserTestimonials";

const Landing = () => {
  return (
    <div className="landing-page">
        <Header data={JsonData.Header} />
        <br />
        <LandingSection
            variant="split"
            title="What does Scuttlebutt do?"
            subtitle="Scuttlebutt helps you diligence companies by finding online commentary from customers, competitors, and employees. It can help you answer questions like:"
            bulletPoints={[
                "Do customers actually like the product, or are they stuck with it?",
                "Is the competition gaining ground?",
                "Could the company raise prices without losing customers?",
                "How hard would it be for users to migrate away?"
            ]}
            bottomText="The tool combs the internet to find millions of posts, comments, videos, reviews, blogs, and more, then turns this raw data into content you can actually use in an IC deck or show to a company you're advising."
            buttonText="Book Demo"
            buttonLink="/book-demo"
            buttonVariant="square"
            image="/img/demo/sb-demo-search.png"
            imageMaxWidth="550px"
            id="about"
        />
        <br />

        <LandingSection
            title="Track changes in the competitive landscape"
            subtitle="Scuttlebutt measures an estimated net promoter score (eNPS) based on user commentary, creating a dynamic picture of company performance over time. While commissioned surveys give only a snapshot of current customer opinion, Scuttlebutt can help you understand the whole history of an industry."
            buttonText="View Case Study"
            buttonLink="/case-study/NPS"
            image="/img/demo/sb-demo-cursor.png"
            mobileImage="/img/demo/sb-demo-cursor-mobile.png"
            imageMaxWidth="80%"
            id="industry-nps"
        />
        <br />

        <LandingSection
            title="Spot customer trends - before they hit Bloomberg"
            subtitle="Scuttlebutt can measure customer switching based on online posts, giving you advanced warning about shifts in the competitive landscape."
            buttonText="View Case Study"
            buttonLink="/case-study/leaver-joiner"
            image="/img/demo/sb-demo-leaver-joiner.png"
            mobileImage="/img/demo/sb-demo-leaver-joiner-mobile.png"
            imageMaxWidth="80%"
            id="customer-switching"
        />
        <br />

        <LandingSection
            title="Understand customer price sensitivity"
            subtitle="Scuttlebutt analyzes customer discussions about pricing and alternatives, helping you dig into customer willingness to pay and opportunities to capture more value."
            buttonText="View Case Study"
            buttonLink="/case-study/cursor-pricing"
            image="/img/demo/sb-demo-wtp.png" 
            mobileImage="/img/demo/sb-demo-wtp-mobile.png"
            imageMaxWidth="70%"
            id="pricing-insights"
        />
        <br />
        <CoverageGrid logoList={JsonData.CoverageLogos} header="View Examples"/>
        <UserTestimonials />
        <PhilosophyQuotes data={JsonData.Testimonials}/>
        <LandingSection
            variant="split"
            title="Ready to get started with Scuttlebutt?"
            subtitle="Sign up for a quick tour of the platform and find out if it might be helpful for your coverage."
            buttonText="Book a Demo"
            buttonLink="/book-demo"
            buttonVariant="square"
            id="get-started"
        />
        <div style={{ padding: '3rem 0'}}>
        </div>
    </div>
  );
};

export default Landing;
