// Component that shows the user for a folder, and allows the user to change the access level of each user
// For a given user, takes a name, email, and role - optionally includes a profile picture
// If no profile picture, show an icon with the first letter of the name

import React, { useState } from 'react';
import { useApiDataContext } from '../ApiDataContext';

const FolderAccessRow = ({name, email, profile_picture, role, profilePicture, searchResult, folderId, userId}) => {
    const [accessLevel, setAccessLevel] = useState(role);
    const { getPeopleWithAccess, handleAccessChange } = useApiDataContext();

    const handleChange = async (event, userId, folderId) => {            
        handleAccessChange(event, userId, folderId);
        setAccessLevel(event.target.value);
    }

    return (
        <div className='folder-access-row'>
            <div className='folder-access-row-left'>
                {profilePicture ? 
                    <img src={profilePicture} className='folder-access-profile-picture'/>
                    :
                    <div className='folder-access-profile-picture'>{name[0]}</div>
                }
                <div className='folder-access-info'>
                    <h4 className='folder-person-name'>{name}</h4>
                    <p>{email}</p>
                </div>
            </div>
            {searchResult && 
                <div className='folder-access-row-right'>
                    <select value={accessLevel} onChange={(event) => handleChange(event, userId, folderId)}>
                        <option value='Owner'>Owner</option>
                        <option value='Editor'>Editor</option>
                        <option value='Viewer'>Viewer</option>
                        <option value='remove'>Remove</option>
                    </select>
                </div>
            }
        </div>
    );
}

export default FolderAccessRow;