import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth, serverAddress } from '../components/AuthContext';
import { useApiDataContext } from '../components/ApiDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import PostDisplay from '../components/PostDisplay';
import NPSPlot from '../components/NPSPlot';
import LeaverJoinerPlot from '../components/LeaverJoinerPlot';
import PricingPlot from '../components/PricingPlot';
import ManagementTeam from '../components/ManagementTeam';

const CompanyPage = () => {
    const { id } = useParams();
    const [companyData, setCompanyData] = useState({});
    const [onWatchlist, setOnWatchlist] = useState(false);
    const { userId, token, serverAddress, flaskServerAddress } = useAuth();
    const {isMobile} = useApiDataContext();

    const [showProducts, setShowProducts] = useState(true);
    const [showPosts, setShowPosts] = useState(false);

    const [relevantPosts, setRelevantPosts] = useState([]); // json data: ['post': {}, 'post_description': {}

    const [isLoading, setIsLoading] = useState(true);
    const [descriptionLoading, setDescriptionLoading] = useState(false);

    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        if (products.length > 0) {
            setSelectedProduct(products[0]);
        }
    }, [products]);


    const fetchCompanyData = async () => {
        setIsLoading(true);
        const response = await fetch(serverAddress + 'company', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                id: id,
                user_id: userId,
                token: token,
            }),
        });
        const data = await response.json();
        // if (!data.data.company_description) {
        //     generateCompanyDetails();
        // }
        setCompanyData(data.data);
        setOnWatchlist(data.data.watchlist || false);
        setIsLoading(false);
        // Updated code to extract products
        const extractedProducts = data.data.topics
            // .filter(topic => topic.topic.)
            .map(topic => ({
                name: topic.topic,
                description: topic.additional_text || 'No description available',
                posts: topic.posts
            }));
        console.log('extracted products:', extractedProducts);
        setProducts(extractedProducts);

        // Extract relevant posts
        const allPosts = extractedProducts.flatMap(product => product.posts);
        const relevantPosts = allPosts.filter(post => 
            post.post_assessments.some(assessment => 
                assessment.type === 'relevance' && assessment.rating >= 8
            )
        ).sort((a, b) => new Date(b.time) - new Date(a.time));
        console.log('relevant posts:', relevantPosts);
        setRelevantPosts(relevantPosts);
    }

    // TODO: if there is no company description, hit the server and ask for one?
    // route: /generate-company-details/3fff1cb5-0d02-471d-a7ea-ec67f420efea/
    // const generateCompanyDetails = async () => {
    //     setDescriptionLoading(true);

    //     const response = await fetch(flaskServerAddress + 'generate-company-details/' + id);
    //     if (response.status == 200) {
    //         const data = await response.json();
    //         if (data && data.company_description) {
    //             setCompanyData(prevData => ({...prevData, company_description: data.company_description}));
    //             setDescriptionLoading(false);
    //         }
    //     }

    // }

    const handleWatchlist = async () => {
        const newWatchlistStatus = !onWatchlist;
        setOnWatchlist(newWatchlistStatus);

        const response = await fetch(serverAddress + 'toggle-watchlist', {
            method: 'POST',
                        headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                company_id: id,
                user_id: userId,
                on_watchlist: !newWatchlistStatus,
                token: token,
            }),
        });
        const data = await response.json();
    }

    useEffect(() => {
        fetchCompanyData();
    }, [userId]);

    useEffect(() => {
        console.log('company data:', companyData);
    }, [companyData]);

    useEffect(() => {
        if (companyData.name) {
            document.title = companyData.name;
        }
        
        // Optionally, you can reset the title when the component is unmounted
    }, [companyData.name]);  // Empty dependency array ensures this runs only on component mount
      
    const handleProductClick = (product) => {
        setSelectedProduct(selectedProduct === product ? null : product);
        console.log('selected product:', product);
    };

    return (
        <div className='scene centered'>
            {isLoading && <div className='progress-indicator'> <CircularProgress/> </div>}
            {!isLoading &&
            <div className='company-page'>
                <div className='company-header'>
                    <div>
                        <h1 className='company-title'>{companyData.name}</h1>
                        <div className='company-info'>
                            {companyData.ticker && <span>Ticker: {companyData.ticker}</span>}
                            {companyData.cik && <span> | CIK: {companyData.cik}</span>}
                        </div>
                    </div>
                    <button 
                        className={`watchlist-button ${onWatchlist ? 'on-watchlist' : ''}`}
                        onClick={handleWatchlist}
                    >   
                        <VisibilityIcon />
                        {onWatchlist ? 'On Watchlist' : 'Add to Watchlist'}
                    </button>
                </div>

                {companyData.company_description && companyData.company_description.text !== '' && 
                    <div className='company-description'>
                        <h3>Company Description</h3>
                        {companyData.company_description.text && <p>{companyData.company_description.text}</p>}
                        {companyData.business_section?.other && (
                            <a
                                href={`https://www.bamsec.com/filing/${companyData.business_section.other.replace(/-/g, '')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="read-more-link"
                            >
                                Read More
                            </a>
                        )}
                    </div>
                }

                {descriptionLoading && <div className='progress-indicator'> <CircularProgress/> </div>}

                {showProducts && 
                    <div className='product-section'>
                        <h3>Product Analysis</h3>
                        {products.map((product, index) => (
                            <div className='product-item' key={index}>
                                <h4 
                                    className='product-name'
                                    onClick={() => handleProductClick(product)}
                                >
                                    {product.name}
                                </h4>
                                {product.description && <p className='product-description'>{product.description}</p>}
                                {selectedProduct === product && product.posts && product.posts.length > 0 && (
                                    <div className='product-charts'>
                                        {product.posts[0].post_assessments.some(assessment => assessment.type === 'NPS') && (
                                            <div className='chart-container'>
                                                <h5>Net Promoter Score</h5>
                                                <br />
                                                <NPSPlot posts={product.posts} stat_name='NPS' title="Net Promoter Score" />
                                            </div>
                                        )}
                                        {product.posts[0].post_assessments.some(assessment => assessment.type === 'leaver_joiner') && (
                                            <div className='chart-container'>
                                                <h5>Customer and Competitor Churn</h5>
                                                <LeaverJoinerPlot posts={product.posts} stat_name='leaver_joiner' titles={["Customer Churn", "Competitor Churn", "Competitive Analysis"]} product_name={product.name} />
                                            </div>
                                        )}
                                        {product.posts[0].post_assessments.some(assessment => assessment.type === 'pricing') && (
                                            <div className='chart-container'>
                                                <h5>Pricing Analysis</h5>
                                                <PricingPlot posts={product.posts} stat_name='pricing' title="Price Sensitivity" />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                }

                <div className='collapsible-section-container'>
                    <div className='company-products-section'>
                        <div className='company-products-header small-title'>
                            Recent Scuttlebutt
                            <div className='show-hide-products gray-button no-background' onClick={() => setShowPosts(!showPosts)}>
                                {showPosts ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                            </div>
                        </div>
                        {showPosts && 
                            <div> 
                                <PostDisplay data={relevantPosts} />
                            </div>
                        }
                    </div>
                </div>

                <ManagementTeam managerRoles={companyData.manager_roles} />

            </div>
            }
        </div>
    )
}

export default CompanyPage;
