import React, { useEffect } from 'react';

const BookDemo = () => {
    useEffect(() => {
        // Load Calendly widget script
        const script = document.createElement('script');
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup script when component unmounts
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className="scene centered">
            <div className="calendly-inline-widget" 
                data-url="https://calendly.com/acastle-scuttlebuttresearch/scuttlebutt-demo" 
                style={{ 
                    minWidth: "320px",
                    height: "90vh",
                    width: "100%",
                    maxWidth: "1200px"
                }}>
            </div>
        </div>
    );
};

export default BookDemo; 