// component that displays a tag
// It needs to have an "X" button to remove the tag
// It needs to have a tag name

import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const Tag = ({tag, handleRemoveTag, isCompanyTag=true}) => {
    const handleTagClick = () => {
        console.log('tag clicked', tag);
        if (isCompanyTag) {
            window.open(`/company/${tag.id}`, '_blank');
        }
    };

    return (
        <div className='tag' onClick={handleTagClick} style={{ cursor: tag.company_id ? 'pointer' : 'default' }}>
            {tag.ticker ? 
                <span className='tag-name'>{tag.ticker}</span> :
                <span className='tag-name'>{tag.name}</span>
            }
            <div className='tag-delete-button-container' onClick={(e) => e.stopPropagation()}>
                <CloseIcon onClick={() => handleRemoveTag(tag)} className='tag-delete-button'/>
            </div>
        </div>
    )
}

export default Tag;