import React, { useState, useRef, useContext, useEffect } from 'react';
import DOMPurify from 'dompurify';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { formatDate } from '../functions/DateFormatters';
import { useApiDataContext } from './ApiDataContext';
import { useAuth } from './AuthContext';
import supabase from './supabaseClient';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { ModalContext } from "../App";
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import IosShareIcon from '@mui/icons-material/IosShare';
import SocialCard from './SocialCard';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

// Add this helper function at the top of the component
const sanitizePreviewText = (text) => {
    if (!text) return '';
    
    // Create a temporary div to decode HTML entities
    const temp = document.createElement('div');
    temp.innerHTML = text;
    
    // Get text content (removes HTML tags) and decode entities
    let cleanText = temp.textContent || temp.innerText;
    
    // Remove extra whitespace, newlines, and other special characters
    cleanText = cleanText
        .replace(/\s+/g, ' ')        // Replace multiple spaces/newlines with single space
        .replace(/[^\w\s.,!?-]/g, '') // Remove special characters except basic punctuation
        .trim();
    
    return cleanText;
};

function PostV2(props) {
    const { post, isNested, ratingThresholds, ratingDisplayName, query, isTopLevel } = props;
    const [showChildren, setShowChildren] = useState(false);
    const { fetchBookmarks, bookmarkFolders, folders, handleBookmark, orderedFolders, useHash, handleStar } = useApiDataContext();
    const { userId } = useAuth();
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [menuHovered, setMenuHovered] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);
    const [bookmarkFolder, setBookmarkFolder] = useState(null);
    const { openNewFolderModal } = useContext(ModalContext);
    const [isDeleted, setIsDeleted] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [foldersInOrder, setFoldersInOrder] = useState([]);
    const [modifiedText, setModifiedText] = useState('');
    const [showRatingDetails, setShowRatingDetails] = useState(false);
    const [isStarred, setIsStarred] = useState(post.is_starred || false);
    const [expanded, setExpanded] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const deleteMessage = useRef('Post Deleted! We will use this feedback to get better at finding relevant content.');

    useEffect(() => {
        setBookmarked(checkBookmark());
    }, [bookmarkFolders]);

    useEffect(() => {
        if (orderedFolders && orderedFolders.length > 0) {
            if (bookmarkFolder) {
                moveFolderToFront();
            } else {
                setFoldersInOrder(orderedFolders);
            }
        }
    }, [orderedFolders, bookmarkFolder]);

    useEffect(() => {
        if (post.text) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = post.text;

            const processNode = (node) => {
                if (node.nodeType === Node.TEXT_NODE) {
                    // Process text nodes
                    const urlRegex = /\b(?:https?:\/\/\S+)/gi;
                    const processedText = node.textContent.replace(urlRegex, url => {
                        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                    });
                    
                    if (processedText !== node.textContent) {
                        const span = document.createElement('span');
                        span.innerHTML = processedText;
                        node.parentNode.replaceChild(span, node);
                    }
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    // Recursively process child nodes, but skip 'a' tags
                    if (node.tagName !== 'A') {
                        Array.from(node.childNodes).forEach(processNode);
                    }
                }
            };

            Array.from(tempDiv.childNodes).forEach(processNode);

            // If there's a query, apply highlighting
            if (query && query.length > 2) {
                const queryTerms = query.split(' ').map(term => term.toLowerCase());
                const queryRoots = queryTerms.map(term => term.toLowerCase().replace(/(ing|ed|s|er|ation|ers|es)$/i, ''));

                const highlightNode = (node) => {
                    if (node.nodeType === Node.TEXT_NODE) {
                        // Split text into sentences
                        const sentences = node.textContent.split(/([.!?]+\s+|\n+)/g);
                        let highlightedText = '';
                        let shouldHighlight = false;

                        sentences.forEach(sentence => {
                            // Check if the sentence contains any query terms
                            const sentenceLower = sentence.toLowerCase();
                            const containsQueryTerm = queryRoots.some(term => 
                                sentenceLower.match(new RegExp(`\\b${term}(?:ing|ed|s|er|ation|ers|es)?\\b`, 'i'))
                            );

                            if (containsQueryTerm) {
                                highlightedText += `<span class="highlight">${sentence}</span>`;
                                shouldHighlight = true;
                            } else {
                                highlightedText += sentence;
                            }
                        });

                        if (shouldHighlight) {
                            const span = document.createElement('span');
                            span.innerHTML = highlightedText;
                            node.parentNode.replaceChild(span, node);
                        }
                    } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== 'A') {
                        Array.from(node.childNodes).forEach(highlightNode);
                    }
                };

                Array.from(tempDiv.childNodes).forEach(highlightNode);
            }

            setModifiedText(tempDiv.innerHTML);
        }
    }, [query, post.text]);

    const getRatingClass = (rating) => {
        if (rating <= ratingThresholds.low) return 'low';
        if (rating > ratingThresholds.high) return 'high';
        return 'med';
    };

    const handleMouseEnter = (event) => {
        const rect = event.target.getBoundingClientRect();
        const scrollBarOffset = 16;
        const yOffset = -2; // Small offset to position below the button
        
        // Calculate x position relative to viewport width
        const xPosition = window.innerWidth - (rect.right + scrollBarOffset);
        
        // Use viewport-relative coordinates
        const yPosition = rect.bottom + yOffset;
        
        setPosition({ 
            x: Math.max(0, xPosition),
            y: yPosition
        });
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    }

    const handleMenuMouseEnter = () => {
        setMenuHovered(true);
    };

    const handleMenuMouseLeave = () => {
        setMenuHovered(false);
        setIsHovered(false);
    };

    function checkBookmark() {
        if (!bookmarkFolders) return false;
        if (bookmarkFolders.length === 0) return false;
        if (post.id in bookmarkFolders) {
            setBookmarkFolder(bookmarkFolders[post.id]);
            return true;
        }
        return false;
    }

    function moveFolderToFront() {
        const foldersIn = [...orderedFolders];
        const bookmarkedFolder = foldersIn.find(folder => folder.folder_id === bookmarkFolder);
        if (bookmarkedFolder) {
            foldersIn.splice(foldersIn.indexOf(bookmarkedFolder), 1);
            foldersIn.unshift(bookmarkedFolder);
        }
        setFoldersInOrder(foldersIn);
    }

    const handleBookmarkClick = async (folder) => {
        if (bookmarked && folder === bookmarkFolder) {
            setBookmarked(false);
        } else if (bookmarked && folder === null) {
            setBookmarked(false);
        } else if (bookmarked && folder !== bookmarkFolder) {
            setBookmarkFolder(folder);
        } else {
            setBookmarked(true);
            setBookmarkFolder(folder);
        }
        handleBookmark(post, folder, bookmarked, bookmarkFolder);
    }

    const handleDelete = async () => {
        setIsDeleted(true);
        await supabase
            .from('deleted_posts')
            .upsert({ user_id: userId, post_id: post.id })
            .select();
        await supabase
            .from('bookmarks')
            .delete()
            .match({ user_id: userId, id: post.id })
            .select();
        fetchBookmarks();
    }

    const undoDelete = async () => {
        setIsDeleted(false);
        await supabase
            .from('deleted_posts')
            .delete()
            .match({ user_id: userId, post_id: post.id })
            .select();
    }

    const handleNewFolder = async () => {
        openNewFolderModal(post.id);
    }

    const handleStarClick = async (id) => {
        setIsStarred(!isStarred);
        handleStar(id, isStarred);
    }

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleShowParent = (e) => {
        e.preventDefault();
        if (post.parent_id) {
            window.open(`/post/${post.parent_id}`, '_blank');
        }
    };

    const toggleChildren = (e) => {
        e.preventDefault();
        setShowChildren(!showChildren);
    };

    const renderText = () => {
        if (!modifiedText) return null;

        const charLimit = 800;
        const displayAmount = 400;

        if (modifiedText.length <= charLimit || expanded) {
            return (
                <div
                    className="post-text"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modifiedText) }}
                    onClick={(e) => {
                        if (e.target.tagName === 'A') {
                            e.preventDefault();
                            window.open(e.target.href, '_blank');
                        }
                    }}
                />
            );
        }

        let truncatedText = modifiedText.substring(0, displayAmount);
        const lastSpaceIndex = truncatedText.lastIndexOf(' ');

        if (lastSpaceIndex > 0) {
            truncatedText = truncatedText.substring(0, lastSpaceIndex);
        }

        truncatedText = truncatedText.trim() + '...';

        return (
            <>
                <div className="post-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncatedText) }} />
                <button className="read-more-button" onClick={toggleExpand}>Read more</button>
            </>
        );
    };

    const renderChildPosts = () => {
        if (!post.children || post.children.length === 0) return null;
        
        return (
            <div className="child-posts">
                {post.children.map(childPost => (
                    <PostV2
                        key={childPost.id}
                        post={childPost}
                        showBookmarkButton={false}
                        isNested={true}
                        ratingThresholds={ratingThresholds}
                        ratingDisplayName={ratingDisplayName}
                        query={query}
                    />
                ))}
            </div>
        );
    };

    const handleCollapseClick = () => {
        setIsCollapsed(!isCollapsed);
    };

    // Add scroll handler effect
    useEffect(() => {
        const handleScroll = () => {
            if (!menuHovered) {
                setIsHovered(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        
        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [menuHovered]);  // Only re-run if menuHovered changes

    const handleDeleteRating = async () => {
        try {
            console.log('Deleting rating with:', {
                id: post.id,
                type: post.ratingName,
                topic_id: post.topic_id
            });
            const { error } = await supabase
                .from('post_assessments')
                .delete()
                .match({ 
                    id: post.id,
                    type: post.ratingName,
                    topic_id: post.topic_id 
                });

            if (error) throw error;
            
            // Hide the rating details after deletion
            setShowRatingDetails(false);
            
            // You might want to trigger a refresh of the post data here
            // depending on your app's architecture
        } catch (error) {
            console.error('Error deleting rating:', error);
        }
    };

    return (
        <div className={isTopLevel ? "post-wrapper" : ""}>
            {isDeleted ? (
                <div className='deleted-post-container'>
                    <div className='deleted-post-message'>
                        <p>Post Deleted! We will use this feedback to get better at finding relevant content.</p>
                    </div>
                    <button className='undo-delete-button' onClick={undoDelete}>
                        <RestoreIcon />
                        <span>Undo</span>
                    </button>
                </div>
            ) : (
                <div className={`post ${isNested ? 'nested-post' : ''} ${isCollapsed ? 'collapsed' : ''}`} key={post.id} id={post.id}>
                    <div className="collapse-handle" onClick={handleCollapseClick}>
                        {isCollapsed ? <div style={{paddingTop: isNested ? '10px' : '10px'}}><ExpandMoreIcon /></div> : <div style={{paddingTop: isNested ? '5px' : '18px'}}><ExpandLessIcon /></div>}
                    </div>
                    
                    {isCollapsed ? (
                        <div className="collapsed-preview">
                            <span className="collapsed-date post-time">{formatDate(post.time)}</span>
                            <span className="collapsed-text">
                                {sanitizePreviewText(post.title || post.text)?.substring(0, 105) || 'No content'}
                            </span>
                        </div>
                    ) : (
                        <>
                            {post.ratingName && (
                                <div className="post-rating-container">
                                    <div className='split'>
                                        <p className={`post-rating ${getRatingClass(post.rating)}`}
                                            onClick={() => setShowRatingDetails(!showRatingDetails)}
                                        >
                                            {ratingDisplayName || post.ratingName}: {post.rating !== null ? `${post.rating} / 10` : 'N / A'}
                                        </p>
                                    </div>
                                    {showRatingDetails && (
                                        <>
                                            <p className="post-rating-explanation">{post.explanation}</p>
                                            <p className="post-rating-confidence">Confidence: {post.confidence * 10}%</p>
                                            {userId === 'ce690a5b-f97d-448c-aa73-b8677f476c86' && (
                                                <button 
                                                    onClick={handleDeleteRating}
                                                    className="delete-rating-button"
                                                    style={{ 
                                                        marginTop: '8px',
                                                        padding: '4px 8px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '4px',
                                                        background: '#ff4444',
                                                        color: 'white',
                                                        border: 'none',
                                                        borderRadius: '4px',
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <DeleteIcon fontSize="small" />
                                                    Delete Rating
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}

                            <div className='post-header'>
                                <p className="post-time"><em>{formatDate(post.time)}</em></p>
                                <div>
                                    <div className="post-bookmark">
                                        <button className="post-button faded-gray-button" onClick={handleDelete}>
                                            <CloseIcon className='delete-icon' />
                                        </button>

                                        <button className='post-button faded-gray-button' onClick={() => setShowShareMenu(true)}>
                                            <IosShareIcon />
                                        </button>

                                        <button className="post-button"
                                            onClick={userId ? () => handleBookmarkClick(null) : () => openNewFolderModal(true)}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            {bookmarked ? <BookmarkAddedIcon className='bookmarked-icon' /> : <BookmarkBorderIcon />}
                                        </button>
                                        <button className='post-button' onClick={() => handleStarClick(post.id, null)}>
                                            {isStarred ? <StarIcon className='star-icon' /> : <StarBorderIcon className='star-icon' />}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="post-container">
                                <div className="split">
                                    {post.subtype && <p className="post-subtype">{post.type === 'reddit' ? `r/${post.subtype}` : post.subtype}</p>}
                                </div>
                                {post.title && <p className="post-title">{post.title}</p>}
                                {renderText()}
                            </div>

                            <div className="post-details">
                                <a href={post.url} className="post-context-link" target='_blank' rel='noreferrer'>Source</a>
                                <a href={`/post/${post.id}`} className="post-context-link" target='_blank' rel='noreferrer'>See Context</a>
                                {props.show_similar_post_button === true && <a href={`/similar-posts/${post.id}`} className="post-context-link" target='_blank'>Similar Posts</a>}
                                {isTopLevel && post.parent_id && (
                                    <a 
                                        href={`/details/${post.parent_id}`}
                                        className="post-context-link" 
                                        onClick={handleShowParent}
                                    >
                                        Show Parent
                                    </a>
                                )}
                                {post.children && post.children.length > 0 && (
                                    <a 
                                        href="#"
                                        className="post-context-link" 
                                        onClick={toggleChildren}
                                    >
                                        {showChildren ? 'Hide' : 'View'} Replies ({post.children.length})
                                    </a>
                                )}
                            </div>

                            {showChildren && renderChildPosts()}
                        </>
                    )}
                </div>
            )}

            {(isHovered || menuHovered) && userId && (
                <div className="bookmark-menu"
                    style={{ top: position.y, right: position.x }}
                    onMouseEnter={handleMenuMouseEnter}
                    onMouseLeave={handleMenuMouseLeave}
                >
                    <ul>
                        {foldersInOrder.map((folder) => (
                            <li key={folder.folder_id}>
                                <button className={(bookmarked && bookmarkFolder === folder.folder_id) ? "bookmarked" : ""}
                                    onClick={() => handleBookmarkClick(folder.folder_id)}
                                >
                                    {folder.folder_name}
                                    {bookmarked && bookmarkFolder === folder.folder_id &&
                                        <CheckIcon className='bookmark-check' fontSize='medium' />
                                    }
                                </button>
                            </li>
                        ))}
                        <li>
                            <div className='add-folder-button-container'>
                                <button
                                    className='add-folder-button'
                                    onClick={handleNewFolder}
                                >
                                    <AddIcon />
                                    New
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            )}

            {showShareMenu && (
                <div className='share-background'>
                    <div className='share-modal'>
                        <div className='share-post-modal-header-bar'>
                            <h4>Share Post</h4>
                            <span className='share-modal-close' onClick={() => setShowShareMenu(false)}>&times;</span>
                        </div>
                        <div className='share-post-modal-body'>
                            <SocialCard post={post} setImageUrl={() => {}} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

PostV2.defaultProps = {
    show_similar_post_button: true,
    ratingThresholds: {
        low: 6,
        high: 8
    },
    isTopLevel: false
}

export default PostV2;
