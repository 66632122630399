import React, {useContext, useState} from 'react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useAuth } from '../components/AuthContext';
import { Auth } from '@supabase/auth-ui-react'
import supabase from '../components/supabaseClient';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderBarPlain from '../components/HeaderBarPlain';

function WITGSignUp() {
    const { session } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [gradYear, setGradYear] = useState("");
    const [interests, setInterests] = useState("");

    const handleSubmit = () => {
        if (name && gradYear) {
            supabase.from('intake_data').upsert({
                full_name: name,
                personal_email: email,
                grad_year: gradYear,
                interests: interests
            }).then(data => {
                console.log("Successfully submitted form!", data);
                navigate('/search');
            })
            .catch((error) => {
                console.log("Error submitting form!", error);
            })
        }
        else {
            alert("Please fill out your name and grad year!");
        }
    }



    if (!session) {
        return (
            <div>
                <HeaderBarPlain navIconLink="/witg" />
                <div className="centered-container">
                    <div className="standard-width" style={{padding: "10px"}}>

                        <br />
                        <div className="centered-container">
                            <h2>Welcome to Scuttlebutt!</h2>
                            <img src="/img/icons/WITG_SB.png" alt="WITG x Scuttlebutt" style={{width: "180px"}} />
                        </div>
                        <br />

                        <p style={{fontStyle: "italic"}}> In partnership with WITG, Scuttlebutt is offering free beta access to Wharton students.</p>
                        <p style={{fontStyle: "italic"}}> Sign up now and start perfecting your pitches!</p>

                        <div>
                            <Auth 
                                supabaseClient={supabase} 
                                view="sign_up"
                                localization={{
                                    variables: {
                                    sign_in: {
                                        email_label: 'Your email address',
                                        password_label: 'Your strong password',
                                    },
                                    },
                                }}
                                appearance={{ 
                                    theme: ThemeSupa, 
                                    variables: {
                                        default: {
                                            colors: {
                                            brand: '#1042c1',
                                            brandAccent: '#1042c1',
                                            },
                                        },
                                        },
                                }}
                                providers={[]} 
                            />
                        </div>
                        <br />
                        
                    </div>
                </div>
            </div>
        )
        }
    else {
        return (
            <div>
                <HeaderBarPlain navIconLink="/witg" />
                <div className="centered-container witg-sign-up">
                    <div className="standard-width centered-container" style={{padding: "10px"}}>
                        <br />

                        <div className="centered-container">
                            <h2>Thanks for signing up!</h2>
                            <p> Before you get started, mind giving us a few details to help us tailor our product to your needs?</p>
                        </div>
                        <br />

                        
                        <div className="sign-up-form">
                            <input type="sign-up-form" placeholder="Full name" value={name} onChange={(e) => setName(e.target.value)} />
                            <input type="sign-up-form" placeholder="Personal email (optional)" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <select className="sign-up-form" defaultValue="" value={gradYear} onChange={(e) => setGradYear(e.target.value)}>
                                <option value="" disabled>Expected Grad Year</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="other">Other</option>
                            </select>
                            <p>If you want to tell us about any stocks on your radar, we can help you prepare for your pitch.</p>
                            <input type="sign-up-form" value={interests} onChange={(e) => setInterests(e.target.value)} placeholder="Are there any names you're looking at?" />
                        </div>



                        <br/>
                        <button className="blue-button" style={{width: "180px", height: "40px", fontWeight: "700"}} onClick={handleSubmit}>Get Started</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default WITGSignUp