// Component that takes a route with a folderId and displays the posts in that folder, along with the summary of the folder.
import React, {useState, useEffect, useContext} from 'react';
import FolderPostDisplay from '../components/FolderViewer/FolderPostDisplay';
import FolderSummary from '../components/FolderViewer/FolderSummary';
import { useApiDataContext } from '../components/ApiDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import FolderFolders from '../components/FolderFolders';
import FolderShareButton from '../components/FolderViewer/FolderShareButton';

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { ModalContext } from "../App";
import SmoothScroll from "smooth-scroll";
import BackToTop from '../components/BackToTop';
import CitationScroller from '../components/CitationScroller';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AskQuestion from '../components/AskQuestion';

const FolderPage = ({showBookmarkButton}) => {
      
    const { folderId } = useParams();
    const {userId} = useAuth();
    const [newSummaryLoading, setNewSummaryLoading] = useState(false);
    const modalContext = useContext(ModalContext);
    const {openNewFolderModal} = useContext(ModalContext);

    const { summarizeFolder, folders, getDescendants, getFolder,  
        getChildrenDetails, bookmarksLoading, checkAccess
    } = useApiDataContext();

    // Different isMobile here, since I want to be more aggressive with switching to icons
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1000);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const [folder, setFolder] = useState(null);

    const [role, setRole] = useState('');
    const [canEdit, setCanEdit] = useState(false);
    const [isSharedFolder, setIsSharedFolder] = useState(false);
    const [childrenFolders, setChildrenFolders] = useState([]);
    const [parentId, setParentId] = useState(null);
    const [summary, setSummary] = useState(null);
    const [sources, setSources] = useState([]);

    const [showQuestionAnswer, setShowQuestionAnswer] = useState(false);

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();




    useEffect(() => {
        if (!folders || !folderId) return; // if folders is not loaded yet, or if folderId is not set, return
        const folder = folders.find(folder => folder.folder_id === folderId);
        console.log('folder: ', folder);

        if (!folder) {
            console.log('folder not found in user folders');
            console.log('bookmarksLoading: ', bookmarksLoading);
            if (bookmarksLoading) return;
            console.log('folder not found in user folders');
            handleNotMyFolder(folderId);
            return;
        }
        
        processFolder(folder, true);

    }, [folders, folderId, bookmarksLoading]);

    const processFolder = (folder, isMine) => {
        setFolder(folder);
        setRole(folder.role);
        setCanEdit(folder.role === 'Owner' || folder.role === 'Editor');
        setIsSharedFolder(folder.role === 'Viewer');
        setParentId(folder.parent);

        // if this folder is mine, then I handle children by looking in the folders array I already have
        if (isMine) {
            console.log('my folder - getting children from folders array')
            const childIds = folder.children || [];
            if (childIds.length > 0){
                const children = folders.filter(folder => childIds.includes(folder.folder_id));
                setChildrenFolders(children);
            } else{
                setChildrenFolders([]);
            }
        } else {
            console.log('not my folder - need to get the children');
            // I need to get the names of the children folders and check access for them
            getChildrenDetails(folderId).then(res => {
                console.log('children: ', res);
                setChildrenFolders(res);
            });
        }

            
        setSummary(folder.summary);
        console.log('folder.summary: ', folder.summary);
        if (folder.summary && folder.summary.summary) {
            const idRegex = /#([\w-]+)/g;
            const matches = folder.summary.summary.match(idRegex);
            const ids = matches ? matches.map(match => match.slice(1)) : [];
            console.log('Extracted IDs:', ids);
            setSources(ids);
        }
        
        
        setLoading(false);
    }


    const handleNotMyFolder = async (folderId) => {
        // When the folder ID is not found in the user's folders,
        // we will use this to get the content.
        // Will need to check if the user has access to the folder (basically, just if it has link sharing)
        // Then, can get the posts using getAllPostsInFolder
        console.log('trying to get access to folderId: ', folderId);
        checkAccess(folderId).then(res => {
            console.log('link access: ', res);
            if (!res || res === 'none') {
                setRole('none');
                setLoading(false);
                return;
            }
            if (res === 'Viewer' || res === 'Editor' || res === 'Owner') {
                getFolder(folderId).then(res => {
                    console.log('folder found: ', res);
                    processFolder(res, false);
                });
            }

        });
    }


    const handleBack = () => {
        if (parentId) {
            navigate(`/folder/${parentId}`);
        } else {
            navigate(`/bookmarks`); // Navigate back to the home page
        }
    }

    const handleSettings = () => {
        navigate(`/folder-settings/${folderId}`);
    }

    const handleSummarize = async () => {
        setNewSummaryLoading(true);
        const res = await summarizeFolder(folderId);
        if (!res) {
            console.log('Failed to generate summary. Please try again later.');
            setNewSummaryLoading(false);
            return;
        }
        setSummary(res);
        setNewSummaryLoading(false);
    };

    const handleGetDescendants = async () => {
        const res = await getDescendants(folderId); 
        console.log('descendants: ', res);
    }

    const handleNewFolderModalOpen = () => {
        console.log('opening new folder modal');
        openNewFolderModal(null, folderId);
    }

    useEffect(() => {
        if (folder && folder.name) {
            console.log('folder: ', folder);
            document.title = folder.name;
        }
    }, [folder]);


    return (
        <div className='my-bookmarks-container scene-short centered'>
            {role === 'none' && 
                <div>
                    <h3>No Access</h3>
                    <p>You do not have access to this folder. Please log in or request access.</p>
                    <button className='gray-button' onClick={() => modalContext.openLoginModal(true)}>Log In</button>
                </div>
            }
            {loading && 
                <div className='progress-indicator'>
                    <CircularProgress />
                </div>
            }
            {role !== 'none' && role !== '' && !loading &&
            <div className='my-bookmarks scene standard-width'>
                <div className='my-bookmarks-toolbar'>
                    {userId || parentId ?
                        <button className="centered-icon-button gray-button" onClick={handleBack}>
                            <ArrowBackIcon className='icon'/>
                            Back
                        </button>
                        :
                        <div></div>
                    }

                    <div className='my-bookmarks-button-container'>

                        {canEdit && !summary && !newSummaryLoading &&
                            <button className='gray-button centered-icon-button' onClick={handleSummarize} >
                                <AutoAwesomeIcon className={isMobile ? '' : 'icon'}/>
                                {!isMobile && `Summarize`}
                            </button>    
                        }

                        <div>
                            <a href={`/folder-similar-posts/${folderId}`} target='_blank'>
                                <button className='gray-button centered-icon-button'> 
                                    <QuestionAnswerIcon  className={isMobile ? '' : 'icon'}/>
                                    {!isMobile && `Similar Posts`}
                                </button>
                            </a>
                        </div> 
                        {canEdit &&
                            <button className='centered-icon-button gray-button' onClick={handleNewFolderModalOpen}>
                                <CreateNewFolderIcon  className={isMobile ? '' : 'icon'}/>
                                {!isMobile && `New Folder`}
                            </button>
                        }
                            
                        {canEdit && folder ?
                            <FolderShareButton folder={folder} isMobileOverride={isMobile}/>
                            :
                            <button className='gray-button centered-icon-button'>
                                <GroupAddIcon  className={isMobile ? '' : 'icon'}/>
                                {!isMobile && `Request Access`}
                            </button>
                        }

                        {canEdit ?
                            <div className='gray-button centered-icon-button' onClick={handleSettings}>
                                <SettingsIcon />
                            </div>
                            :
                            <div className='gray-button centered-icon-button'>
                                <FolderCopyIcon  className={isMobile ? '' : 'icon'}/>
                                {!isMobile && `Copy Folder`}
                            </div>
                        }

                        </div>
                    </div>

                <div className='folder-name'> 
                    {folder.name}
                    <button className='gray-button centered-icon-button qa-button' onClick={() => {
                        setShowQuestionAnswer(!showQuestionAnswer);
                    }}>
                        <ContactSupportIcon className='icon'/>
                        Ask a Question
                    </button>
                </div> 

                <AskQuestion
                    folderId={folderId}
                    topicName={folder.name}
                    show={showQuestionAnswer}
                    sources={sources}
                    setSources={setSources}
                />

                {!newSummaryLoading &&
                    <FolderSummary 
                        summary={summary}
                        handleSummarize={handleSummarize}
                        allowSummaryGeneration={true} 
                        isSharedFolder={isSharedFolder}
                        canEdit={canEdit}
                    />
                }
                {newSummaryLoading && 
                    <div className='progress-indicator summary-progress'> 
                        <div className='progress-container' >
                            <CircularProgress /> 
                            <p className='summary-generating-label'>Summary generating...</p>
                        </div>
                    </div>
                }


                {childrenFolders && childrenFolders.length > 0 && 
                    <div className='folder-children'>
                        <div className='folder-children-container'>
                            <FolderFolders folders={childrenFolders}/>
                        </div>
                    </div>
                }




                <FolderPostDisplay header={"Bookmarked Posts"} posts={folder.bookmarks} showBookmarkButton={showBookmarkButton} sources={sources}/>
                {/* <button className='gray-button' onClick={handleGetDescendants}>Get Descendants</button> */}
            </div>
            }
            <BackToTop />
            <CitationScroller />
        </div>
    )
}

export default FolderPage;