import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../components/AuthContext';
import './TwitterLanding.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TwitterPricing from './TwitterPricing';
import TwitterSearchSection from './TwitterSearchSection';
import TwitterHeaderBar from './TwitterHeaderBar.js';

const TwitterLandingV2 = () => {
    const {isMobile, userId} = useAuth();
    const [email, setEmail] = useState('');
    const [badEmailFormat, setBadEmailFormat] = useState(false);

    useEffect(() => {
        console.log('email: ', email);
        if (badEmailFormat) {
            const formatCheck = checkEmailFormat(email);
            if (formatCheck) {
                setBadEmailFormat(false);
            }
        }
    }, [email])

    const checkEmailFormat = (email) => {
        if (email === '' || email === null || !email.includes('@') || email.length < 5 || !email.includes('.') || email.includes(' ')) {
            // alert('Please enter an email address.');
            return false
        }
        return true
    }

    const handleSubmit = useCallback(() => {
        console.log('submitting email: ', email);
        // confirm email format
        const formatCheck = checkEmailFormat(email);
        if (formatCheck) {
            // send email to backend
            // TODO

            // show search tool
            window.location.href = '/twitter-search-tool';
        }
        else {
            setBadEmailFormat(true);
        }
    }, [email, checkEmailFormat]);

    // add a listener that handles submitting the email when the user presses enter
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                console.log('enter pressed');
                console.log('email at this point is: ', email);
                handleSubmit();
            }
        }

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [handleSubmit, email])

    return (
        <div className='container-full'>
            <TwitterHeaderBar isMobile={isMobile} isLandingPage={true} />
            <div className='body-container'>
                {isMobile && <br />}
                {isMobile && <br />}

                <div className={isMobile ? 'title-section' : 'title-section side-by-side'}>
                    <div className={isMobile ? 'title body-section' : 'title body-section body-section-left'}>
                        <h1>Search investment news and commentary from Twitter / X: </h1>
                        <h2>Minus all the spam.</h2>
                    </div>
                    {!isMobile &&
                        <div className='body-section-right'>
                            <img src='img/scuttlesift/demo_card.svg' alt='Twitter Landing V2' />
                        </div>
                    }
                </div>

                <div className='centered-container'>
                    <div className='twitter-sign-up centered '>
                        {badEmailFormat && <div className='bad-email-text'> Please enter a valid email address. </div>}
                        <input 
                            className={!badEmailFormat ? 'twitter-input email-valid' : 'twitter-input bad-email'}
                            type="text" 
                            placeholder="Your email address"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    < br/>
                    <div className='centered'>
                        <button
                            className='twitter-sign-up-button'
                            onClick={handleSubmit}
                        >   

                            <div className='twitter-sign-up-button-contents'>
                                <div style={{width: '20px'}}></div>
                                <div className='twitter-sign-up-button-text'> Try Now </div>
                                < ArrowForwardIosIcon />
                            </div>
                        </button>
                    </div>
                    <div className='twitter-sign-up-button-subtext'> Totally free - no credit card required. </div>
                </div>

                <br />
                <br />
                <br />

            </div>

            <div className='centered-container gray' id='demo'>
                <br />
                <br/>

                <h1>How Does it Work?</h1>
                <br/>
                <div className='body-section top-bottom' >
                    <h3>ScuttleSift filters out spam to provide you with the highest-quality search results on Twitter / X.</h3>
                    <br />
                    {/* if mobile, make it top-bottom */}
                    <div className={'body-section before-after ' + (isMobile ? 'top-bottom' : 'side-by-side')}>
                        <div className='body-section-left centered'>
                            <img src='img/scuttlesift/before_card.svg' alt='Twitter Landing V2' />
                        </div>
                        {isMobile && <br />}
                        <div className='body-section-right centered'>
                            <img src='img/scuttlesift/after_card.svg' alt='Twitter Landing V2' />
                        </div>
                    </div>
                </div>
                <br />
                <br/>

            </div>

            
            <div className='centered-container gray' id='features-section'>
                <br />
                <br/>

                <h1>Pro Features</h1>
                <div className='body-section top-bottom' >
                    <br />
                    {/* if mobile, make it top-bottom */}
                    <div className={'body-section before-after top-bottom'}>
                        < div className={'feature ' + (isMobile ? 'top-bottom' : 'side-by-side')}>
                            <h3>Build advanced searches to filter for exactly the topics you are researching.</h3>
                            <img src='img/scuttlesift/feature1.svg' alt='Twitter Landing V2' />
                        </div>

                        {isMobile && <br />}
                        {isMobile && <br />}

                        < div className={'feature ' + (isMobile ? 'top-bottom' : 'side-by-side')}>
                            {isMobile && <h3>Save your watchlist and get the news with a single click.</h3>}
                            <img src='img/scuttlesift/feature2.svg' alt='Twitter Landing V2' />
                            {!isMobile && <h3>Save your watchlist and get the news with a single click.</h3>}
                        </div>

                        {isMobile && <br />}
                        {isMobile && <br />}

                        < div className={'feature ' + (isMobile ? 'top-bottom' : 'side-by-side')}>
                            <h3>Find older write-ups or catch up on the latest by searching a specific time period.</h3>
                            <img src='img/scuttlesift/feature3.svg' alt='Twitter Landing V2' />
                        </div>
                    </div>
                </div>
                <br />
                <br/>

            </div>

            {/* Testimonials! */}
            

            {/* Pricing - use the pricing cards thing I already made*/}

            <div className='centered-container' id='pricing'>
                <br />
                <br />
                <h1>Pricing</h1>
                <br />
                <TwitterPricing />
            </div>

            <div className='centered-container search-preview-container' id='try-it'>
                <br />
                <br />
                <div className='search-preview-header'>
                    <h1>See For Yourself</h1>
                    <h4>Prove that it works: make a search and compare the results!</h4>
                </div>
                <br />
                <div className='search-tool-preview'>
                    <TwitterSearchSection includeTagline={false} allowSavedSearches={false} hideAdvanced={true} allowAdvanced={false} />
                </div>
                <br />
                <br />

            </div>

            <div className='landing-footer centered-container'>
                <br />
                <br />

                {/* <div className='landing-footer-text'>
                    <div className='twitter-search-header-container'>
                        <h1 className='twitter-search-header'> ScuttleSift </h1>
                        <h4 className='twitter-search-subheader'>by Scuttlebutt Research</h4>
                        <div className='twitter-search-description'>
                            Search X / Twitter for investment news and analysis, (mostly) free from the crypto bots and day traders.
                        </div>
                    </div>
                </div> */}
                <br />
                <h6>© 2024 Scuttlebutt Research</h6>
            </div>

        </div>
    );
};

export default TwitterLandingV2;