import React, { useRef } from 'react';
import * as htmlToImage from 'html-to-image';
import DOMPurify from 'dompurify';
import { formatDate } from '../functions/DateFormatters';
import  supabase  from './supabaseClient'; // assuming you have a supabase client setup
import { Buffer } from "buffer";
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import ClipboardButton from './ClipboardButton';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';

const SocialCard = ({ post, setImageUrl }) => {
    const cardRef = useRef(null);
    const baseImageUrl = 'https://earxhhhgnpcjfqocieui.supabase.co/storage/v1/object/public/social-cards/'
    const uploadUrl = `${post.id}.jpeg`
    const imageUrl = `${baseImageUrl}${post.id}.jpeg`
    const [downloaded, setDownloaded] = React.useState(false)

    console.log('post type: ', post)

    const downloadImage = () => {
        htmlToImage.toJpeg(cardRef.current, {
            // width: 1200, height: 630, canvasHeight: 630, canvasWidth: 1200,
            imagePlaceholder: "https://www.scuttlebuttresearch.com/img/favicon-navbar.png",
            skipFonts: true // Add this line to skip loading fonts
        })
        .then(function (dataUrl) {
            console.log(dataUrl);
            const link = document.createElement('a');
            link.download = uploadUrl;
            link.href = dataUrl;
            link.click();
            setDownloaded(true)
            setTimeout(() => {
                setDownloaded(false)
            }, 2000)
        });
    };

    const prepareBase64DataUrl = (base64) =>
        base64
            .replace('data:image/jpeg;', 'data:image/jpeg;charset=utf-8;')
            .replace(/^.+,/, '')

    const uploadCardToSupabase = () => {
        // convert the card to an image
        htmlToImage.toJpeg(cardRef.current)
            .then(function (dataUrl) {
                supabase.storage.from('social-cards')
                    .upload(uploadUrl,
                        Buffer.from(prepareBase64DataUrl(dataUrl), 'base64'),
                        {
                            contentType: 'image/jpeg',
                            upsert: true,
                        }
                    )
            })
            .then(() => {
                setImageUrl(imageUrl)
            });
        
    };
 
    return (
        <div className='social-card-container'>
            <div ref={cardRef} className='social-card-export-container'>
                <div className='social-card'>
                    <div className='social-card-text-container'>
                        <div className='social-card-date'>
                            {formatDate(post.time)}
                        </div>
                        {/* <h1 className='quote-header'>Scuttlebutt Research</h1> */}
                        <div className="social-card-text" dangerouslySetInnerHTML={{ __html: `<p> ${DOMPurify.sanitize(post.text)} </p>`}} />
                        {/* Add additional elements like author, logo, etc. */}
                        {post.type === 'hn' &&
                            <div className='social-card-source'>
                                Source: Hacker News User
                            </div>
                        }
                        <div className='social-card-footer'>
                            <div />
                            <img className='social-card-logo' src='https://www.scuttlebuttresearch.com/img/sb-logo-long.png' height="24" />
                        </div>
                    

                    </div>
                </div>
            </div>
            {/* Make a download button that downloads the image, and then switches the icon to download done briefly */}
            <div className='social-card-button-footer'>
                <button onClick={downloadImage} className='gray-button centered-icon-button'>
                    <div className='icon'>
                        {downloaded ? <DownloadDoneIcon className=''/> : <DownloadIcon />}
                    </div>
                    {downloaded ? 'Downloaded!' : 'Download Share Image'}
                </button>
                <div className='share-post-icons'>
                    <div className='button-container'>
                        <ClipboardButton value={`${window.location.origin}/post/${post.id}`} />
                    </div>

                    <div className='button-container'>
                        <a href={`https://twitter.com/intent/tweet?text=Here's an interesting datapoint from Scuttlebutt Research: &url=www.scuttlebuttresearch.com/post/${post.id} (via @ScuttlebuttRes)`} target='_blank' rel='noreferrer'>
                            <button className='centered-icon-button gray-button'>
                                <TwitterIcon className='icon'/>
                                Tweet
                            </button>
                        </a>
                    </div>

                    <div className='button-container'>
                        <a href={`mailto:?to=&body=I thought you would be interested in this Scuttlebutt&subject=Check out this post on Scuttlebutt Research&body=www.scuttlebuttresearch.com/post/${post.id}`}>
                            <button className='centered-icon-button gray-button'>
                                <EmailIcon className='icon'/>
                                Email
                            </button>
                        </a>
                    </div>

                </div>
            </div>

            {/* <button onClick={uploadCardToSupabase}>Upload Image</button> */}

        </div>
    );
};

export default SocialCard;