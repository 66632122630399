import React, { createContext, useContext } from 'react';
import useBookmarks from './Bookmarks';

const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
  const apiData = useBookmarks();

  return (
    <ApiDataContext.Provider value={apiData}>
      {children}
    </ApiDataContext.Provider>
  );
};

export const useApiDataContext = () => useContext(ApiDataContext);