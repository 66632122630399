export function formatDate(unixTimestamp) {
    // Convert Unix timestamp to milliseconds by multiplying by 1000
    const milliseconds = unixTimestamp * 1000;
  
    // Create a new Date object with the milliseconds
    const dateObject = new Date(milliseconds);
  
    // Get the month abbreviation
    const monthAbbreviation = dateObject.toLocaleString('en-us', { month: 'short' });
  
    // Get the day and year
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
  
    // Formatted date string
    return `${monthAbbreviation} ${day}, ${year}`;
  }

export function formatTimestamp(timestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const date = new Date(timestamp);
    const month = months[date.getMonth()];
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)

    const formattedTime = `${month} ${day}, ${hours}:${minutes}${ampm}`;

    return formattedTime;
}    