import React, { useEffect, useState, useCallback } from 'react';
import './ScuttleSift.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TwitterLanding = ({confirmed}) => {
    const [email, setEmail] = useState('');
    const [badEmailFormat, setBadEmailFormat] = useState(false);
    const isMobile = window.innerWidth < 900;

    useEffect(() => {
        console.log('email: ', email);
        if (badEmailFormat) {
            const formatCheck = checkEmailFormat(email);
            if (formatCheck) {
                setBadEmailFormat(false);
            }
        }
    }, [email])

    const checkEmailFormat = (email) => {
        if (email === '' || email === null || !email.includes('@') || email.length < 5 || !email.includes('.') || email.includes(' ')) {
            // alert('Please enter an email address.');
            return false
        }
        return true
    }

    const handleSubmit = useCallback(() => {
        console.log('submitting email: ', email);
        // confirm email format
        const formatCheck = checkEmailFormat(email);
        if (formatCheck) {
            // send email to backend
            // TODO

            // show search tool
            confirmed();
        }
        else {
            setBadEmailFormat(true);
        }
    }, [email, confirmed, checkEmailFormat]);

    // add a listener that handles submitting the email when the user presses enter
    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Enter') {
                console.log('enter pressed');
                console.log('email at this point is: ', email);
                handleSubmit();
            }
        }

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        }
    }, [handleSubmit, email])

    return (
        <div className='twitter-landing-container'>
            <div className='twitter-landing'>
                <h1 className='tagline' style={{ fontSize: isMobile ? '32px' : '60px' }}> Search investment news and commentary from Twitter:  </h1>
                <br/>
                {!isMobile && <br/>}
                <h1 className='tagline-underlined' style={{ fontSize: isMobile ? '35px' : '60px' }}> Without all the crap. </h1>
            </div>
            < br/>
            {!isMobile && <br/>}
            {!isMobile && <br/>}
            {!isMobile && <br/>}

            <div>
                <div className='twitter-sign-up centered '>
                    {badEmailFormat && <div className='bad-email-text'> Please enter a valid email address. </div>}
                    <input 
                        className={!badEmailFormat ? 'twitter-input email-valid' : 'twitter-input bad-email'}
                        type="text" 
                        placeholder="Your email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                < br/>
                <div className='centered'>
                    <button
                        className='twitter-sign-up-button'
                        onClick={handleSubmit}
                    >   

                        <div className='twitter-sign-up-button-contents'>
                            <div style={{width: '20px'}}></div>
                            <div className='twitter-sign-up-button-text'> Try Now </div>
                            < ArrowForwardIosIcon />
                        </div>
                    </button>
                </div>
                <div className='twitter-sign-up-button-subtext'> Totally free - no credit card required. </div>


            </div>

        </div>

    )
}

export default TwitterLanding;