import React, { useState, useEffect } from "react";
import { Navigation } from "../components/Landing/navigation";
import { Header } from "../components/Landing/header";
import { Services } from "../components/Landing/services";
import { PhilosophyQuotes } from "../components/Landing/PhilosophyQuotes";
import Footer from "../components/Landing/Footer";
import { CoverageGrid } from "../components/Landing/CoverageGrid";
import { CustomerGrid } from "../components/Landing/CustomerGrid";
import JsonData from "../data/data.json";
import "../App.css";
import TryNow from "../components/Landing/TryNow";

const landingPageData = JsonData;

const WITG = () => {

  return (
    <div className="landing-page">
        <Navigation 
            links={[{href:'#coverage', text: 'Examples'}, {href:'#services', text: 'Features'}, {href:'#testimonials', text: 'Philosophy'}]} 
            ctaText="Try Now"
            ctaLink="/witg-signup"
        />

        <Header 
            titleOverride="Perfect Your Pitch" 
            titleSize="36px" 
            paragraphOverride="Quickly find unique insights into the companies you are pitching." 
            ctaLink="/witg-signup"
            ctaText="Try Now"
        />
        <br />
        <div className="centered-container" id="what-we-do">
            <div className="centered-container standard-width ">
                <div className="centered-container what-we-do-text standard-width ">
                    <br />
                    <h2 style={{'fontSize': '24px'}}>Get the Real Story</h2>
                    <p>
                    Scuttlebutt is a software tool that finds internet commentary about companies and products. 
                    </p>
                    <p>
                    It helps investors quickly understand the competitive landscape for a target company, based on on-the-ground signal from people in the know.
                    </p>

                    <p>
                    The tool pulls in millions of data points from across the web and highlights those that are most relevant for investors.
                    </p>
                </div>
            </div>
        </div>

        <CoverageGrid logoList={landingPageData.CoverageLogos} header="View Examples"/>
        <PhilosophyQuotes data={landingPageData.PhilosophyQuotes} />
        
        <br/>
        <br/>

        {/* <Features data={landingPageData.Features} /> */}
        <CustomerGrid logoList={landingPageData.CustomerLogos} header="Our Customers Include..."/>
        {/* <About data={landingPageData.About} /> */}
        {/* <Team data={landingPageData.Team} /> */}
        <div className="centered-container">
            <div className="centered-container standard-width ">
                <TryNow subtitle="Totally free for WITG members!" link="/witg-signup"/>
            </div>
        </div>
        <Footer data={landingPageData.Contact}/>
    </div>
  );
};

export default WITG;
