// Componene that allows the user to delete a folder
// Takes in a folder ID
// Has a button that says "Delete Folder"
// Asks for confirmation before deleting
// Deletes the folder

import React, { useState } from 'react';
import supabase from './supabaseClient';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { useApiDataContext } from './ApiDataContext';

const FolderDeletor = ({folderId, folderName}) => {
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();
    const { setSelectedFolderId, fetchFolders } = useApiDataContext();

    const handleDeleteFolder = () => {
        if (isDeleting) {
            return;
        }
        setIsDeleting(true);
    }

    const handleConfirmDelete = () => {
        // delete the folder
        supabase.from('bookmark_folders').delete().eq('uuid', folderId)
        .then(response => {
            console.log('response:', response);
            // set the selected folder to 'All'
            setSelectedFolderId('All');

            // refresh the folders

            fetchFolders().then(() => {
                // route the user back to /bookmarks
                navigate('/bookmarks'); // Navigate back to the home page
            });


        })
        .catch(error => {
            console.error('error:', error);
        });
    }

    return (
        <div className='delete-section'>
            <button className='centered-icon-button delete-button' onClick={handleDeleteFolder}>
                <DeleteForeverIcon className='icon'/>
                Delete Folder
            </button>
            {isDeleting && 
                <div className='modal-overlay'>
                    <div className='delete-confirmation'>
                        <p>Are you sure you want to delete the folder "{folderName}"?</p>
                        <p style={{ fontWeight: 'bold' }}>This action cannot be undone.</p>
                        <div className='delete-confirmation-buttons'>
                            <button className='centered-icon-button gray-button' onClick={() => setIsDeleting(false)}>No, cancel</button>
                            <button className='centered-icon-button delete-button' onClick={handleConfirmDelete}>
                                <DeleteForeverIcon className='icon'/>
                                Yes, Delete
                            </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default FolderDeletor;