import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import PostDisplay from "../components/PostDisplay";
import { useApiDataContext } from "../components/ApiDataContext";
import SmoothScroll from "smooth-scroll";
import BackToTop from "../components/BackToTop";
import CitationScroller from "../components/CitationScroller";
import { extractKeywords } from "../functions/OpenAI";

const QandA = () => {
    // const [query, setQuery] = useState("");
    // const [answer, setAnswer] = useState("");
    // const [posts, setPosts] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const {queryPosts} = useApiDataContext();
    // const [queryKeywords, setQueryKeywords] = useState([]);

    // const getPosts = async () => {
    //     if (query === "") {
    //         return;
    //     }
    //     try {
    //         setLoading(true);
    //         const keywords = await extractKeywords(query, true);
    //         console.log('keywords: ', keywords);
    //         setQueryKeywords(keywords)
    //         const response = await fetch(`http://127.0.0.1:5000/vectorgensearch/${query}`);
    //         const data = await response.json();
    //         const result = await queryPosts(query, data);
    //         // Sort posts_used by 'time' in descending order
    //         result.posts_used.sort((a, b) => {
    //             if (a.cited && !b.cited) return -1;
    //             if (!a.cited && b.cited) return 1;
    //             return b.time - a.time;
    //         });
    //         console.log('result.posts_used: ', result.posts_used);
    //         setPosts(result.posts_used);
    //         setAnswer(result.answer);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error querying posts:', error);
    //         return null;
    //     }
    // }


    // return (
    //     <div className="centered-container">
    //         <div className="standard-width">
    //             <h1>QandA</h1>
    //             <input type="text" placeholder="Enter your question" onChange={(e) => setQuery(e.target.value)} />
    //             <button onClick={getPosts}>Submit</button>

    //             {answer &&
    //                 <div dangerouslySetInnerHTML={{ __html: answer }} />
    //             }
    //             {posts && !loading &&
    //                 <PostDisplay data={posts} query={queryKeywords} />
    //             }
    //             {loading &&
    //                 <CircularProgress />
    //             }
    //         </div>
    //         <BackToTop />
    //         <CitationScroller />

    //     </div>
    // );
};

export default QandA;