import React from 'react';

const LogoGrid = (props) => {
    const handleLogoClick = (e, index) => {
        if (props.onLogoClick) {
            props.onLogoClick(index);
        }
        else{
            console.log("No onLogoClick function provided");
            e.stopPropagation();
        }
    };

    function scrollToSection() {
        const isMobile = window.innerWidth <= 768;

        if (isMobile) {
            // Wait for quotes to render before scrolling
            setTimeout(() => {
                const targetSection = document.querySelector('.quote-container');
                if (targetSection) {
                    const headerOffset = 80; // 60px navbar + 20px padding
                    const elementPosition = targetSection.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 200);
        }
    }

    return (
        <div className='logo-grid'>
            {props.logos.map((logo, index) => (
                <a key={logo.id || index} onClick={(e) => {
                    handleLogoClick(e, index);
                    scrollToSection();
                }}>
                    <div style={{ '--specific-item-size': logo.specificItemSize }}>
                        <div className={`logo-container ${props.selectedLogoIndex === index ? 'logo-container-selected' : ''} ${props.noHover ? 'no-hover' : ''}`}>
                            <img className="logo-img" src={logo.logo} alt={`Logo ${index}`} />
                        </div>
                    </div>
                </a>
            ))}
        </div>
    );
};

export default LogoGrid;