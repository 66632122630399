import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DateRange = ({setStartDate, setEndDate, translateDate, vertical=true}) => {

    if (!translateDate) {
        translateDate = (date) => {
            console.log('date:', date);
            console.log('date format:', date.format('YYYY-MM-DD'));
            return date.format('YYYY-MM-DD');
        }
    }
    
    return (
        <div className={vertical ? 'date-pickers vertical' : "date-pickers split"} style={{width: '100%'}}>
            <div className="date-picker" style={{width: '100%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Start Date" 
                        slotProps={{ textField: { size: 'small'} }}
                        sx={{width: '100%'}}
                        onChange={(newValue) => setStartDate(translateDate(newValue))}
                    />
                </LocalizationProvider>
            </div>
            <div className="date-picker" style={{width: '100%'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="End Date" 
                        slotProps={{ textField: { size: 'small' } }}
                        sx={{width: '100%'}}
                        onChange={(newValue) => setEndDate(translateDate(newValue))}
                    />
                </LocalizationProvider>
            </div>
        </div>
    )
}


export default DateRange;
