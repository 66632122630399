import React, { useState, useRef, useEffect } from 'react';
import SearchPreview from './SearchPreview';
import { useAuth } from './AuthContext';

const CompanyTagAdder = ({folder, setFolder}) => {
    const {serverAddress} = useAuth();
    const [inputValue, setInputValue] = useState('');
    const searchBoxRef = useRef(null);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const onlyShowCompanySearch = true;
    const [company, setCompany] = useState('');

    const handleInputFocus = () => {
        setIsInputFocused(true);
      };
    
    const handleInputBlur = () => {
        // wait
        setTimeout(() => {
            setIsInputFocused(false);
        }, 200);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleAddTag = async (company) => {
        console.log('folder:', folder)
        setIsInputFocused(false);
        // TODO: add tag
        console.log('adding tag for company: ', company.id);
        // add tag to folder_company_tags
        // schema: folder_id, company_id
        const existingTags = folder.company_tags || [];
        setFolder({...folder, company_tags: [...existingTags, company]});

        const data = await fetch(serverAddress + `add-folder-tag`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                folder_id: folder.folder_id,
                company_id: company.id,
            }),
        });
    };
    
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            console.log('enter pressed!')
        }
    };

    return (
    <div className='company-tag-container'>
        <div className='company-tag-input-container' >
            <input ref={searchBoxRef}
                className="company-tag-input"
                value={inputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                sx={{ ml: 1, flex: 1 }}
                inputProps={{style: {fontSize: 16}}}
                placeholder="Add company tags"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
            >
            </input>
        </div>
        {isInputFocused && inputValue.length > 0 &&
            <SearchPreview 
                query={inputValue} 
                parentRef={searchBoxRef} 
                onlyShowCompanySearch={onlyShowCompanySearch} 
                handleClick={(selectedCompany) => handleAddTag(selectedCompany)}
                setCompany={setCompany}
            />
        }
        </div>
    );
}

export default CompanyTagAdder;