// simple component that shows a block of text
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import symbolsData from '../data/symbols.json';

const TranscriptViewer = () => {
    const earningscall_apikey = "4OLKcN0qC53RRoIxFxPwCw"
    const [ticker, setTicker] = useState('AAPL')
    const [year, setYear] = useState('2024')
    const [quarter, setQuarter] = useState('4')
    const [url, setUrl] = useState('')

    const [transcript, setTranscript] = useState('')
    const [loading, setLoading] = useState(false)
    const [symbols, setSymbols] = useState(symbolsData)
    const [exchange, setExchange] = useState('')
    const [event, setEvent] = useState('')
    const [company, setCompany] = useState('')
    const [foundSymbolData, setFoundSymbolData] = useState('')

    const fetchTranscript = () => {
        console.log(url)
        setLoading(true)
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setTranscript(data.text)
                setEvent(data.event)
                setCompany(foundSymbolData.name)
                setLoading(false)
            })
    }

    const updateUrl = () => {
        const url = `https://v2.api.earningscall.biz/transcript?apikey=${earningscall_apikey}&exchange=${exchange}&symbol=${ticker}&year=${year}&quarter=${quarter}`
        setUrl(url)
    }

    const getExchange = ({ticker}) => {
        // find the exchange for the ticker
        let exchange = 'NASDAQ';
        const foundSymbol = symbols.find(symbol => symbol.symbol === ticker);
        if (foundSymbol) {
            exchange = foundSymbol.exchange;
            setFoundSymbolData(foundSymbol);
        }
        console.log(exchange)
        setExchange(exchange)
    }

    useEffect(() => {
        console.log('symbols:', symbols)
        if (symbols.length > 0 && ticker) {
            getExchange({ticker})
        }
    }, [symbols, ticker])

    useEffect(() => {
        updateUrl()
    }, [exchange, ticker, year, quarter])

    return (
        <div className="centered-container">
            <div className="centered-container standard-width ">
                <h3>Transcript</h3>
                <div className="transcript-viewer-header-bar">
                    <input 
                        type="text" 
                        value={ticker} 
                        onChange={(e) => setTicker(e.target.value.toUpperCase())}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                fetchTranscript();
                            }
                        }}
                    />
                    <select value={year} onChange={(e) => setYear(e.target.value)}>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                    </select>
                    <select value={quarter} onChange={(e) => setQuarter(e.target.value)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                    <button onClick={fetchTranscript}>Get Transcript</button>
                </div>
                <br/>
                <br/>
                {loading && <CircularProgress />}
                {!loading && 
                <div>
                    <h1>{company}</h1>
                    <h3>{new Date(event.conference_date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</h3>
                    <div>{transcript}</div>
                </div>}
            </div>
        </div>
    )
}

export default TranscriptViewer;