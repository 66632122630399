import { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import  supabase  from './supabaseClient'; // assuming you have a supabase client setup
import { all } from 'axios';
import { formatTimestamp } from '../functions/DateFormatters';
import { setDifference } from '../functions/Helpers';
import { useLocation } from 'react-router-dom';

const useBookmarks = () => {
    const [bookmarkFolders, setBookmarkFolders] = useState(null);
    const { userId, userDoesNotExist, flaskServerAddress, serverAddress } = useAuth();
    const [folders, setFolders] = useState([]);
    const [deletedPosts, setDeletedPosts] = useState([]);
    const [peopleWithAccess, setPeopleWithAccess] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const noAccessCode = 'none';
    const [bookmarksLoading, setBookmarksLoading] = useState(true);
    const [uncategorizedBookmarks, setUncategorizedBookmarks] = useState([]);
    const [orderedFolders, setOrderedFolders] = useState([]);

    const fetchDeletedPosts = async () => {
        if (userId === null) return;

        try {
            const { data, error } = await supabase
                .from('deleted_posts')
                .select('post_id')
                .eq('user_id', userId);
        
            if (error) {
                console.error('Error fetching deleted posts:', error);
            }
    
            setDeletedPosts(data ? data.map(post => post.post_id) : []);
        } catch (error) {
            console.error('Error fetching deleted posts:', error);
        }
    }

    const fetchBookmarks = async () => {
        try {
            const response = await fetch(`${serverAddress}bookmarks/fetch-bookmarks?userId=${userId}`);
            const data = await response.json();
            
            setUncategorizedBookmarks(data.uncategorizedBookmarks);
            setOrderedFolders(data.orderedFolders);
            setBookmarkFolders(data.bookmarkFolders);
            setFolders(data.folders);
            setBookmarksLoading(false);
        } catch (error) {
            console.error('Error fetching bookmarks:', error);
            setBookmarksLoading(false);
        }
    }

    useEffect(() => {
        console.log('userId:', userId);
        if (userId !== null) {
            fetchBookmarks();
            fetchDeletedPosts();
        }
        if (userDoesNotExist) {
            setBookmarksLoading(false);
        }   
    }, [userId, userDoesNotExist]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    const getFolder = async (folderId) => {
        try {
            const response = await fetch(`${serverAddress}bookmarks/get-folder/${folderId}`);
            return await response.json();
        } catch (error) {
            console.error('Error getting folder:', error);
            return null;
        }
    }

    const checkAccess = async (folderId) => {
        try {
            const response = await fetch(`${serverAddress}bookmarks/check-access/${folderId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.role;
        } catch (error) {
            console.error('Error checking access:', error);
            return null;
        }
    }

    const getChildrenDetails = async (folderId) => {
        console.log('getting children details for folder', folderId);
        try {
            const response = await fetch(`${serverAddress}bookmarks/get-children-details/${folderId}`);
            return await response.json();
        } catch (error) {
            console.error('Error getting children details:', error);
            return [];
        }
    }

    const getPeopleWithAccess = async (folderId) => {
        const response = await fetch(serverAddress + 'bookmarks/get-people-with-access/'+folderId)
        const data = await response.json();
        setPeopleWithAccess(data);
    }

    const handleAccessChange = async (event, userToChange, folderId) => {
        try {
            await fetch(`${serverAddress}bookmarks/handle-access-change`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({ 
                    newRole: event.target.value, 
                    userToChange, 
                    folderId,
                    userId 
                })
            });
            getPeopleWithAccess(folderId);
        } catch (error) {
            console.error('Error changing access:', error);
        }
    };
    
    const addPersonRole = async (folderId, userId, role) => {
        console.log('adding person role:', folderId, userId, role);
        try {
            await fetch(`${serverAddress}bookmarks/add-person-role`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({ folderId: folderId, userId: userId, role: role })
            });
            getPeopleWithAccess(folderId);
        } catch (error) {
            console.error('Error adding person role:', error);
        }
    }
    

    const removeTag = async (folderId, tagId) => {
        // TODO: actually make tags a thing
        console.log('Removing tag ', tagId, ' from folder ', folderId);
        const response = await fetch(serverAddress + `remove-folder-tag`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                folder_id: folderId,
                company_id: tagId,
            }),
        })
    }

    // Functions for rearranging the folder list
    const updateFolderOrder = (folder) => {
        const newOrderedFolders = orderedFolders.filter(f => f.folder_id !== folder.folder_id);
        newOrderedFolders.unshift(folder);
        setOrderedFolders(newOrderedFolders);
    }

    // Functions for talking to your bookmark folder!
    const summarizeFolder = async (folderId) => {
        const folder = folders.find(folder => folder.folder_id === folderId);
        if (folder) {
            try {
                const response = await fetch(`${flaskServerAddress}summarize/${folderId}`);
                const data = await response.json();
                data.created_at = formatTimestamp(new Date().toISOString());
                data.numNewBookmarks = 0;
                return data;
            } catch (error) {
                console.error('Error generating summary:', error);
                return null;
            }
        }
        return null;
    };
    
    const queryPosts = async (query, folderId) => {
        try {
            const response = await fetch(`${flaskServerAddress}query-posts/${folderId}?query=${encodeURIComponent(query)}`);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error querying posts:', error);
            return null;
        }
    }


        

    const handleBookmark = async (post, targetFolder, bookmarked, currentFolder) => {
        const updateLocalState = (action) => {
            switch (action) {
            case 'delete':
                setBookmarkFolders(prev => {
                    const newState = {...prev};
                    delete newState[post.id];
                    return newState;
                });
                setFolders(prev => prev.map(folder => {
                    if (folder.folder_id === currentFolder) {
                        // Add a null check before accessing 'id'
                        return {...folder, bookmarks: folder.bookmarks.filter(b => b && b.id !== post.id)};
                    }
                    return folder;
                }));
                break;
                case 'move':
                    setBookmarkFolders(prev => ({...prev, [post.id]: targetFolder}));
                    setFolders(prev => prev.map(folder => {
                        if (folder.folder_id === currentFolder) {
                            return {...folder, bookmarks: folder.bookmarks.filter(b => b && b.id !== post.id)};
                        }
                        if (folder.folder_id === targetFolder) {
                            return {...folder, bookmarks: [...folder.bookmarks, post]};
                        }
                        return folder;
                    }));
                    break;
                case 'add':
                    setBookmarkFolders(prev => ({...prev, [post.id]: targetFolder}));
                    setFolders(prev => prev.map(folder => {
                        if (folder.folder_id === targetFolder) {
                            return {...folder, bookmarks: [...folder.bookmarks, post]};
                        }
                        return folder;
                    }));
                    break;
            }
        };
    
        try {
            // Optimistically update the UI
            if (bookmarked) {
                if (targetFolder === currentFolder || targetFolder === null) {
                    updateLocalState('delete');
                } else if (targetFolder !== currentFolder) {
                    updateLocalState('move');
                }
            } else {
                updateLocalState('add');
            }

            console.log('handling bookmark for ', post.id, 'in folder ', targetFolder, 'bookmarked: ', bookmarked, 'current folder: ', currentFolder, 'userId: ', userId);
    
            // Make the API call
            const response = await fetch(`${serverAddress}bookmarks/handle-bookmark/${post.id}/${targetFolder}/${bookmarked}/${currentFolder}/${userId}`, {
                method: 'POST',
            });
    
            if (!response.ok) {
                throw new Error('Failed to update bookmark');
            }
    
            // Update folder order if necessary
            if (targetFolder) {
                updateFolderOrder({ folder_id: targetFolder, folder_name: folders.find(f => f.folder_id === targetFolder).name });
            }
    
        } catch (error) {
            console.error('Error handling bookmark:', error);
            // Revert the optimistic update
            fetchBookmarks();
        }
    };

    // These three functions - create, delete, and move - will modify the folder structure
    // They will also handle writing the changes to supabase
    const createFolder = async (newFolderName, newFolderPostId, parentId) => {
        // Create: Takes a folder name, an optional post that is being bookmarked to that folder, and an optional parent folder
        // Parent will be null if it is a top-level folder (by default)
        // If there is a post, it will be bookmarked to the folder

        const updateFoldersOnCreate = (newFolderId, parentId, newBookmark) => {
            // once the folder is created and the bookmark is added to supabase, we need to update the folder structure in the local state
            // This will just add an entry to folders with this new folder, containing the bookmark (if it exists)
            const newFolders = [...folders];
            const newBookmarks = newBookmark ? [newBookmark] : [];
            newFolders.push({folder_id: newFolderId, name: newFolderName, role: 'Owner', bookmarks: newBookmarks, parent: parentId || null, children: []});
            console.log('new folders:', newFolders);
            setFolders(newFolders);
            updateFolderOrder({ folder_id: newFolderId, folder_name: newFolderName });
        }

        // First, create the bookmark folder in supabase
        supabase
            .from('bookmark_folders')
            .insert([{ user_id: userId, folder_name: newFolderName }])
            .select()
            .then(response => {
                const newFolderId = response.data[0].uuid;
                // need to add the creator as the owner
                addPersonRole(newFolderId, userId, 'Owner');
                // if there is a post to bookmark, add that to the folder
                if (newFolderPostId) {
                    supabase
                        .from('bookmarks')
                        .insert({user_id: userId, id: newFolderPostId, folder_id: newFolderId})
                        .select()
                        .then(bookmark => {
                            const bookmarkId = bookmark.data[0].uuid;
                            
                            // add to bookmarkFolders
                            const newBookmarkFolders = {...bookmarkFolders};
                            newBookmarkFolders[newFolderPostId] = newFolderId;
                            setBookmarkFolders(newBookmarkFolders);

                            supabase
                                .from('posts')
                                .select('*')
                                .eq('id', newFolderPostId)
                                .then(response => {
                                    const post = response.data[0];
                                    const newBookmark = {id: bookmarkId, post_id: post.id, text: post.text, url: post.url, time: post.time, type: post.type, created_at: new Date().getTime()};
                                    console.log('new bookmark:', newBookmark);
                                    updateFoldersOnCreate(newFolderId, parentId, newBookmark);
                                });
                        });
                } else {
                    updateFoldersOnCreate(newFolderId, parentId, null);
                }
            });



    }

    const deleteFolder = async (folderId) => {
        // Remove the folder from the local state
        const updatedFolders = folders.filter(folder => folder.folder_id !== folderId);
        setFolders(updatedFolders);

        // Update orderedFolders if necessary
        const updatedOrderedFolders = orderedFolders.filter(folder => folder.folder_id !== folderId);
        setOrderedFolders(updatedOrderedFolders);

        // If the folder has a parent, update the parent's children array
        const folderToDelete = folders.find(folder => folder.folder_id === folderId);
        if (folderToDelete.parent) {
            const updatedParent = updatedFolders.find(folder => folder.folder_id === folderToDelete.parent);
            if (updatedParent) {
                updatedParent.children = updatedParent.children.filter(childId => childId !== folderId);
            }
        }
        const folder = folders.find(folder => folder.folder_id === folderId);
        if (folder.bookmarks.length > 0 || (folder.children && folder.children.length > 0)) {
            alert('Folder is not empty - please move or delete the contents first');
            return;
        }

        try {
            await fetch(`${serverAddress}bookmarks/delete-folder/${folderId}`, { method: 'DELETE' });
            fetchBookmarks();
        } catch (error) {
            console.error('Error deleting folder:', error);
        }
    }
   
    const moveFolders = async (folderIds, newParentId) => {

        console.log('moving folders:', folderIds, 'to parent:', newParentId);

        // First, do some checks to make sure this is a valid move
        if (folderIds.includes(newParentId)){
            return;
        }

        // All this does is set a new parent ID on the folders provided
        // First, do this in the 'folders' object
        const newFolders = [...folders];
        folderIds.forEach(folderId => {
            const folder = newFolders.find(folder => folder.folder_id === folderId);
            folder.parent = newParentId;
        });

        // Then, need to add the children to the new parent
        const parent = newFolders.find(folder => folder.folder_id === newParentId);
        const children = parent.children || [];
        parent.children = children.concat(folderIds);
        // remove any dupes
        parent.children = Array.from(new Set(parent.children));
        setFolders(newFolders);

        // Then, do this in supabase
        try {
            await fetch(`${serverAddress}bookmarks/move-folders`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({ 
                    folderIds: JSON.stringify(folderIds), 
                    newParentId 
                })
            });
            // fetchBookmarks();
        } catch (error) {
            console.error('Error moving folders:', error);
        }
    }

    const handleStar = async (postId, isStarred) => {
        console.log('bookmarkFolders:', bookmarkFolders);
        const folderId = bookmarkFolders[postId];
        console.log('star clicked for post:', postId, 'in folder:', folderId);
        // Update the local state to reflect the change
        setFolders(prevFolders => {
            return prevFolders.map(folder => {
                if (folder.folder_id === folderId) {
                    const updatedPosts = folder.bookmarks.map(post => {
                        if (post.id === postId) {
                            return { ...post, isStarred: true };
                        }
                        return post;
                    });
                    return { ...folder, bookmarks: updatedPosts };
                }
                return folder;
            });
        });
        
        // Toggle the star status for the post
        try {
            await fetch(`${serverAddress}bookmarks/handle-star`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({ postId, isStarred, userId, folderId })
            });
        } catch (error) {
            console.error('Error handling star:', error);
        }
    

        console.log(`Post ${postId} in folder ${folderId} has been starred.`);
    }

    function useHash() {
        const location = useLocation();
        return location.hash;
    }

    return { 
        fetchBookmarks, 
        folders,
        bookmarkFolders, 
        getFolder,
        deletedPosts, fetchDeletedPosts, 
        summarizeFolder, checkAccess,
        getPeopleWithAccess, peopleWithAccess, handleAccessChange, addPersonRole,
        removeTag,
        handleBookmark,
        isMobile,
        createFolder, deleteFolder, moveFolders,
        bookmarksLoading,
        uncategorizedBookmarks, orderedFolders, setOrderedFolders,
        getChildrenDetails,
        useHash,
        queryPosts,
        handleStar
    };
};

export default useBookmarks;