// Component that takes a folder_id and returns a button that allows the user to copy-paste the link to share the folder
// The link to the folder is /folder/:folder_id

import React, {useState, useEffect} from 'react';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
// import {DefaultCopyField} from '@eisberg-labs/mui-copy-field';
import ClipboardButton from '../ClipboardButton';
import supabase from '../supabaseClient';
import FolderAccessRow from './FolderAccessRow';
import { useApiDataContext } from '../ApiDataContext';
import {useAuth} from '../AuthContext';

const FolderShareButton = ({folder, isMobileOverride}) => {
    const { userDetails, serverAddress } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [isMobileFlag, setIsMobileFlag] = useState(false);
    const { getPeopleWithAccess, peopleWithAccess, addPersonRole} = useApiDataContext();


    const handleSearch = (event) => {
        if (event.target.value.length > 0) {
            searchForUser(event.target.value);
            setShowSearchDropdown(true);
        } else {
            setShowSearchDropdown(false);
        }
    }

    const searchForUser = async (searchString) => {
        if (userDetails.user_org) {
            const response = await fetch(serverAddress + 'search-for-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    query: searchString,
                    user: userDetails.user_id,
                    user_org: userDetails.user_org.id,
                }),
            });
            console.log('response: ', response);
            const data = await response.json();
            console.log('data: ', data);
            setSearchResults(data.data);
        }
    }

    useEffect(() => {
        console.log('folder: ', folder);
        if (folder && folder.folder_id) {
            getPeopleWithAccess(folder.folder_id);
        }
    }, [folder]);

    
    useEffect(() => {
        setIsMobileFlag(isMobileOverride);
    }, [isMobileOverride]);

    const closeModal = () => {
        setShowModal(false);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const handleAddPerson = async (person) => {
        console.log('person: ', person);
        console.log('folder: ', folder);
        await addPersonRole(
            folder.folder_id,
            person.user_id,
            'Viewer'
        );
        getPeopleWithAccess(folder.folder_id);
        setShowSearchDropdown(false);
        // clear the text in the share-modal-search
        document.querySelector('.share-modal-search').value = '';
    }
    

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest('.share-modal')) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [closeModal]);

    // When the user clicks outside of the dropdown, I want it to close
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.share-modal-search-dropdown')) {
                setShowSearchDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCopy = async () => {
        // add to folder_link_access table
        // schema: folder_id, access_level (Owner, Editor, Viewer)
        // This will add "Viewer" by default
        // Then, the user can change the access level

        const response = await fetch(`${serverAddress}create-folder-link`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                folder_id: folder.folder_id,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create folder link');
        }

        const data = await response.json();
        // You might want to do something with the response data here,
        // such as copying the link to clipboard or displaying it to the user

        console.log(response);
        console.log(data);

    }

    return (
        <div>
            <button className='gray-button centered-icon-button' onClick={openModal}>
                <FolderSharedIcon  className={isMobileFlag ? '' : 'icon'}/>
                {!isMobileFlag && `Share`}
            </button>

            {showModal && 
                <div className='share-background'>
                    <div className='share-modal'>
                        <span className='share-modal-close' onClick={closeModal}>&times;</span>

                        <div className='share-modal-content'>

                            <div className='share-modal-header'>
                                <h4 className='share-header'>Share {folder.name}</h4>
                            </div>

                            {/* html input that allows user to search for other users */}
                            <div className='share-modal-search-container'>
                                <input className='share-modal-search' type='text' placeholder='Add collaborators' onChange={handleSearch}/>
                                {showSearchDropdown &&
                                    <div className='share-modal-search-dropdown'>
                                        {searchResults.map((person) => {
                                            return (
                                                // when this row is clicked, it should add the person to the people with access
                                                <div className='share-modal-search-result' onClick={() => handleAddPerson(person)}>
                                                    <FolderAccessRow 
                                                        name={person.name} 
                                                        email={person.email} 
                                                        profile_picture={person.profile_picture} 
                                                        searchResult={false}
                                                        userId={person.user_id}
                                                        folderId={folder.folder_id}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                            </div>

                            <div className='share-modal-header'>
                                <h5> People with access </h5>
                            </div>

                            {peopleWithAccess.map((person) => {
                                return (
                                    <FolderAccessRow  
                                        role={person.role} 
                                        name={person.name} 
                                        email={person.email} 
                                        profile_picture={person.profile_picture}
                                        userId={person.user_id}
                                        folderId={folder.folder_id} 
                                        searchResult={true}/>
                                );
                            })}
                            {/* <DefaultCopyField label={"Share Link"} value={`www.scuttlebuttresearch.com/folder/${folderId}`} /> */}
                            <div className='share-modal-footer'>
                                <div class="sneaky-button" onClick={handleCopy}>
                                    <ClipboardButton value={`${window.location.origin}/folder/${folder.folder_id}`} />
                                </div>
                                <button className='share-modal-done' onClick={closeModal}>Done</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    )
}

export default FolderShareButton;