import React, {useState, useEffect, useRef} from 'react';
import FolderButton from './FolderButton';
import { useApiDataContext } from './ApiDataContext';

const FolderFolders = ({folders}) => {
    const [targetFolder, setTargetFolder] = useState(null);
    const [moveFolder, setMoveFolder] = useState(null);
    const dragFolder = useRef(null);
    const draggedOverFolder = useRef(null);

    const {moveFolders} = useApiDataContext();

    const handleAddFolder = (folder, targetFolder) => {
        setTargetFolder(null);

        if (folder && targetFolder) {
            moveFolders([folder.folder_id], targetFolder.folder_id);
        }

    }

    function allowDrop(event) {
        event.preventDefault();
    }

    const handleDragStart = (folder) => {
        dragFolder.current = folder;
        setMoveFolder(folder);
    }


    const handleDragOver = (folder) => {
        draggedOverFolder.current = folder;
        setTargetFolder(folder);
    }

    useEffect(() => {
        // if the target folder is the same as the move folder, then null out the target folder
        if (moveFolder && targetFolder && moveFolder.folder_id === targetFolder.folder_id) {
            setTargetFolder(null);
        }
    }, [targetFolder, moveFolder]);

    return (
        <div className='folder-buttons-container'>
            {folders && folders.map((folder) => {
                return (
                    <div 
                        className='folder-button-container'
                        draggable='true'
                        onDragStart={() => {
                            handleDragStart(folder);
                        }}
                        onDragOver={(event) => {allowDrop(event)}}
                        onDragEnter={() => {handleDragOver(folder)}}
                        onDragEnd={() => {handleAddFolder(dragFolder.current, draggedOverFolder.current)}}
                        key={folder.folder_id}
                    >
                        <FolderButton folder={folder} key={folder.folder_id} targetFolder={targetFolder}/>
                        {/* <div style={{width: '100px', height: '100px', backgroundColor: 'red'}}></div> */}
                    </div>
                );
            })}
        </div>
    );
}

export default FolderFolders;
