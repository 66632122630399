import React from 'react';
import HeaderBar from '../components/HeaderBar';
import { Outlet } from 'react-router-dom';

const AppLayout = () => {
    return (
        <div className="app-layout">
            <HeaderBar />
            <main className="app-content">
                <Outlet />
            </main>
        </div>
    );
};

export default AppLayout; 