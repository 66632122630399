import React, { useState, useEffect } from 'react';
import PostDisplay from '../components/PostDisplay';
import { useApiDataContext } from '../components/ApiDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';

function News () {
    const { folderPosts } = useApiDataContext();
    const [similarPosts, setSimilarPosts] = useState([]);
    const folder_id = 'All'
    const [selectedPeriod, setSelectedPeriod] = useState(30);
    const [loading, setLoading] = useState(true);
    const { flaskServerAddress } = useAuth();

    useEffect(() => {
        if (folderPosts && folderPosts[folder_id] && similarPosts.length === 0) {
            console.log('folder posts: ', folderPosts[folder_id]);
            getNews();
        }
    }, [folderPosts]);

    useEffect(() => {
        console.log('selected period: ', selectedPeriod)
        getNews();
    }, [selectedPeriod]);

    useEffect(() => {
        console.log('similar posts: ', similarPosts);
    }, [similarPosts]);

    const getNews = async () => {
        const body = JSON.stringify({'uuid': folder_id, 'posts':folderPosts[folder_id], 'days': selectedPeriod});
        setLoading(true);
        fetch(flaskServerAddress + 'similar-posts-for-folder/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
        .then(response => response.json())
        .then(data => {
            const jsonData = JSON.parse(data);
            setSimilarPosts(jsonData);
            setLoading(false);
        })
        
        .catch((error) => {
            console.error('Error:', error);
            setSimilarPosts([]);
            setLoading(false);
        });
    }

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
    }

    return (
        <div className='scene'>
            <div className='post-details-container similar-posts'>
                <div className='post-details similar-posts'>
                    <div className='news-header-container'>
                        <h1 className = 'similar-posts-header'>News Feed</h1>
                        <select className='news-dropdown' value={selectedPeriod} onChange={handlePeriodChange}>
                            <option value={1}>Past Day</option>
                            <option value={7}>Past Week</option>
                            <option value={30}>Past Month</option>
                        </select>
                    </div>
                    {!loading && similarPosts  && 
                        <div className='similar-posts-container'>
                            <PostDisplay data={similarPosts} />
                        </div>
                    }
                    {loading && 
                        <div className='progress-indicator'>
                            <CircularProgress /> 
                        </div>
                    }
                    {!loading && (!similarPosts || similarPosts.length === 0) &&
                        <div className='progress-indicator'>
                            <h3>No news found for the selected period.</h3>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default News;