import React, { useState, useEffect } from 'react';
import Post from '../components/Post';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PostDisplay from '../components/PostDisplay';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';

// PostDetail shows, for any given post, the post itself, its parent, and its kids
function SimilarPosts () {
    let { id } = useParams();
    const { flaskServerAddress } = useAuth();
    const [posts, setPosts] = useState([]);
    const [originalPost, setOriginalPost] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            console.log('Fetching data...')
            try {
                console.log('ID: ', id);
                const data = await axios.get(flaskServerAddress + `similar-posts/${id}`);
                console.log('Data: ', data.data);
                setPosts(data.data.posts);
                setOriginalPost(data.data.original_post);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [id]);

    useEffect(() => {
        console.log('Posts: ', posts);
        console.log('Original Post: ', originalPost);
    }, [posts, originalPost]);

    return (
        <div className="scene">
            <div className='post-details-container similar-posts centered-container'>
                <div className='post-details similar-posts standard-width'>
                    {originalPost && originalPost.id && posts ? 
                        <div>
                            <div className='original-post'>
                                <h1>Original Post</h1>
                                <Post post={originalPost} key={originalPost.id} show_similar_post_button={false}/>
                            </div>
                                <div className='similar-posts-container'>
                                    <h1 className = 'similar-posts-header'>Similar Posts</h1>
                                    <PostDisplay data={posts} />
                                </div>
                        </div>
                        :
                        <div className='progress-indicator'>
                            <CircularProgress /> 
                        </div>
                    }
                </div>
            </div>
        </div>

    );
}

export default SimilarPosts;