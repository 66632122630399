import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext'; // Adjust the import path as needed

function SearchScene() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [summary, setSummary] = useState('');
  const { userId, userDetails, serverAddress } = useAuth();

const handleSearch = async (e) => {
    e.preventDefault();
    
    if (!userId || !userDetails?.user_org?.id) {
        console.error('User ID or User Org ID is missing');
        return;
    }
    try {
        console.log('searching query', query, 'for user', userId);
        const encodedQuery = encodeURIComponent(query);
        const url = `${serverAddress}search-my-files/${userId}/${userDetails.user_org.id}?query=${encodedQuery}`;
        const response = await fetch(url);
    
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    
    const data = await response.json();
    console.log('data', data)
    setResults(data.results);
    setSummary(data.summary);
    } catch (error) {
        console.error('Error searching files:', error);
    }
};

  return (
    <div className='ask-my-files centered-container'>
        <div className='standard-width'>
            <br/>
            <br/>

            <form onSubmit={handleSearch} className='sign-up-form'>
                <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter your search query"
                />
                <button type="submit" className='button-blue'>Search</button>
            </form>
            <br/>
            <br/>
            {summary && (
                <div>
                    <h4>Answer</h4>
                    <p>{summary}</p>
                </div>
            )}
            <br/>
            <br/>
            <h4>Sources</h4>
            {results.length > 0 && (
                <ul>
                {results.map((result, index) => (
                    <li key={index}>
                        <p>{result.text}</p>
                        <a href={`${result.file}#page=${result.page}`} target="_blank" rel="noopener noreferrer">View Source</a>
                        <hr className='post-hr'></hr>
                    </li>
                ))}
                </ul>
            )}
      </div>
    </div>
  );
}

export default SearchScene;