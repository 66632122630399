import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';

class ClipboardButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false
    };
  }

  copyToClipboard = () => {
    const { value } = this.props;
    navigator.clipboard.writeText(value)
      .then(() => {
        this.setState({ copied: true });
        setTimeout(() => {
          this.setState({ copied: false });
        }, 2000); // Reset copied state after 2 seconds
      })
      .catch(err => console.error('Could not copy to clipboard: ', err));
  };

  render() {
    return (
      <div>
        <button onClick={this.copyToClipboard} className='gray-button centered-icon-button'>
            {this.state.copied ? <FileDownloadDoneIcon className='icon'/> : <ContentCopyIcon className='icon'/>}
            {this.state.copied ? 'Copied!' : 'Copy Link'}
        </button>
      </div>
    );
  }
}

export default ClipboardButton;
