import React from "react";
import LandingSection from "./LandingSection";
import Carousel from "./Carousel";

export const PhilosophyQuotes = (props) => {
  const renderQuote = (quote, isActive) => (
    <div className="philosophy-quote-card">
      <div className="philosophy-quote-content">
        <div 
          className="philosophy-quote-text" 
          dangerouslySetInnerHTML={{ __html: quote.text }}
        />
        <div className="philosophy-quote-author">
          <img 
            src={quote.img} 
            alt={quote.name} 
            className="philosophy-quote-image"
          />
          <div className="philosophy-quote-name">{quote.name}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div id="philosophy">
      <LandingSection
        title="The Scuttlebutt Philosophy"
        subtitle="The internet is full of untapped signal. Industry discussion boards, blogs, product reviews, and even customer support forums contain nuggets of gold that would be incredibly useful for an investor."
        bottomText="We believe that the key to investment research is to learn directly from the users, competitors, and employees that know these products intimately. Scuttlebutt helps you find what those people are saying, so that you can get the real story."
        customContent={
          <div className="philosophy-carousel-wrapper">
            <Carousel
              items={props.data || []}
              renderItem={renderQuote}
              className="philosophy-carousel"
              slideClassName="philosophy-slide"
              containerClassName="philosophy-slides-container"
              autoPlayInterval={6000}
            />
          </div>
        }
      />
    </div>
  );
};