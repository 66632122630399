import React from 'react';
import FileUploader from '../components/FileUploader';

const UploadFiles = () => {
    return (
        <div className="upload-files-container">
            <h1>Upload Files</h1>
            <p>Select and upload your files using the form below.</p>
            <FileUploader />
        </div>
    );
};

export default UploadFiles;
