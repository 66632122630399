import React, {useState, useEffect, useRef} from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useApiDataContext } from './ApiDataContext';
import { useAuth } from './AuthContext';

function NewBookmarkFolder({newFolderModalIsOpen, closeNewFolderModal, newFolderPostId=null, parentId=null}) {
    const { fetchFolders, addPersonRole, fetchBookmarks, createFolder } = useApiDataContext();
    const { userId } = useAuth();
    const [newFolderName, setNewFolderName] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        // Focus the input element when the component mounts
        if (inputRef.current){
            inputRef.current.focus();
        }
    }, [newFolderModalIsOpen]);

    const handleChange = (event) => {
        setNewFolderName(event.target.value);
    };

    // on key enter, create folder
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCreate();
        }
    }

    const handleCreate = async () => {
        if (newFolderName === '') {
            alert('Please choose a name for the folder!');
            return;
        }
        console.log('new folder post ID', newFolderPostId)
        createFolder(newFolderName, newFolderPostId, parentId);
        setNewFolderName('');
        closeNewFolderModal();
    }

        
    

    return (
        <div>
        {newFolderModalIsOpen && 
            <div className='new-folder-modal'>
                <div className="inputs-container">
                    <h1 className='title'>Create New Folder</h1>
                    < br/>
                    <input 
                        type='text' 
                        className='new-folder-name-input'
                        placeholder='Folder Name' 
                        ref={inputRef}
                        value={newFolderName}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                    />
                    <div className="bottom-bar">
                        <button 
                            className='modal-button'
                            onClick={() => handleCreate()}
                        >
                            Create
                        </button>
                    </div>
                </div>
                <button onClick={closeNewFolderModal} className='modal-close-button'><CloseIcon /></button>
            </div>
        }
        </div>
    );
}

export default NewBookmarkFolder;