// component that shows the summary for a given folder of bookmarks
// This takes a folder ID and an optional boolean for whether or not to allow the user to generate a new summary

import React, { useState, useEffect, useContext } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const FolderSummary = ({summary, handleSummarize, canEdit}) => {

    const [show, setShow] = useState(false);
    const [cleanedSummary, setCleanedSummary] = useState(null);

    useEffect(() => {
        if (summary) {
            const cleanedText = summary.summary.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
            setCleanedSummary(cleanedText);
        }
    }, [summary]);
    

    return (
        <div>
            {summary &&
                <div className='collapsible-section-container'>
                    <div className='summary-header'>
                        <h4>Summary</h4>
        

                        <div className='show-hide-products gray-button no-background' onClick={() => setShow(!show)}>
                            {show ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
                        </div>
                    </div>
                    { show && 
                        <div>
                            <div className='summary-text' dangerouslySetInnerHTML={{ __html: cleanedSummary }} />

                            <div className='summary-footer'>
                                <p className='generation-time'>Last generated: {summary.created_at}</p>
                                {/* {summary.numNewBookmarks > 0 && canEdit && */}
                                {
                                    <div className='summary-refresh'>
                                        <button className='summarize-button summary-button-gray' onClick={handleSummarize}> 
                                            <div className='refresh-icon'>
                                                <RefreshIcon/> 
                                            </div>
                                            Update Summary
                                        </button>
                                        <p className='new-bookmarks-text'>{summary.numNewBookmarks} New Bookmarks</p>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default FolderSummary;