import React, {createContext, useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CssBaseline } from "@mui/material";
import { ApiDataProvider } from'./components/ApiDataContext.js';
import { AuthProvider } from './components/AuthContext';
import ReactGA from 'react-ga4';
// Import layouts
import LandingLayout from './layouts/LandingLayout';
import AppLayout from './layouts/AppLayout';
// Import components
import LoginModal from './components/LoginModal.js';
import NewFolderModal from './components/NewFolderModal.js';
// Import all scenes/pages
import Main from './scenes/Search';
import MyBookmarks from './scenes/MyBookmarks';
import Landing from './scenes/Landing';
import PostDetail from './scenes/PostDetail.js';
import SimilarPosts from './scenes/SimilarPosts.js';
import FolderSimilarPosts from './scenes/FolderSimilarPosts.js';
import ForYou from './scenes/ForYou.js';
import News from './scenes/News.js';
import Account from './scenes/Account.js';
import CompanyPage from './scenes/CompanyPage.js';
import FolderPage from './scenes/FolderPage.js';
import SocialCard from './components/SocialCard.js';
import FolderSettings from './scenes/FolderSettings.js';
import TwitterSearchTool from './twitter-tool/TwitterSearchTool.js';
import TwitterPricing from './twitter-tool/TwitterPricing.js';
import TwitterLandingV2 from './twitter-tool/TwitterLandingV2.js';
import PaymentConfirmation from './twitter-tool/PaymentConfirmation.js';
import AdvancedFeaturePricing from './twitter-tool/AdvancedFeaturePricing.js';
import TranscriptViewer from './scenes/TranscriptViewer.js';
import QandA from './scenes/QandA.js';
import WITG from './scenes/WITG.js';
import WITGSignUp from './scenes/WITGSignUp.js';
import ResetPassword from './scenes/ResetPassword.js';
import UpdatePassword from './scenes/UpdatePassword.js';
import Toy from './scenes/Toy.js';
import Login from './scenes/Login.js';
import UploadFiles from './scenes/UploadFiles.js';
import AskMyFiles from './scenes/AskMyFiles.js';
import SupportReps from './scenes/SupportReps.js';
import RelatedPostsTester from './scenes/RelatedPostsTester.js';
import ThematicSearch from './scenes/ThematicSearch.js';
import ManagerPage from './scenes/ManagerPage.js';
import BookDemo from './scenes/BookDemo';
import PrivacyPolicy from './scenes/PrivacyPolicy';
import TermsOfService from './scenes/TermsOfService';
import NPSCaseStudy from './components/CaseStudies/NPSCaseStudy';
import LeaverJoinerCaseStudy from './components/CaseStudies/LeaverJoinerCaseStudy';
import CursorPricingCaseStudy from './components/CaseStudies/CursorPricingCaseStudy';

export const ModalContext = createContext();

function App() {
    ReactGA.initialize('G-HDMK9NNJJS');

    const [loginModalIsOpen, setIsOpen] = useState(false);
    const [newFolderModalIsOpen, setNewFolderIsOpen] = useState(false);
    const [newFolderPostId, setNewFolderPostId] = useState(null);
    const [newFolderParentId, setNewFolderParentId] = useState(null);

    const closeLoginModal = () => { 
        setIsOpen(false);
    } 

    const openLoginModal = () => {
        setIsOpen(true);
    }

    const closeNewFolderModal = () => {
        setNewFolderIsOpen(false);
    }

    const openNewFolderModal = (post_id, parentId) => {
        console.log('openin new folder modal');
        console.log('post id on folder create: ', post_id);
        console.log('parent id on folder create: ', parentId);
        setNewFolderPostId(post_id);
        setNewFolderParentId(parentId);
        setNewFolderIsOpen(true);
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <BrowserRouter>
            <AuthProvider>
                <div>
                    <LoginModal 
                        loginModalIsOpen={loginModalIsOpen} 
                        closeLoginModal={closeLoginModal}
                    />
                    {newFolderModalIsOpen && <div className="modal-overlay" onClick={closeNewFolderModal}></div>}

                    <ModalContext.Provider 
                        value={{ 
                            loginModalIsOpen, openLoginModal, closeLoginModal, 
                            newFolderModalIsOpen, openNewFolderModal, closeNewFolderModal, 
                            newFolderPostId 
                        }}
                    >
                        <ApiDataProvider>
                            <NewFolderModal 
                                newFolderModalIsOpen={newFolderModalIsOpen} 
                                closeNewFolderModal={closeNewFolderModal}
                                newFolderPostId={newFolderPostId}
                                parentId={newFolderParentId}
                            />
                            <CssBaseline />
                            
                            <Routes>
                                {/* Marketing/Landing Pages */}
                                <Route element={<LandingLayout />}>
                                    <Route path="/" element={<Landing />} />
                                    <Route path="/case-study/NPS" element={<NPSCaseStudy />} />
                                    <Route path="/case-study/leaver-joiner" element={<LeaverJoinerCaseStudy />} />
                                    <Route path="/case-study/cursor-pricing" element={<CursorPricingCaseStudy />} />
                                    <Route path="/book-demo" element={<BookDemo />} />
                                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                    <Route path="/terms-of-service" element={<TermsOfService />} />
                                </Route>

                                {/* App Pages */}
                                <Route element={<AppLayout />}>
                                    <Route path="/search" element={<Main />} />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/for-you" element={<ForYou />} />
                                    <Route path="/news" element={<News />} />
                                    <Route path="/bookmarks" element={<MyBookmarks />} />
                                    <Route path="/similar-posts/:id" element={<SimilarPosts />} />
                                    <Route path="/folder-similar-posts/:folder_id" element={<FolderSimilarPosts />} />
                                    <Route path="/account" element={<Account />} />
                                    <Route path="/company/:id" element={<CompanyPage />} />
                                    <Route path="/folder/:folderId" element={<FolderPage />} />
                                    <Route path="/post/:id" element={<PostDetail />} />
                                    <Route path="/post-card/:id" element={<SocialCard />} />
                                    <Route path="/folder-settings/:folderId" element={<FolderSettings />} />
                                    <Route path="/twitter-search-tool" element={<TwitterSearchTool />} />
                                    <Route path="/twitter-pricing" element={<TwitterPricing />} />
                                    <Route path="/advanced-feature-pricing" element={<AdvancedFeaturePricing />} />
                                    <Route path="/twitter-landing" element={<TwitterLandingV2 />} />
                                    <Route path="/payment-confirmation" element={<PaymentConfirmation />} />
                                    <Route path="/transcript-viewer" element={<TranscriptViewer />} />
                                    <Route path="/qanda" element={<QandA />} />
                                    <Route path="/witg" element={<WITG />} />
                                    <Route path="/witg-signup" element={<WITGSignUp />} />
                                    <Route path="/reset-password" element={<ResetPassword />} />
                                    <Route path="/update-password" element={<UpdatePassword />} />
                                    <Route path="/toy" element={<Toy />} />
                                    <Route path="/upload-files" element={<UploadFiles />} />
                                    <Route path="/ask-my-files" element={<AskMyFiles />} />
                                    <Route path="/support-reps" element={<SupportReps />} />
                                    <Route path="/related-posts-tester" element={<RelatedPostsTester />} />
                                    <Route path="/thematic-search" element={<ThematicSearch />} />
                                    <Route path="/managers/:id" element={<ManagerPage />} />
                                </Route>
                            </Routes>
                        </ApiDataProvider>
                    </ModalContext.Provider>
                </div>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;