import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from './supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    // const serverAddress = 'http://localhost:5000/';
    const serverAddress = 'https://scuttlebutt-backend.vercel.app/';
    
    // const flaskServerAddress = 'http://127.0.0.1:5000/';
    const flaskServerAddress = 'https://hnapi-sepia.vercel.app/';

    // const processingServerAddress = 'http://127.0.0.1:8000/';
    const processingServerAddress = 'https://sb-chrome-api.vercel.app/';

    const [userDoesNotExist, setUserDoesNotExist] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [userOrg, setUserOrg] = useState('');
    const [ip, setIp] = useState('');
    const mobileWidth = 768;
    const [scuttlesiftStatus, setScuttlesiftStatus] = useState('free');
    const [userIsPremium, setUserIsPremium] = useState(true);

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);

    const [session, setSession] = useState(null)
    const [token, setToken] = useState(null);
    const [userId, setUserId] = useState(null)
    const [userDetails, setUserDetails] = useState(null)

    const fetchUserDetails = async (userId) => {
        try {
            const response = await fetch(serverAddress + 'user/details', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    user_id: userId,
                    token: token,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user details');
            }
            const data = await response.json();
            localStorage.setItem('userDetails', JSON.stringify(data));
            setUserDetails(data);
            setScuttlesiftStatus(data.scuttlesift_status)
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    useEffect(() => {
        // on initial page load, try and load the session and user details from local storage
        const loadSession = () => {
            const sessionData = localStorage.getItem('session');
            if (sessionData) {
                const parsedSession = JSON.parse(sessionData);
                setSession(parsedSession);
                const userDetailData = localStorage.getItem('userDetails')
                if (userDetailData) {
                    setUserDetails(JSON.parse(userDetailData))
                }
                else {
                    console.log('userDetails not in local storage - userId is', parsedSession.user.id)
                    fetchUserDetails(parsedSession.user.id)
                }
            }
            else{
                console.log('no session in local storage')
                setSession(null)
                setUserDetails(null)
                setUserId(null)
                setToken(null)
                setUserDoesNotExist(true)
            }

        };

        loadSession();
    }, []);

    useEffect(() => {
        // When the session changes, extract the access token and userId from it for easy usage elsewhere
        if (session) {
            console.log('session', session)
            setToken(session.access_token)
            setUserId(session.user.id)
        }
    }, [session])


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < mobileWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const loginUser = async (email, password) => {
        try {
            const response = await fetch(serverAddress + 'auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: email,
                    password: password,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Login failed');
            }

            const data = await response.json();
            console.log('auth data from node:', data);
            if (data.data.session && data.data.session.access_token && data.data.user) {
                setSession(data.data.session);
                setToken(data.data.session.access_token);
                setUserId(data.data.user.id);

                localStorage.setItem('token', data.data.session.access_token);
                localStorage.setItem('userId', data.data.user.id);
                localStorage.setItem('session', JSON.stringify(data.data.session));

            } else {
                console.log('Login failed. Please check your credentials.');
            }
        } catch (error) {
            console.error('Login error:', error.message);
            return error.message
        }
    };
    
    const logOut = async () => {
        const { error } = await supabase.auth.signOut()
        if (error) {
            console.error('Logout error:', error.message);
            return error.message
        }
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('session');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('user');


        setUserId(null);
        setSession(null);
        setUserDetails(null);
        setScuttlesiftStatus('free');

        console.log('Logged out');
        window.location.href = '/';
    }

    const signUpUser = async (email, password, name, company_name) => {
        console.log(email, password, name, company_name)
        const response = await fetch(serverAddress + 'auth/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email: email,
                password: password,
                full_name: name,
                org_name: company_name,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            console.log('Signup successful:', data);
            
            // Log in the user after successful signup
            await loginUser(email, password);
        } else {
            const errorData = await response.json();
            console.error('Signup failed:', errorData);
            return errorData.error
        }
    }

    return (
        <AuthContext.Provider value={{ 
            session, setSession, 
            userId, 
            token, 
            userDoesNotExist, isLoading, userOrg, ip, isMobile, scuttlesiftStatus, setScuttlesiftStatus,
            userIsPremium, userDetails, fetchUserDetails,
            logOut, loginUser, signUpUser,
            serverAddress, flaskServerAddress, processingServerAddress
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);