// simple component with a header and a TwitterPricing component

import TwitterPricing from './TwitterPricing.js';
import TwitterHeaderBar from './TwitterHeaderBar.js';
import { useAuth } from '../components/AuthContext.js';


const AdvancedFeaturePricing = () => {
    const {isMobile, userId} = useAuth();

    return (
        <div className="centered-container">
            <TwitterHeaderBar isMobile={isMobile} isLandingPage={false} />
            <br />
            <div className="advanced-feature-warning">
                <h3>Sorry, That's a Pro Feature!</h3>
                <p>Get access to advanced search and saved searches by upgrading to Scuttlesift Pro or Enterprise.</p>
            </div>
            <TwitterPricing />
        </div>
    );
};

export default AdvancedFeaturePricing;