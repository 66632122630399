import React from 'react';
import '../../styles/Landing.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const LandingSection = ({ 
    title, 
    subtitle, 
    buttonText, 
    buttonLink, 
    image,
    mobileImage,
    imageMaxWidth = '100%',
    variant = 'stacked',
    buttonVariant = 'link',
    bulletPoints = [],
    bottomText = '',
    onClick = () => buttonLink && (window.location.href = buttonLink),
    id = '',
    customContent = null
}) => {
  return (
    <section className={`landing-section ${variant === 'split' ? 'landing-section-split' : ''}`} id={id}>
      <div className={`landing-content ${variant === 'split' ? 'landing-content-split' : ''}`}>
        <h2 className="landing-title">{title}</h2>
        <div className="landing-section-text">
            <p className="landing-subtitle">{subtitle}</p>
        
            {variant === 'split' && bulletPoints.length > 0 && (
            <ul className="landing-bullets">
                {bulletPoints.map((point, index) => (
                <li key={index}>{point}</li>
                ))}
            </ul>
            )}

            {bottomText && <p className="landing-bottom-text">{bottomText}</p>}
        </div>

        {buttonText && (
            buttonVariant === 'link' ? (
                <button onClick={onClick} className="landing-button">
                    {buttonText} <ArrowForwardIcon sx={{ fontSize: 20 }} />
                </button>
            ) : (
                <button onClick={onClick} className="landing-button-square btn square blue wide tall">
                    {buttonText}
                </button>
            )
        )}
      </div>

      {customContent || (
          <>
              {image && variant === 'split' && (
                  <div 
                      className="landing-image-split"
                      onClick={buttonLink ? onClick : undefined}
                      style={{ cursor: buttonLink ? 'pointer' : 'default' }}
                  >
                      <img 
                          src={image} 
                          alt="" 
                          className="desktop-image" 
                          style={{ maxWidth: imageMaxWidth }}
                      />
                      {mobileImage && <img src={mobileImage} alt="" className="mobile-image" />}
                  </div>
              )}

              {image && variant === 'stacked' && (
                  <div 
                      className="landing-image"
                      onClick={buttonLink ? onClick : undefined}
                      style={{ cursor: buttonLink ? 'pointer' : 'default' }}
                  >
                      <img 
                          src={image} 
                          alt="" 
                          className="desktop-image"
                          style={{ maxWidth: imageMaxWidth }}
                      />
                      {mobileImage && <img src={mobileImage} alt="" className="mobile-image" />}
                  </div>
              )}
          </>
      )}
    </section>
  );
};

export default LandingSection;
