// Architecture:

// const refined_ban_list = [
//     "btc", "crypto", "eth", "discord", "short term target", "technical level", "support level", "breaking through", "trade idea"
//     , "technicals", "technical analysis", "resistance", "hot stock", "stock alert","🚀","🚨", "pullback", "RSI", "4HR", "💰",
//     "stock picks", "Solana", "candle", "memecoin", "doge", "AH update", "weekly chart", "candle chart", "discord", "short term target", "technical level", "support level", "breaking through", "trade idea",
//     "bear flag", "pumped",  "liq", "bitcoin","dip", "LOBO", "token", "tokens", "roburna","dip", "breakout", "equity swings", 
//     "moving average", "blockchain", "AVWAP", "trendline", "🔥", "greenblockchain", "roburnaofficial", "Moving Average", "hodl",
//     "defi", "gas fees", "altcoin", "swing trading", "should rip", "realized profit", "options signals", "📈","📉", "weekly setups", "potential set up", "setup", "set ups",
//     "insane play", "win rate", "insider stock trades", "bear gap", "bull gap", "fake breakdown", "h&s", "head and shoulders",
//     "top analyst target price", "swing trades", "swing trade", "top analyst price target", "target price", "price target"
// ]

const refined_ban_list = [
    "option","options", "put", "call", "puts", "calls", "flow",
    "discord",
    "btc", "crypto", "eth", "coins","coin","token","tokens","pumps",
    "🚀", "🚨", "📈", "📉", "💰",
    "52", // 52 week high / low - might be too aggressive, though
    "radar", "alert", "plays",
    "trade", "trading", "trader",
    "candle", "resistance", "breakout", "pattern","support",
    "bear", "bull", "bearish", "bullish", 
    "alerts", "swing", "technical",
    "earnings",
    "short interest", "dip",
    "gap", 
    "picks", "chart" ,"rotation",
    "IWM", "SPY", "SPX", "QQQ", "TSLA", "NVDA", "AMD", "GME","PLTR",
    "pool", 
    "longs", "shorts",
    "crashing",
    "community",
    "pick","picks",
    "volume","flag",
    "warning", "setup", "set ups", "setups",
    "join", // likely high false positive rate, but also really good filter... divided on this one
    "21", // 21 day moving average
    "dividend",
    "altcoin", "altcoins", 
    "members", "member",
    "BTFD",
    "watchlist", 
    "candlestick", "ATH",
    "inside days", "top analyst",
    "ema", "sma", "macd", "rsi", "vwap",
    "technician",
    "students", 
    "highs","lows",
    "blockchain", "defi", "bitcoin", "solana",
    "volatility",
    "momentum",
    "market open",
    "retest",
    "exchange",
    "flowing",
    "breaking out",
    "green", "red",
    "record", "10x",
    "Roburna"
]

const removeDupesInOrder = (arr) => {
    let found = {};
    let out = [];
    for (let i = 0; i < arr.length; i++) {
        if (!found[arr[i]]) {
            found[arr[i]] = true;
            out.push(arr[i]);
        }
    }
    return out;
}

const makeBanListString = (ban_list, custom_ban_list, searchTerm) => {
    // concat the ban list into the format -"item"-"item"
    // remove dupes - need to do this IN ORDER, since order matters
    let ban_list_clean = ban_list
    if (custom_ban_list) {
        ban_list_clean = custom_ban_list.concat(ban_list_clean);
    }

    ban_list_clean = removeDupesInOrder(ban_list_clean);
    // console.log('ban list clean:', ban_list_clean);

    // remove parent, or, and, ", and the ticker symbol $ from the search term
    let searchList = searchTerm.replace(/ and /g, ' ').replace(/ or /g, ' ').replace(/"/g, ' ').replace(/\$/g, ' ').split(' ');
    // console.log('search list:', searchList);

    const ban_list_str = ban_list_clean.map((item) => {
        // if the item is in search term, don't ban it
        if (searchList.includes(item)) {
            return ''
        }
        if (item.includes(' ')) {
            return `+-"${item}"`
        }
        return `+-${item}`
    }).join('')

    // const ban_list_str = banned_terms_no_spaces_str
    // console.log('length of ban list:', ban_list_str.length);
    return ban_list_str
}

export function searchParamsToLink(search_params) {
    // unpack the search_params object into the makeTwitterSearchLink function
    const {searchTerm, date_min, date_max, custom_ban_list, custom_account_blocks, min_retweets, min_faves, min_replies, social_filter, followed_only, block_hashtags, block_links} = search_params;
    return makeTwitterSearchLink({
        searchTerm, date_min, date_max, custom_ban_list, custom_account_blocks, min_retweets, min_faves, min_replies, social_filter, followed_only, block_hashtags, block_links
    });
}


export function makeTwitterSearchLink ({
    searchTerm, 
    date_min, date_max, 
    custom_ban_list, custom_account_blocks, 
    min_retweets, min_faves, min_replies,
    social_filter, followed_only,
    block_hashtags, block_links
 }) {
    // dates need to be in the format yyyy-mm-dd

    // concat ban_list and custom_ban_list into a list, if custom_ban_list is not null
    const ban_list_str = makeBanListString(refined_ban_list, custom_ban_list, searchTerm);
    // console.log('ban list str:', ban_list_str);

    //  &f=live  <- query for LATEST tweets
    let link = `https://x.com/search?q=`

    let q = `${searchTerm}`

    q+='+lang:en';
        
    if (min_retweets && min_retweets > 0) {
        q += `+min_retweets:${min_retweets}`
    }
    if (min_faves && min_faves > 0) {
        q += `+min_faves:${min_faves}`
    }
    if (min_replies && min_replies > 0) {
        q += `+min_replies:${min_replies}`
    }

    if (date_max) {
        q += `+until:${date_max}`
    }
    if (date_min) {
        q += `+since:${date_min}`
    }

    if (social_filter) {
        q += '+filter:trusted'
    }

    if (followed_only) {
        q += '+filter:follows'
    }

    if (block_hashtags) {
        q += '+-filter:hashtags'
    }

    if (block_links) {
        q +='-filter:links'
    }

    q += ban_list_str

    // Query shortener - will kill the lowest-priority terms first
    // Order matters!!
    // console.log('length of query, before trimming:', q.length);
    if (q.length > 500) {
        // if the query is too long, truncate it at the nearest '+' that makes it less than 500 characters
        const q_split = q.split('+');
        let new_q = '';
        let i = 0;
        for (i = 0; i < q_split.length; i++) {
            // if the new query would be too long, break
            if (new_q.length + q_split[i].length > 500) {
                console.log('what got trimmed? ', q.slice(new_q.length, q.length));
                break;
            }
            new_q += q_split[i] + '+';
        }
        // remove the last '+'
        new_q = new_q.slice(0, -1);
        q = new_q;
    }

    // add custom account blocks
    if (custom_account_blocks && custom_account_blocks.length > 0) {
        q += custom_account_blocks.map((item) => {
            return `+-from:${item}`
        }).join('')
    }

    // console.log('final length of query:', q.length);

    link+=q;


    // the & params don't count against the character limit, since they are not part of the search query (q)
    link+="&src=typed_query";
    // link+="&f=live";



    // replace all spaces with %20
    // link = link.replace(/ /g, '%20');

    // print the # of characters in the link
    // console.log('final link len:', link.length);
    // console.log('link:', link);
    return link
}

export function cleanCorporateName(name){
    // remove all corporate identifiers from the name
    // e.g. "Apple Inc." -> "Apple"
    const to_remove_raw = [
        "Inc.", "Corp.", "Corporation", "Ltd.", "Limited", "PLC", "Public Limited Company", "AG", "Inc", 
        "Co", "Ltd", "Corp", "Group", "Holdings", "Holdings Inc", "Holdings Ltd", "Holdings Corp", "Holdings Corporation",
        "Holdings Group", "Holdings PLC", "Holdings Public Limited Company", "Holdings AG", "Holdings Inc.", "Holdings Ltd.",
        "Holdings Corp.", "Holdings Corporation.", "Holdings Group.", "Holdings PLC.", "Holdings Public Limited Company.", "Holdings AG."
    ]

    const to_remove = to_remove_raw.map((item) => {
        return item.toLowerCase();
    });

    const words = name.toLowerCase().split(' ');
    // console.log('words:', words);
    // remove all instances of the words in to_remove

    let cleaned_name = '';
    words.forEach((word) => {
        if (!to_remove.includes(word)) {
            cleaned_name += word + ' ';
        }
    })

    return cleaned_name.trim();
}