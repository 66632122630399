// This component takes a folder ID, gets the posts in that folder by importing the bookmarks context, and returns a PostDisplay component with those posts

import React, { useState, useEffect, useContext } from 'react';
import { useApiDataContext } from '../ApiDataContext';
import PostDisplay from '../PostDisplay';
import CircularProgress from '@mui/material/CircularProgress';

const FolderPostDisplay = ({header, posts, showBookmarkButton, sources}) => {
    return (
        <div>
            {posts && posts.length > 0 && (
                <div>
                    <PostDisplay header={header} data={posts} showBookmarkButton={showBookmarkButton} sources={sources}/>
                </div>
            )}
            {posts && posts.length === 0 && (
                <div className='no-bookmarks'>
                </div>
            )}
            {!posts && (
                <div className='progress-indicator'>
                    <CircularProgress />
                </div>
            )}
        </div>
    )
}

export default FolderPostDisplay;