import React, {useState} from 'react'
import { useAuth } from '../components/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

function Login({closeLoginModal}) {
    const { session, loginUser, signUpUser, serverAddress } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();

    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);

    const handleSignUp = async (e) => {
        e.preventDefault();
        setError('');
        const name = first_name + ' ' + last_name;
        const signUpError = await signUpUser(email, password, name, company_name);
        console.log('signUpError:', signUpError)
        if (signUpError) {
            setError(signUpError);
        }
    };

    const handleLogIn = async (e) => {
        e.preventDefault();
        setError('');
        const loginError = await loginUser(email, password);
        console.log('loginError:', loginError)
        if (loginError) {
            setError(loginError);
        }
    };

    // handle closing modal
    const handleModalClose = () => {
        console.log('location:', location)
        if (location.pathname === '/'){
            // route the user to /search
            navigate('/search');
        }
        closeLoginModal();
    }

    if (!session) {
    return (
        <div className="login-modal">
            
            {isSignUp && (
                <div className='form-container sign-up-form'>
                    <div className='small-break' />
                    <form onSubmit={handleSignUp}>
                        <div className='form-input'>
                            <label htmlFor='first_name'>First Name</label>
                            <input
                                type="text"
                                id="first_name"
                                value={first_name}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='form-input'>
                            <label htmlFor="last_name">Last Name</label>
                            <input
                                type="text"
                                id="last_name"
                                value={last_name}
                                onChange={(e) => setLastName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='form-input'>
                            <label htmlFor="company_name">Company Name</label>
                            <input
                                type="text"
                                id="company_name"
                                value={company_name}
                                onChange={(e) => setCompanyName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='form-input'>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='form-input'>
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className='small-break' />
                        <button type="submit" className="button-square button-blue log-in-button">Sign Up</button>
                    </form>
                    <button className="sign-in-link" onClick={() => setIsSignUp(false)}>
                        Already have an account? Sign in
                    </button>
                </div>
            )}

            {!isSignUp && (
                <div className='form-container sign-up-form'>
                    <div className='small-break' />
                    <form onSubmit={handleLogIn}>
                    <div className='form-input'>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className='form-input'>
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <div className='small-break' />
                        <button type="submit" className="button-square button-blue log-in-button">Sign In</button>
                    </form>
                    <button className="sign-in-link" onClick={() => setIsSignUp(true)}>
                        Don't have an account? Sign up
                    </button>
                </div>
            )}

        </div>
    )
    }
    else {
        return (
            <div className="login-modal">
                <div className='form-container sign-up-form'>
                    <div className='logged-in-container'>
                        <h1>Logged in!</h1>
                        <h3>Welcome to Scuttlebutt.</h3>
                    </div>
                    <button className=" button-blue get-started-button" onClick={handleModalClose}>
                        Get Started Now
                    </button>
                </div>
            </div>
        )
    }
}

export default Login