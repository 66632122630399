import React from "react";
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const QuoteDisplay = ({ quotes, companyName, onClose }) => {
    return (
        <div className="quote-container">
            <div className="quote-header">
                <h2>Scuttlebutt about <span className="company-name">{companyName}</span></h2>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    className="close-button"
                >
                    <CloseIcon />
                </IconButton>
            </div>
            <div className="quotes-grid">
                {quotes && quotes.map((quote, index) => (
                    <div key={index} className="quote-card">
                        <p dangerouslySetInnerHTML={{ __html: quote.quote }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuoteDisplay;
