import { useState, useEffect } from "react";
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { startOfMonth, format, parse } from 'date-fns';
import PostDisplay from "./PostDisplay";
import SettingsIcon from '@mui/icons-material/Settings';
import { subYears } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const NPSPlot = ({posts, stat_name, title}) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [baselineNPS, setBaselineNPS] = useState(0);
    const [padding, setPadding] = useState(20);
    const [averages, setAverages] = useState({ 
        year: { nps: null, raw: null, noPaddingNps: null, count: null },
        fiveYears: { nps: null, raw: null, noPaddingNps: null, count: null },
        allTime: { nps: null, raw: null, noPaddingNps: null, count: null }
    });
    const [isCumulative, setIsCumulative] = useState(false);
    const [cumulativeData, setCumulativeData] = useState([]);

    const scrollToPosts = () => {
        const postsSection = document.getElementById('nps-posts');
        if (postsSection) {
            const padding = 80; // Adjust this value as needed
            const targetPosition = postsSection.getBoundingClientRect().top + window.pageYOffset - padding;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        }
    };


    const fetchData = async () => {
        
        const data = posts
            .map(post => {
                const assessment = post.post_assessments.find(assessment => assessment.type === stat_name);
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null);

        // Transform the NPS ratings into an actual NPS score, excluding -1 ratings
        const transformedData = data
            .filter(item => item.rating !== null);
        setData(transformedData);
    }

    const calculateCumulativeData = () => {
        if (data.length === 0) return [];

        const sortedData = [...data].sort((a, b) => a.time - b.time);
        let cumulativeSum = baselineNPS * padding;
        let cumulativeCount = padding;
        
        return sortedData.map((item, index) => {
            const itemDate = new Date(item.time * 1000);
            let npsScore = 0;
            if (item.rating > 8) npsScore = 100;
            else if (item.rating <= 6) npsScore = -100;
            else npsScore = item.rating;

            cumulativeSum += npsScore;
            cumulativeCount++;

            const average = Number((cumulativeSum / cumulativeCount).toFixed(1));

            return {
                x: format(itemDate, 'yyyy-MM'),
                y: average,
                originalDataPoints: [item],
                rawAverage: item.rating,
                count: index + 1
            };
        });
    };

    const handleDownloadCSV = () => {
        if (!chartData || !postsReferenced) return;
    
        // Original CSV content
        const aggregatedCsvContent = [
            'period,NPS,Count,Average Review Score',
            ...chartData.datasets[0].data
                .filter(item => item.y !== null)
                .map(item => {
                    const date = parse(item.x, 'yyyy-MM', new Date());
                    const formattedDate = format(date, 'M/d/yyyy');
                    const averageReviewScore = item.rawAverage !== null ? item.rawAverage.toFixed(1) : '';
                    return `${formattedDate},${item.y},${item.count},${averageReviewScore}`;
                })
        ].join('\n');

        // New CSV content for raw post data
        const rawPostsCsvContent = [
            'text,score,rationale,date,url',
            ...data.map(item => {
                const date = new Date(item.time * 1000);
                const formattedDate = format(date, 'M/d/yyyy');
                const combinedText = `${item.title || ''} ${item.text || ''}`.replace(/"/g, '""').replace(/<[^>]*>/g, '');
                const url = `scuttlebuttresearch.com/post/${item.id}`;
                return `"${combinedText}",${item.rating},"${item.explanation.replace(/"/g, '""')}",${formattedDate},"${url}"`;
            })
        ].join('\n');

    
        // Function to create and trigger download
        const downloadCsv = (content, filename) => {
            const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };
    
        // Download both CSVs
        downloadCsv(aggregatedCsvContent, `nps_scores_${stat_name}.csv`);
        downloadCsv(rawPostsCsvContent, `raw_posts_${stat_name}.csv`);
    };

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
        }
    }, [posts]);

    const handlePointClick = (event, elements) => {
        if (elements.length > 0) {
            const { datasetIndex, index } = elements[0];
            const pointData = chartData.datasets[datasetIndex].data[index];
            const { x: month, y: value, originalDataPoints } = pointData;
            
            const postsReferenced = posts.map(post => {
                const matchingData = originalDataPoints.find(item => item.id === post.id);
                if (matchingData) {
                    return {
                        ...post,
                        rating: matchingData.rating,
                        confidence: matchingData.confidence,
                        explanation: matchingData.explanation,
                        ratingName: stat_name
                    };
                }
                return null;
            }).filter(Boolean);

            setPostsReferenced(postsReferenced);
            // Use setTimeout to ensure the DOM has updated before scrolling
            setTimeout(scrollToPosts, 0);
        }
    };

    const handleClosePostsReferenced = () => {
        setPostsReferenced([]);
    };

    useEffect(() => {
        if (data.length > 0) {
            const cumulativeData = calculateCumulativeData();
            setCumulativeData(cumulativeData);
            // Sort data by date
            const sortedData = data.sort((a, b) => a.time - b.time);
            
            // Get the range of months
            const startDate = startOfMonth(new Date(sortedData[0].time * 1000));
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
            
            // Generate all months between start and end
            const allMonths = [];
            let currentMonth = startDate;
            while (currentMonth <= endDate) {
                allMonths.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0));
                currentMonth.setMonth(currentMonth.getMonth() + 1);
            }
            
            // Calculate trailing 6-month averages for each month
            let previousAverage = null;
            const trailingAverages = allMonths.map(month => {
                const sixMonthsAgo = new Date(month);
                sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
                
                const last6Months = sortedData.filter(d => 
                    new Date(d.time * 1000) >= sixMonthsAgo && 
                    new Date(d.time * 1000) <= month
                );
                
                let average;
                if (last6Months.length > 0) {
                    const n_simulated_reviews = padding; // Use padding for simulated reviews
                    const simulated_score = baselineNPS; // Use baselineNPS for simulated score
                    
                    const sum = last6Months.reduce((acc, curr) => {
                        if (curr.rating > 8) return acc + 100;
                        if (curr.rating <= 6) return acc - 100;
                        return acc + curr.rating;
                    }, 0);
                    const totalSum = sum + (n_simulated_reviews * simulated_score);
                    const totalCount = last6Months.length + n_simulated_reviews;
                    
                    average = Number((totalSum / totalCount).toFixed(1));
                    previousAverage = average;
                } else {
                    average = previousAverage ? Number(previousAverage.toFixed(1)) : null;
                }
                
                return { 
                    x: format(month, 'yyyy-MM'), 
                    y: average,
                    originalDataPoints: last6Months,
                    rawAverage: last6Months.length > 0 ? last6Months.reduce((sum, curr) => sum + curr.rating, 0) / last6Months.length : null,
                    count: last6Months.length
                };
            });
    
            setChartData({
                labels: isCumulative ? cumulativeData.map(item => item.x) : trailingAverages.map(item => item.x),
                datasets: [
                    {
                        label: isCumulative ? `Cumulative ${stat_name} Rating` : `Trailing 6-Month Average ${stat_name} Rating`,
                        data: isCumulative ? cumulativeData : trailingAverages,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1
                    }
                ]
            });

            const now = new Date();
            const oneYearAgo = subYears(now, 1);
            const fiveYearsAgo = subYears(now, 5);

            const calculateAverages = (startDate) => {
                const filteredData = sortedData.filter(d => new Date(d.time * 1000) >= startDate);
                const monthsSince = Math.max(1, Math.ceil((now - startDate) / (1000 * 60 * 60 * 24 * 30))) / 6;
                // Need to divide by 6 because the padding is used on a trailing 6mo period
                const paddingValue = baselineNPS * monthsSince * padding; 

                let npsSum = 0;
                let rawSum = 0;
                filteredData.forEach(curr => {
                    if (curr.rating > 8) npsSum += 100;
                    else if (curr.rating <= 6) npsSum -= 100;
                    else npsSum += curr.rating;
                    rawSum += curr.rating;
                });

                const totalNpsSum = npsSum + paddingValue;
                const totalCount = filteredData.length + (monthsSince * padding);

                return {
                    nps: Number((totalNpsSum / totalCount).toFixed(0)),
                    noPaddingNps: Number((npsSum / filteredData.length).toFixed(0)),
                    raw: Number((rawSum / filteredData.length).toFixed(1)),
                    count: filteredData.length
                };
            };

            setAverages({
                year: calculateAverages(oneYearAgo),
                fiveYears: calculateAverages(fiveYearsAgo),
                allTime: calculateAverages(new Date(0)) // From the beginning of time
            });
        }
    }, [data, stat_name, baselineNPS, padding, isCumulative]);

    const handleSettingsClick = () => {
        setShowSettings(!showSettings);
    };

    const handleToggleChange = (event) => {
        setIsCumulative(event.target.checked);
    };

    return (
        <div>
            {chartData && (
            <>  
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>Estimated Net Promoter Score (NPS)</h4>
                    <div>
                        <FormControlLabel
                            control={<Switch checked={isCumulative} onChange={handleToggleChange} />}
                            label="Cumulative"
                        />
                        <button onClick={handleDownloadCSV} style={{ background: 'none', border: 'none', cursor: 'pointer', marginRight: '10px' }}>
                            <DownloadIcon />
                        </button>
                        <button onClick={handleSettingsClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            <SettingsIcon />
                        </button>
                    </div>
                </div>
                {showSettings && (
                    <div style={{ marginBottom: '20px' }}>
                        <label>
                            Baseline NPS:
                            <input 
                                type="number" 
                                value={baselineNPS} 
                                onChange={(e) => setBaselineNPS(Number(e.target.value))}
                                style={{ marginLeft: '10px', marginRight: '20px' }}
                            />
                        </label>
                        <label>
                            Padding:
                            <input 
                                type="number" 
                                value={padding} 
                                onChange={(e) => setPadding(Number(e.target.value))}
                                style={{ marginLeft: '10px' }}
                            />
                        </label>
                    </div>
                )}
                <Line
                    data={chartData}
                    options={{
                        responsive: true,
                        scales: {
                            // ... existing scales configuration ...
                            y: {
                                min: -100,
                                max: 100
                            }
                        },
                        plugins: {
                            // Remove the backgroundColorPlugin object from here
                        },
                        onClick: handlePointClick,
                    }}
                    plugins={[{
                        id: 'backgroundColorPlugin',
                        beforeDraw: (chart) => {
                            const ctx = chart.canvas.getContext('2d');
                            const chartArea = chart.chartArea;
                            const yAxis = chart.scales.y;
                            const min = -100;
                            const low = 0;
                            const mid = 30;
                            const high = 100;

                            // Light red background for y < 6
                            ctx.fillStyle = 'rgba(255, 200, 200, 0.5)';
                            ctx.fillRect(chartArea.left, yAxis.getPixelForValue(min), chartArea.right - chartArea.left, yAxis.getPixelForValue(low) - yAxis.getPixelForValue(min));

                            // Yellow background for 6 <= y < 8
                            ctx.fillStyle = 'rgba(255, 255, 200, 0.5)';
                            ctx.fillRect(chartArea.left, yAxis.getPixelForValue(mid), chartArea.right - chartArea.left, yAxis.getPixelForValue(low) - yAxis.getPixelForValue(mid));

                            // Green background for y >= 8
                            ctx.fillStyle = 'rgba(200, 255, 200, 0.5)';
                            ctx.fillRect(chartArea.left, yAxis.getPixelForValue(high), chartArea.right - chartArea.left, yAxis.getPixelForValue(mid) - yAxis.getPixelForValue(high));
                        }
                    }]}
                />
                <br />
                <h4 style={{textAlign: 'left'}}>Average Ratings</h4>
                <table className="average-ratings-table">
                    <thead>
                        <tr>
                            <th>Time Period</th>
                            <th>NPS (Adj.)</th>
                            <th>NPS (Raw)</th>
                            <th>Average Rating</th>
                            <th>Sample Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Past Year</td>
                            <td>{averages.year.nps}</td>
                            <td>{averages.year.noPaddingNps}</td>
                            <td>{averages.year.raw} / 10</td>
                            <td>{averages.year.count}</td>
                        </tr>
                        <tr>
                            <td>Past 5 Years</td>
                            <td>{averages.fiveYears.nps}</td>
                            <td>{averages.fiveYears.noPaddingNps}</td>
                            <td>{averages.fiveYears.raw} / 10</td>
                            <td>{averages.fiveYears.count}</td>
                        </tr>
                        <tr>
                            <td>All Time</td>
                            <td>{averages.allTime.nps}</td>
                            <td>{averages.allTime.noPaddingNps}</td>
                            <td>{averages.allTime.raw} / 10</td>
                            <td>{averages.allTime.count}</td>
                        </tr>
                    </tbody>
                </table>
            </> 
            
            )}
            {postsReferenced.length > 0 &&
                <div id='nps-posts'>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>Posts Referenced</h4>
                        <button onClick={handleClosePostsReferenced} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            <CloseIcon />
                        </button>
                    </div>
                    <h6>Six-Month Period Ended {new Date(postsReferenced[0].time * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })}</h6>
                    <PostDisplay data={postsReferenced} header={''} showBookmarkButton={false} query={''} />
                </div>
            }
        </div>
    )
}

export default NPSPlot;