import React, { useState, useEffect } from 'react';
import PostDisplay from '../components/PostDisplay';
import { useApiDataContext } from '../components/ApiDataContext';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';

function ForYou () {
    const { folderPosts } = useApiDataContext();
    const { flaskServerAddress } = useAuth();
    const [similarPosts, setSimilarPosts] = useState([]);
    const folder_id = 'All'

    useEffect(() => {
        if (folderPosts && folderPosts[folder_id] && similarPosts.length === 0) {
            console.log('folder posts: ', folderPosts[folder_id]);
            getPostsForYou();
        }
    }, [folderPosts]);

    useEffect(() => {
        console.log('similar posts: ', similarPosts);
    }, [similarPosts]);

    const getPostsForYou = async () => {
        const body = JSON.stringify({'uuid': folder_id, 'posts':folderPosts[folder_id]});
        fetch(flaskServerAddress + 'similar-posts-for-folder/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: body
        })
        .then(response => response.json())
        .then(data => {
            const jsonData = JSON.parse(data);
            setSimilarPosts(jsonData);
        })
        
        .catch((error) => {
            console.error('Error:', error);
            setSimilarPosts([]);
        });
    }

    return (
        <div className='scene'>
            <div className='post-details-container similar-posts'>
                <div className='post-details similar-posts'>
                    {similarPosts  && 
                        <div className='similar-posts-container'>
                            <h1 className = 'similar-posts-header'>Recommended Posts</h1>
                            <PostDisplay data={similarPosts} />
                        </div>
                    }
                    {(!similarPosts || similarPosts.length === 0) && 
                        <div className='progress-indicator'>
                            <CircularProgress /> 
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ForYou;