import React, { useState, useEffect, useRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import TodayIcon from '@mui/icons-material/Today';
import dayjs from 'dayjs'; // Import dayjs

const DateRangePicker = ({ onDateChange, startDate, endDate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [start, setStart] = useState(startDate ? dayjs(startDate) : null);
    const [end, setEnd] = useState(endDate ? dayjs(endDate) : null);
    const endDateRef = useRef(null);

    useEffect(() => {
        setStart(startDate ? dayjs(startDate) : null);
        setEnd(endDate ? dayjs(endDate) : null);
    }, [startDate, endDate]);

    const toggleSection = () => {
        setIsOpen(!isOpen);
    };

    const applyFilter = () => {
        console.log('Applying filter with start:', start, 'end:', end);
        onDateChange(start ? start.toDate() : null, end ? end.toDate() : null);
    }

    const handleKeyDown = (e, isStartDate) => {
        if (e.key === 'Enter') {
            if (isStartDate && endDateRef.current) {
                endDateRef.current.focus();
            } else {
                applyFilter();
            }
        }
    };

    return (
        <div className='date-range-picker-container'>
            <div className="date-range-picker-section">
                <div className="date-range-picker-header">
                    {!isOpen && (
                        <button className="filter-button centered-icon-button" onClick={toggleSection}>
                            <TodayIcon className="icon" />
                            Filter Results by Date
                        </button>)}
                    {isOpen && (
                        <button className="close-filter-button" onClick={toggleSection}>
                            <CloseIcon />
                        </button>
                    )}
                </div>

                {isOpen && (
                    <section>
                        <div className="date-pickers">
                            <div className="date-picker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                        label="Start Date" 
                                        slotProps={{ 
                                            textField: { 
                                                size: 'small',
                                                onKeyDown: (e) => handleKeyDown(e, true)
                                            }
                                        }}
                                        value={start} 
                                        onChange={(newValue) => setStart(newValue)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="date-picker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker 
                                        label="End Date" 
                                        slotProps={{ 
                                            textField: { 
                                                size: 'small',
                                                onKeyDown: (e) => handleKeyDown(e, false),
                                                inputRef: endDateRef
                                            }
                                        }}
                                        value={end} 
                                        onChange={(newValue) => setEnd(newValue)}
                                    />
                                </LocalizationProvider>
                            </div>
                            <button className="apply-filter-button" onClick={applyFilter}>Filter</button>
                        </div>
                    </section>
                )}
            </div>
        </div>
    )
}

export default DateRangePicker;
