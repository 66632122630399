import React, { useState, useEffect, useMemo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import PostDisplay from './PostDisplay';
import CloseIcon from '@mui/icons-material/Close';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const PricingPlot = ({ posts, title }) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [demandData, setDemandData] = useState(null);

    const thresholds = {
        veryReceptive: 7,
        moderatelyReceptive: 3,
        notReceptive: -3,
        extremelyNotReceptive: -7
    };

    const colors = {
        veryReceptive: '#2ecc71',
        moderatelyReceptive: '#a9dfbf',
        neutral: '#95a5a6',
        notReceptive: '#f1948a',
        extremelyNotReceptive: '#e74c3c'
    };

    const willingness = {
        veryReceptive: 1,
        moderatelyReceptive: 0.95,
        neutral: 0.65,
        notReceptive: 0.35,
        extremelyNotReceptive: 0.10
    };

    const pricePoints = [0, 0.05, 0.1, 0.15, 0.2]; // 0% to 20% price increase

    const fetchData = () => {
        const pricingData = posts
            .map(post => {
                const assessment = post.post_assessments.find(assessment => assessment.type === 'pricing');
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null);
        console.log('pricingData', pricingData);
        setData(pricingData);
    };

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
        }
    }, [posts]);

    const chartDataMemo = useMemo(() => {
        if (data.length === 0) return null;

        const categoryCounts = {
            veryReceptive: 0,
            moderatelyReceptive: 0,
            neutral: 0,
            notReceptive: 0,
            extremelyNotReceptive: 0
        };

        data.forEach(item => {
            const rating = item.rating;
            if (rating !== null) {
                if (rating > thresholds.veryReceptive) {
                    categoryCounts.veryReceptive++;
                } else if (rating > thresholds.moderatelyReceptive) {
                    categoryCounts.moderatelyReceptive++;
                } else if (rating > thresholds.notReceptive) {
                    categoryCounts.neutral++;
                } else if (rating > thresholds.extremelyNotReceptive) {
                    categoryCounts.notReceptive++;
                } else {
                    categoryCounts.extremelyNotReceptive++;
                }
            }
        });

        return {
            categoryCounts,
            chartData: {
                labels: ['Pricing Receptiveness'],
                datasets: [
                    {
                        label: 'Extremely Not Receptive',
                        data: [categoryCounts.extremelyNotReceptive],
                        backgroundColor: colors.extremelyNotReceptive,
                    },
                    {
                        label: 'Not Receptive',
                        data: [categoryCounts.notReceptive],
                        backgroundColor: colors.notReceptive,
                    },
                    {
                        label: 'Neutral',
                        data: [categoryCounts.neutral],
                        backgroundColor: colors.neutral,
                    },
                    {
                        label: 'Moderately Receptive',
                        data: [categoryCounts.moderatelyReceptive],
                        backgroundColor: colors.moderatelyReceptive,
                    },
                    {
                        label: 'Very Receptive',
                        data: [categoryCounts.veryReceptive],
                        backgroundColor: colors.veryReceptive,
                    },
                ],
            }
        };
    }, [data]);

    const demandDataMemo = useMemo(() => {
        if (!chartDataMemo) return null;
    
        const { categoryCounts } = chartDataMemo;
        const totalPosts = Object.values(categoryCounts).reduce((sum, count) => sum + count, 0);
    
        const demandPoints = pricePoints.map(priceIncrease => {
            let remainingDemand = 0;
            for (const category in categoryCounts) {
                const categoryPercentage = categoryCounts[category] / totalPosts;
                const willingnessToPayAtPrice = Math.max(0, 1 - (priceIncrease / willingness[category]));
                remainingDemand += categoryPercentage * willingnessToPayAtPrice;
            }
            return remainingDemand * 100; // Convert to percentage
        });
    
        return {
            labels: pricePoints.map(p => `+${p * 100}%`),
            datasets: [
                {
                    label: 'Demand',
                    data: demandPoints,
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1
                }
            ]
        };
    }, [chartDataMemo]);

    useEffect(() => {
        setChartData(chartDataMemo?.chartData || null);
    }, [chartDataMemo]);

    useEffect(() => {
        setDemandData(demandDataMemo);
    }, [demandDataMemo]);

    const handleBarClick = (event, elements) => {
        if (elements.length > 0) {
            const { datasetIndex } = elements[0];
            const categories = ['veryReceptive', 'moderatelyReceptive', 'neutral', 'notReceptive', 'extremelyNotReceptive'];
            const selectedCategory = categories[categories.length - 1 - datasetIndex];
            setSelectedCategory(selectedCategory);

            const categoryPosts = getCategoryPosts(selectedCategory);
            setPostsReferenced(categoryPosts);
        } else {
            setSelectedCategory(null);
            setPostsReferenced([]);
        }
    };

    const getCategoryPosts = (category) => {
        if (!category) return [];

        return posts.filter(post => {
            const assessment = post.post_assessments.find(a => a.type === 'pricing');
            if (!assessment || assessment.rating === null) return false;

            const rating = assessment.rating;
            switch (category) {
                case 'veryReceptive':
                    return rating > thresholds.veryReceptive;
                case 'moderatelyReceptive':
                    return rating > thresholds.moderatelyReceptive && rating <= thresholds.veryReceptive;
                case 'neutral':
                    return rating > thresholds.notReceptive && rating <= thresholds.moderatelyReceptive;
                case 'notReceptive':
                    return rating > thresholds.extremelyNotReceptive && rating <= thresholds.notReceptive;
                case 'extremelyNotReceptive':
                    return rating <= thresholds.extremelyNotReceptive;
                default:
                    return false;
            }
        }).map(post => ({
            ...post,
            rating: post.post_assessments.find(a => a.type === 'pricing').rating,
            confidence: post.post_assessments.find(a => a.type === 'pricing').confidence,
            explanation: post.post_assessments.find(a => a.type === 'pricing').explanation,
            ratingName: 'pricing'
        }));
    };

    const handleClosePostsReferenced = () => {
        setPostsReferenced([]);
        setSelectedCategory(null);
    };

    return (
        <div>
            {chartData && (
                <>
                    <h4 style={{textAlign: 'left'}}>{title}</h4>
                    <p style={{textAlign: 'left'}}>Customer Willingness to Accept Price Increase</p>
                    <Bar
                        data={chartData}
                        options={{
                            responsive: true,
                            scales: {
                                x: { stacked: true },
                                y: { 
                                    stacked: true,
                                    title: { display: true, text: 'Count' }
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            const label = context.dataset.label || '';
                                            const value = context.parsed.y;
                                            return `${label}: ${value}`;
                                        }
                                    }
                                }
                            },
                            onClick: handleBarClick,
                            barThickness: 100, // Add this line to set the bar width
                        }}
                    />
                </>
            )}
            {demandData && (
                <>
                    <h4 style={{textAlign: 'left'}}>Estimated Demand Curve</h4>
                    <p style={{textAlign: 'left'}}>Estimated Quantity Demanded as a Function of % Price Increase</p>
                    <Line
                        data={demandData}
                        options={{
                            responsive: true,
                            scales: {
                                x: { 
                                    title: { display: true, text: 'Price Increase' }
                                },
                                y: { 
                                    title: { display: true, text: 'Demand (%)' },
                                    min: 0,
                                    max: 100
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            return `Demand: ${context.parsed.y.toFixed(2)}%`;
                                        }
                                    }
                                }
                            }
                        }}
                    />
                </>
            )}
            {postsReferenced.length > 0 &&
                <div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>Posts Referenced</h4>
                        <button onClick={handleClosePostsReferenced} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            <CloseIcon />
                        </button>
                    </div>
                    <h5>{selectedCategory.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} Posts</h5>
                    <PostDisplay data={postsReferenced} header={''} showBookmarkButton={false} query={''} ratingThresholds={{low: -2, high: 2}} ratingDisplayName={'Pricing Receptiveness'}/>
                </div>
            }
        </div>
    );
};

export default PricingPlot;