// create react component

import React, { useEffect, useState, useRef, useContext } from 'react';
import DOMPurify from 'dompurify';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { formatDate } from '../functions/DateFormatters';
import { useApiDataContext } from './ApiDataContext';
import { useAuth } from './AuthContext';
import  supabase  from './supabaseClient'; // assuming you have a supabase client setup
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { ModalContext } from "../App";
import CloseIcon from '@mui/icons-material/Close';
import RestoreIcon from '@mui/icons-material/Restore';
import IosShareIcon from '@mui/icons-material/IosShare';
import SocialCard from './SocialCard';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useLocation } from 'react-router-dom';

function Post(props) {
    const post = props.post;
    const { ratingThresholds, ratingDisplayName } = props;

    // console.log('post: ', post);
    const { fetchBookmarks, bookmarkFolders, folders, handleBookmark, orderedFolders, useHash, handleStar } = useApiDataContext();
    const { userId } = useAuth();
    const [isHovered, setIsHovered] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [menuHovered, setMenuHovered] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);
    const [bookmarkFolder, setBookmarkFolder] = useState(null);
    const {openNewFolderModal} = useContext(ModalContext);
    const [isDeleted, setIsDeleted] = useState(false);
    const modalContext = useContext(ModalContext);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [foldersInOrder, setFoldersInOrder] = useState([]);
    const [modifiedText, setModifiedText] = useState(false);
    const query = props.query;
    const [showRatingDetails, setShowRatingDetails] = useState(false);
    const [isStarred, setIsStarred] = useState(post.is_starred || false);
    const [expanded, setExpanded] = useState(false);

    const [parentPost, setParentPost] = useState(null);
    const [childPosts, setChildPosts] = useState([]);
    const [showParent, setShowParent] = useState(false);
    const [showChildren, setShowChildren] = useState(false);
    
    const getRatingClass = (rating) => {
        if (rating <= ratingThresholds.low) return 'low';
        if (rating > ratingThresholds.high) return 'high';
        return 'med';
    };
    
    // useEffect(() => {
    //     fetchRelatedPosts();
    // }, [post.id]);

    const fetchRelatedPosts = async () => {
        // Fetch parent post
        const { data: parentData, error: parentError } = await supabase
            .from('post_parent_child')
            .select('parent_id')
            .eq('child_id', post.id)
            .single();


        if (parentData) {
            const { data: parentPost, error: parentPostError } = await supabase
                .from('posts')
                .select('*')
                .eq('id', parentData.parent_id)
                .single();

            if (parentPost) setParentPost(parentPost);
        }

        // Fetch child posts
        const { data: childrenData, error: childrenError } = await supabase
            .from('post_parent_child')
            .select('child_id')
            .eq('parent_id', post.id);

        if (childrenData && childrenData.length > 0) {
            const childIds = childrenData.map(child => child.child_id);
            const { data: childPosts, error: childPostsError } = await supabase
                .from('posts')
                .select('*')
                .in('id', childIds);

            if (childPosts) setChildPosts(childPosts);
        }
        console.log('childPosts: ', childPosts);
    };



    useEffect(() => {
        if (orderedFolders && orderedFolders.length > 0 ) {
            if (bookmarkFolder) {
                moveFolderToFront();
            }
            else {
                setFoldersInOrder(orderedFolders);
            }
            
        }
        
    }, [orderedFolders, bookmarkFolder]);
    
    // If NOT logged in, we may want to show a bookmark button that pops up a login modal
    const showBookmarkButton = useState(true);

    const deleteMessage = useRef('Post Deleted! We will use this feedback to get better at finding relevant content.');

    // if the screen is small enough that it is likely mobile, set deleteMessage to 'Post Deleted'
    if (window.innerWidth <= 768) {
        deleteMessage.current = 'Post Deleted!';
    }

    // useEffect(() => {
    //     // when the post.text loads, replace URLs with anchor tags
    //     var pattern = /\b(?:https?:\/\/\S+)/gi; // Match URLs that start with http:// or https://
    //     // Replace matched URLs with HTML anchor tags
    //     post.text = post.text.replace(pattern, function(url) {
    //         var encodedURL = encodeURIComponent(url);
    //         // Convert certain characters to their respective HTML entities
    //         encodedURL = encodedURL.replace(/\//g, '&#x2F;');
    //         encodedURL = encodedURL.replace(/:/g, '&#x3A;');
    //         // return the anchor tag, with no target for the link
    //         // and open in a new tab
    //         return '<a href="' + encodedURL + '" target="_blank" rel="noreferrer">' + url + '</a>';
            
    //     });

    //     console.log('TEXT: ', post.text);
    // }, []);

    function isVerticalScrollBarActive() {
        return document.documentElement.scrollHeight > document.documentElement.clientHeight;
    }

    const handleMouseEnter = (event) => {
        const rect = event.target.getBoundingClientRect();
        // set position so that the top right corner of the menu is at the bottom right corner of the button
        // const scrollBarOffset = isVerticalScrollBarActive() ? 16 : 0;
        const scrollBarOffset = 16;
        const yOffset = -2;
        setPosition({ x: window.innerWidth - ( rect.right + window.scrollX +scrollBarOffset ), y: rect.bottom + window.scrollY + yOffset });
        setIsHovered(true);
    };

    const handleMouseLeave = (event) => {
        setIsHovered(false);
    }

    const handleMenuMouseEnter = () => {
        setMenuHovered(true);
    };
    
    const handleMenuMouseLeave = () => {
        setMenuHovered(false);
        setIsHovered(false);
    };

    useEffect(() => {
        setBookmarked(checkBookmark());
    }, [bookmarkFolders]);

    function checkBookmark() {
        if (bookmarkFolders === null || bookmarkFolders === undefined) {
            return false;   
        }
        if (bookmarkFolders.length === 0) {
            return false;
        }
        if(post.id in bookmarkFolders) {
            setBookmarkFolder(bookmarkFolders[post.id]);            
            return true; // Found the bookmark, so return true
        }
        return false; // If not found, return false
    }

    function moveFolderToFront() {
        // put the folder bookmarked first
        const foldersIn = [...orderedFolders];
        const bookmarkedFolder = foldersIn.find(folder => folder.folder_id === bookmarkFolder);
        // console.log('bookmarked folder: ', bookmarkedFolder)
        if (bookmarkedFolder) {
            foldersIn.splice(foldersIn.indexOf(bookmarkedFolder), 1);
            foldersIn.unshift(bookmarkedFolder);
        }
        // console.log('folders in order, edited: ', foldersIn);
        setFoldersInOrder(foldersIn);
    }
        

    const handleBookmarkClick = async (folder) => {      
        // if id is already in bookmarks, and no change to folder, delete it
        if (bookmarked && folder === bookmarkFolder) {
            setBookmarked(false);
        }   
        // If it is bookmarked, but we've clicked the bookmark button, remove it
        else if (bookmarked && folder === null) {
            setBookmarked(false);
        }
        // if id is already in bookmarks, and change to folder, update it
        else if (bookmarked && folder !== bookmarkFolder) {
            setBookmarkFolder(folder);
        }
        else {
            setBookmarked(true);
            setBookmarkFolder(folder);
        }
        handleBookmark(post, folder, bookmarked, bookmarkFolder);
    }

    const handleDelete = async () => {
        setIsDeleted(true);
        const { data, error } = await supabase
            .from('deleted_posts')
            .upsert({ user_id: userId, post_id: post.id })
            .select()
        console.log('deleted post: ', post.id);
        console.log('user id: ', userId);
        const { data2, error2 } = await supabase
            .from('bookmarks')
            .delete()
            .match({ user_id: userId, id: post.id })
            .select()
        fetchBookmarks();
    }

    const undoDelete = async () => {
        setIsDeleted(false);
        const { data, error } = await supabase
            .from('deleted_posts')
            .delete()
            .match({ user_id: userId, post_id: post.id })
            .select()
    }

    const handleNewFolder = async () => {
        openNewFolderModal(post.id);   
    }

    const handleStarClick = async (id) => {
        setIsStarred(!isStarred);
        handleStar(id, isStarred);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.share-modal')) {
                setShowShareMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (post.text && query && query.length > 2) {
            const queryTerms = query.split(' ').map(term => term.toLowerCase());
            const queryRoots = queryTerms.map(term => term.toLowerCase().replace(/(ing|ed|s|er|ation|ers|es)$/i, ''));
            
            // Create a temporary DOM element to parse the HTML
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = post.text;
            
            // Function to highlight text and convert URLs within a node
            const processNode = (node) => {
                if (node.nodeType === Node.TEXT_NODE) {
                    const sentences = node.textContent.split(/(?<=[.!?])\s+/);
                    const processedSentences = sentences.map(sentence => {
                        // Highlight matching query terms
                        let processedSentence = sentence;
                        if (queryRoots.some(root => {
                            const regex = new RegExp(`\\b(${root}(?:ing|ed|s|er|ation|ers|es)?)\\b`, 'gi');
                            return regex.test(sentence.toLowerCase());
                        })) {
                            processedSentence = `<span class="highlight">${sentence}</span>`;
                        }
                        
                        // Convert URLs to clickable links
                        const urlRegex = /\b(?:https?:\/\/\S+)/gi;
                        processedSentence = processedSentence.replace(urlRegex, url => {
                            return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
                        });
                        
                        return processedSentence;
                    });
                    
                    const newContent = processedSentences.join(' ');
                    if (newContent !== node.textContent) {
                        const span = document.createElement('span');
                        span.innerHTML = newContent;
                        node.parentNode.replaceChild(span, node);
                    }
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    Array.from(node.childNodes).forEach(processNode);
                }
            };
            
            // Apply processing to all nodes
            Array.from(tempDiv.childNodes).forEach(processNode);
            
            // Set the modified HTML
            setModifiedText(tempDiv.innerHTML);
        } else if (post.text) {
            // If there's no query, just convert URLs to clickable links
            const urlRegex = /\b(?:https?:\/\/\S+)/gi;
            const processedText = post.text.replace(urlRegex, url => {
                return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
            });
            setModifiedText(processedText);
        }
    }, [query, post.text]);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const renderText = () => {
        if (!modifiedText) return null;

        const charLimit = 800;
        const displayAmount = 400;
        
        if (modifiedText.length <= charLimit || expanded) {
            return (
                <div 
                    className="post-text" 
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(modifiedText) }} 
                    onClick={(e) => {
                        if (e.target.tagName === 'A') {
                            e.preventDefault();
                            window.open(e.target.href, '_blank');
                        }
                    }}
                />
            );
        }

        let truncatedText = modifiedText.substring(0, displayAmount);
        const lastSpaceIndex = truncatedText.lastIndexOf(' ');
        
        if (lastSpaceIndex > 0) {
            truncatedText = truncatedText.substring(0, lastSpaceIndex);
        }

        truncatedText = truncatedText.trim() + '...';

        return (
            <>
                <div className="post-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(truncatedText) }} />
                <button className="read-more-button" onClick={toggleExpand}>Read more</button>
            </>
        );
    };

    return (
        <div className={`post-wrapper ${props.isChild ? 'child-post' : ''}`}>


            {!isDeleted &&
                <div className='post' key={post.id} id={post.id}>
                {post.ratingName && 
                    <div className="post-rating-container">
                        <div className='split'>
                            <p className={`post-rating ${getRatingClass(post.rating)}`}
                                onClick={() => setShowRatingDetails(!showRatingDetails)}
                            >
                                {ratingDisplayName || post.ratingName}: {post.rating !== null ? `${post.rating} / 10` : 'N / A'}
                            </p>
                        </div>
                        {showRatingDetails &&
                            <>
                                <p className="post-rating-explanation">{post.explanation}</p>
                                <p className="post-rating-confidence">Confidence: {post.confidence * 10}%</p>
                            </>
                        }
                    </div>
                }

                <div className='post-header'>
                    <p className="post-time"><em>{formatDate(post.time)}</em></p>  
                    {showBookmarkButton  &&
                        <div>
                            <div className="post-bookmark">
                                <button className ="post-button faded-gray-button" onClick={handleDelete}>
                                    <CloseIcon className='delete-icon'/>
                                </button>
                                
                                <button className='post-button faded-gray-button' onClick={() => setShowShareMenu(true)}>
                                    <IosShareIcon />
                                </button>
                                
                                <button className="post-button" 
                                // if there is a user, handleBookmarkClick, else openLoginModal
                                    onClick={userId ? () => handleBookmarkClick(null) : () => modalContext.openLoginModal(true)}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {bookmarked ? <BookmarkAddedIcon className='bookmarked-icon'/> : <BookmarkBorderIcon />}
                                </button>
                                {
                                    <button className='post-button' onClick={() => handleStarClick(post.id, null)}>
                                        {isStarred ? <StarIcon className='star-icon'/> : <StarBorderIcon className='star-icon'/>}
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>

                <div className="post-container">
                    <div className="split">
                        {post.subtype && <p className="post-subtype">{post.type === 'reddit' ? `r/${post.subtype}` : post.subtype}</p>}
                    </div>
                    {post.title && <p className="post-title">{post.title}</p>}
                    {renderText()}
                </div>

                {/* {parentPost && (
                    <div className="post-parent">
                        <button onClick={() => setShowParent(!showParent)}>
                                {showParent ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                Show Parent Post
                        </button>
                        {showParent && <Post post={parentPost} showBookmarkButton={false} />}
                    </div>
                )} */}


                <div className="post-details">
                    <a href={post.url} className="post-context-link" target='_blank' rel='noreferrer'>Source</a>
                    {post.type !== 'reddit' && <a href={`/details/${post.id}`} className="post-context-link" target='_blank' rel='noreferrer'>See Context</a>}
                    {props.show_similar_post_button === true && <a href={`/similar-posts/${post.id}`} className="post-context-link" target='_blank'>Similar Posts</a>}
                    {childPosts.length > 0 && (
                        <button 
                            className="post-context-link" 
                            onClick={() => setShowChildren(!showChildren)}
                        >
                            {showChildren ? 'Hide' : 'View'} Comments ({childPosts.length})
                        </button>
                    )}
                </div>
                <div className="post-children">
                    {showChildren && childPosts.map(childPost => (
                        <Post key={childPost.id} post={childPost} showBookmarkButton={false} isChild={true} />
                    ))}
                </div>

            </div>
        }


        {isDeleted &&
            <div className='post-header deleted'>
                <div className='deleted-post-container'>
                    <div className='deleted-post-header'>
                        {deleteMessage.current}
                    </div>
                <button className='undo-delete-button' onClick={undoDelete}>
                    <RestoreIcon />
                    Undo
                </button>
                </div>
            </div>
        }


        {(isHovered || menuHovered) && userId && (
            <div className="bookmark-menu" 
                style={{ top: position.y, right: position.x }}
                onMouseEnter={handleMenuMouseEnter}
                onMouseLeave={handleMenuMouseLeave}
            >
                <ul>
                    {foldersInOrder.map((folder) => (
                        <li key={folder.folder_id}>
                            <button className={(bookmarked && bookmarkFolder === folder.folder_id) ? "bookmarked" : ""}
                                onClick={() =>handleBookmarkClick(folder.folder_id)}
                            >
                                {folder.folder_name}
                                {bookmarked && bookmarkFolder === folder.folder_id && 
                                    <CheckIcon className='bookmark-check' fontSize='medium'/>
                                }
                            </button>
                        </li>
                    ))}
                    <li> 
                        <div className='add-folder-button-container'>
                            <button 
                                className='add-folder-button'
                                onClick={handleNewFolder}
                            > 
                                <AddIcon/> 
                                New 
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        )}

        {showShareMenu && 
            // modal that pops up to share the post
            // Has a button to let you copy the link
            // Has icon buttons for twitter and email
            // has an x in the top right corner to close the modal
            <div className='share-background'>
                <div className='share-modal'>
                    <div className='share-post-modal-header-bar'>
                        <h4>Share Post</h4>
                        <span className='share-modal-close' onClick={() => setShowShareMenu(false)}>&times;</span>
                    </div>
                    <div className='share-post-modal-body'>

                        <SocialCard post={post} setImageUrl={setImageUrl}/>
                    </div>
                </div>
            </div>
        
        }
        </div>
    );
}

Post.defaultProps = {
    show_similar_post_button: true,
    ratingThresholds: {
        low: 6,
        high: 8
    }
}

export default Post;
