import React from 'react';

function VideoThumbnail({ video }) {
  const handleClick = () => {
    window.open(video.url, '_blank');
  };

  return (
    <div className="video-thumbnail" onClick={handleClick}>
      <img src={video.thumbnail_url} alt={video.title} />
      <h3>{video.title}</h3>
    </div>
  );
}

export default VideoThumbnail;