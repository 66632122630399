// basic header bar
import React from 'react';

const TwitterHeaderBar = ({isMobile, isLandingPage}) => {

    return (
        <div style={{width: '100%'}}>
            {!isMobile &&
                <div className='header-container'>
                    <div className='landing-navbar split'>
                        <div className='twitter-search-header-container'>
                            <h1 className='twitter-search-header'> 
                                <a href='/twitter-search-tool'>ScuttleSift</a> 
                            </h1>
                            <h4 className='twitter-search-subheader'>
                                <a href='/'>by Scuttlebutt Research</a>
                            </h4>
                        </div>
                        {isLandingPage &&
                        <div className='landing-navbar-right'>
                            <div className='landing-navbar-right-buttons'>
                                <button className='landing-navbar-right-button' 
                                    onClick={() => {window.location.href = '#demo'}}
                                >
                                    Demo
                                </button>

                                <button className='landing-navbar-right-button'
                                    onClick={() => {window.location.href = '#features-section'}}
                                >
                                    Features
                                </button>

                                {/* <button className='landing-navbar-right-button'
                                    onClick={() => {window.location.href = '#testimonials'}}
                                >
                                    Testimonials
                                </button> */}

                                <button className='landing-navbar-right-button'
                                    onClick={() => {window.location.href = '#pricing'}}
                                >
                                    Pricing
                                </button>

                            </div>
                            <button className='try-button'
                                onClick={() => {window.location.href = '#try-it'}}
                            >
                                Try Free
                                </button>
                            </div>
                        }
                    </div>
                </div>
            }

            {isMobile &&
                <div className='mobile-header-container split'>
                    <div className='mobile-header-left'>
                        <h1 className='twitter-search-header white'> 
                            <a href='/twitter-search-tool'>ScuttleSift</a> 
                        </h1>
                        <h4 className='twitter-search-subheader white'>
                            <a href='/'>by Scuttlebutt Research</a>
                        </h4>
                    </div>
                    <div className='mobile-header-right'>
                        <button className='try-button'>Try Free</button>
                    </div>
                </div>
            }
        </div>
        
    );
};

export default TwitterHeaderBar;