// scene showing my twitter search tool
// copilot: create a search bar and a button

import React, { useState, useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterLanding from './TwitterLanding';
import TwitterSearchSection from './TwitterSearchSection';
import TwitterHeaderBar from './TwitterHeaderBar';
import { useAuth } from '../components/AuthContext';

const TwitterSearchTool = () => {
    const [showLandingFlow, setShowLandingFlow] = useState(false);
    const {isMobile, scuttlesiftStatus, userId} = useAuth();
    const [hasPro, setHasPro] = useState(false);

    // useEffect(() => {
    //     if (scuttlesiftStatus === 'pro') {
    //         setShowLandingFlow(false);
    //         setHasPro(true);
    //     }
    // }, [scuttlesiftStatus]);

    useEffect(() => {
        if (userId) {
            setShowLandingFlow(false);
            setHasPro(true);
        }
    }, [userId]);

    return (
        <div>
            {/* <div className="header-bar">
                <div> <h1 className='navbar-header-title'> <a href="/"> <img src='/img/favicon-navbar.png' /> </a> </h1> </div>
                <div className='buttons-left' style={{color: "#fff", size: "40px", paddingRight:"10px"}}>
                    <MenuIcon />
                </div>
                
            </div> */}
            <TwitterHeaderBar isMobile={isMobile} isLandingPage={false} />
            {showLandingFlow ? 
                <TwitterLanding confirmed={() => setShowLandingFlow(false)} />
                :
                <TwitterSearchSection allowAdvanced={hasPro} allowSavedSearches={hasPro} />
            } 
        </div>
    )
}

export default TwitterSearchTool;