import React, { useState, useEffect } from 'react';
import Article from '../Article/Article';
import { Link } from 'react-router-dom';

const LeaverJoinerCaseStudy = () => {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch('/content/case-studies/leaver-joiner.md')
      .then(response => response.text())
      .then(text => setMarkdown(text));
  }, []);

  return (
    <Article
      markdown={markdown}
      callToAction={
        <Link to="/book-demo" className="landing-button-square btn square blue wide tall">
          Book a Demo
        </Link>
      }
    />
  );
};

export default LeaverJoinerCaseStudy; 