import { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import { startOfMonth, format, parse } from 'date-fns';
import PostDisplay from "./PostDisplay";
import SettingsIcon from '@mui/icons-material/Settings';
import { subYears } from 'date-fns';
import DownloadIcon from '@mui/icons-material/Download';
import CloseIcon from '@mui/icons-material/Close';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LeaverJoinerPlot = ({posts, stat_name, titles, product_name}) => {
    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [postsReferenced, setPostsReferenced] = useState([]);
    const [showSettings, setShowSettings] = useState(false);
    const [baselineNPS, setBaselineNPS] = useState(0);
    const [padding, setPadding] = useState(20);
    const [minObservations, setMinObservations] = useState(20);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [competitors, setCompetitors] = useState({});
    const [selectedCompetitor, setSelectedCompetitor] = useState(null);
    const [competitiveComparisons, setCompetitiveComparisons] = useState({});
    const [showCompetitorSettings, setShowCompetitorSettings] = useState(false);
    const [showComparisonSettings, setShowComparisonSettings] = useState(false);
    const [selectedCompetitors, setSelectedCompetitors] = useState([]);
    const [selectedComparisons, setSelectedComparisons] = useState([]);
    const [ratingDisplayName, setRatingDisplayName] = useState('Rating');

    const ratingThresholds = {
        low: -2,
        high: 2
    }

    const [buckets, setBuckets] = useState([
        { name: 'Strong Joiners', min: 6, max: Infinity, color: 'rgba(0, 255, 0, 0.7)' },
        { name: 'Possible Joiners', min: 1, max: 6, color: 'rgba(144, 238, 144, 0.7)' },
        { name: 'Strong Leavers', min: -Infinity, max: -4, color: 'rgba(255, 0, 0, 0.7)' },
        { name: 'Possible Leavers', min: -4, max: -1, color: 'rgba(255, 99, 71, 0.7)' },
        // { name: 'Neutral', min: -2, max: 2, color: 'rgba(128, 128, 128, 0.7)' },
    ]);

    const fetchData = async () => {
        const data = posts
            .map(post => {
                const assessment = post.post_assessments.find(assessment => assessment.type === stat_name);
                if (assessment) {
                    return {
                        ...assessment,
                        time: post.time,
                        id: post.id
                    };
                }
                return null;
            })
            .filter(item => item !== null);

        // Transform the NPS ratings into an actual NPS score, excluding -1 ratings
        const transformedData = data
            .filter(item => item.rating !== null);
        setData(transformedData);
        console.log('transformedData', transformedData);
    }

    const handleDownloadCSV = () => {
        
    };

    useEffect(() => {
        console.log('updated posts', postsReferenced);
        if (postsReferenced.length > 0) {
            setTimeout(scrollToPosts, 10);
        }
    }, [postsReferenced]);

    useEffect(() => {
        if (Array.isArray(posts) && posts.length > 0) {
            fetchData();
            processCompetitiveComparisons();
        }
    }, [posts]);


    useEffect(() => {
        if (data.length > 0) {
            // Sort data by date
            const sortedData = data.sort((a, b) => a.time - b.time);
            
            // Get the range of months
            const startDate = startOfMonth(new Date(sortedData[0].time * 1000));
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);
            endDate.setDate(0);
            
            // Generate all months between start and end
            const allMonths = [];
            let currentMonth = startDate;
            while (currentMonth <= endDate) {
                allMonths.push(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0));
                currentMonth.setMonth(currentMonth.getMonth() + 1);
            }
            
            const counts = allMonths.map(month => {
                const monthData = data.filter(d => 
                    new Date(d.time * 1000) <= month &&
                    new Date(d.time * 1000) > new Date(month.getFullYear(), month.getMonth() - 6, 1)
                );

                const bucketCounts = buckets.map(bucket => ({
                    ...bucket,
                    count: monthData.filter(d => d.rating >= bucket.min && d.rating < bucket.max).length
                }));

                const netCount = bucketCounts.reduce((acc, bucket) => {
                    const joinLeaveWeight = bucket.name.includes('Joiner') ? 1 : -1;
                    const strengthWeight = bucket.name.includes('Strong')  ? 1 : 0.5;
                    return acc + (bucket.count * joinLeaveWeight * strengthWeight);
                }, 0);

                return {
                    x: format(month, 'yyyy-MM'),
                    bucketCounts,
                    netCount,
                    originalDataPoints: monthData,
                    observationCount: monthData.length
                };
            });

            // Filter out months with insufficient observations
            let sufficientCounts = [];
            let rollingCount = 0;
            for (let i = 0; i < counts.length; i++) {
                rollingCount += counts[i].observationCount;
                if (rollingCount >= minObservations) {
                    sufficientCounts.push(counts[i]);
                } else if (sufficientCounts.length > 0) {
                    // If we've already started including months, keep including them
                    sufficientCounts.push(counts[i]);
                }
            }

            setChartData({
                labels: sufficientCounts.map(item => item.x),
                datasets: [
                    ...buckets.map((bucket, index) => ({
                        type: 'bar',
                        label: bucket.name,
                        data: sufficientCounts.map(count => {
                            const value = count.bucketCounts[index].count;
                            return bucket.name.includes('Leaver') ? -value : value;
                        }),
                        backgroundColor: bucket.color,
                        stack: 'Stack 0',
                    })),
                    {
                        type: 'line',
                        label: 'Net',
                        data: sufficientCounts.map(count => count.netCount),
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1,
                    }
                ]
            });

            const competitorCounts = {};
            data.forEach(item => {
                if (item.details && item.details.competitor) {
                    const competitor = item.details.competitor;
                    if (competitor) {
                        if (!competitorCounts[competitor]) {
                            competitorCounts[competitor] = { total: 0, leaving: 0, joining: 0 };
                        }
                        competitorCounts[competitor].total += 1;
                        
                        if (item.rating < 0) {
                            competitorCounts[competitor].leaving += 1;
                        } else if (item.rating > 0) {
                            competitorCounts[competitor].joining += 1;
                        }
                    }
                }
            });

            console.log('Competitor Counts:', competitorCounts);

            // Sort competitors by net benefit (joining - leaving)
            const sortedCompetitors = Object.entries(competitorCounts)
                .sort((a, b) => b[1].total - a[1].total)
                .slice(0, 5);  // Get top 5 for display


            console.log('Sorted Competitors:', sortedCompetitors);

            setCompetitors(Object.fromEntries(sortedCompetitors));
            setSelectedCompetitors(Object.keys(Object.fromEntries(sortedCompetitors)));
        }
    }, [data, buckets, minObservations]);

    const prepareCompetitorChartData = () => {
        const labels = selectedCompetitors;
        const joiningData = labels.map(label => competitors[label]?.joining || 0);
        const leavingData = labels.map(label => -(competitors[label]?.leaving || 0));
        const netData = joiningData.map((joining, index) => joining + leavingData[index]);
    
        return {
            labels,
            datasets: [
                {
                    label: 'Joining',
                    data: joiningData,
                    backgroundColor: 'rgba(144, 238, 144, 0.7)',
                    borderColor: 'rgba(144, 238, 144, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Leaving',
                    data: leavingData,
                    backgroundColor: 'rgba(255, 99, 71, 0.7)',
                    borderColor: 'rgba(255, 99, 71, 1)',
                    borderWidth: 1,
                },
                {
                    type: 'line',
                    label: 'Net',
                    data: netData,
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    borderWidth: 2,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    fill: false,
                }
            ]
        };
    };

    const processCompetitiveComparisons = () => {
        const comparisons = {};
        posts.forEach(post => {
            const competitiveAssessments = post.post_assessments.filter(assessment => assessment.type === 'competitive_comparison');
            competitiveAssessments.forEach(assessment => {
                const competitor = assessment.details && assessment.details.competitor ? assessment.details.competitor.trim() : null;
                if (competitor) {
                    if (!comparisons[competitor]) {
                        comparisons[competitor] = { positive: 0, negative: 0, total: 0 };
                    }
                    comparisons[competitor].total += 1;
                    if (assessment.rating > 0) {
                        comparisons[competitor].positive += 1;
                    } else if (assessment.rating < 0) {
                        comparisons[competitor].negative += 1;
                    }
                }
            });
        });
    
        // Sort competitors by net benefit (positive - negative)
        const sortedComparisons = Object.entries(comparisons)
            .sort((a, b) => (b[1].total) - (a[1].total))
            .slice(0, 5);  // Get top 5 competitors
    
        setCompetitiveComparisons(Object.fromEntries(sortedComparisons));
        setSelectedComparisons(Object.keys(Object.fromEntries(sortedComparisons)));
    };

    const prepareCompetitiveComparisonChartData = () => {
        const labels = selectedComparisons;
        const positiveData = labels.map(label => competitiveComparisons[label]?.positive || 0);
        const negativeData = labels.map(label => -(competitiveComparisons[label]?.negative || 0));
        const netData = positiveData.map((positive, index) => positive + negativeData[index]);
    
        return {
            labels,
            datasets: [
                {
                    label: 'Positive',
                    data: positiveData,
                    backgroundColor: 'rgba(144, 238, 144, 0.7)',
                    borderColor: 'rgba(144, 238, 144, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Negative',
                    data: negativeData,
                    backgroundColor: 'rgba(255, 99, 71, 0.7)',
                    borderColor: 'rgba(255, 99, 71, 1)',
                    borderWidth: 1,
                },
                {
                    type: 'line',
                    label: 'Net',
                    data: netData,
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    borderWidth: 2,
                    pointRadius: 3,
                    pointHoverRadius: 5,
                    fill: false,
                }
            ]
        };
    };
    
    const handleCompetitiveComparisonChartClick = (event, elements) => {
        if (elements.length > 0) {
            const { index } = elements[0];
            const competitor = Object.keys(competitiveComparisons)[index];
            setSelectedCompetitor(competitor);

            const competitorPosts = posts.filter(post => 
                post.post_assessments.some(a => 
                    a.type === 'competitive_comparison' && 
                    a.details && 
                    a.details.competitor === competitor
                )
            )
            .filter(post => post.post_assessments.some(a => a.type === 'competitive_comparison' && a.rating !== null))
            .map(post => {
                const assessment = post.post_assessments.find(a => 
                    a.type === 'competitive_comparison' && 
                    a.details && 
                    a.details.competitor === competitor
                );
                return {
                    ...post,
                    rating: assessment.rating,
                    confidence: assessment.confidence,
                    explanation: assessment.explanation,
                    ratingName: 'competitive_comparison'
                };
            });
            console.log('competitorPosts', competitorPosts);
            setPostsReferenced(competitorPosts);
            setRatingDisplayName('');
            // Use setTimeout to ensure the DOM has updated before scrolling
        }
    };

    const handleCompetitorChartClick = (event, elements) => {
        if (elements.length > 0) {
            const { index } = elements[0];
            const competitor = Object.keys(competitors)[index];
            setSelectedCompetitor(competitor);

            const competitorPosts = posts.filter(post => {
                const assessment = post.post_assessments.find(a => a.type === stat_name);
                return assessment && assessment.details && assessment.details.competitor === competitor && assessment.rating !== null;
            })
            .filter(post => post.post_assessments.some(a => a.type === stat_name && a.rating !== null))
            .map(post => {
                const assessment = post.post_assessments.find(a => a.type === stat_name);
                return {
                    ...post,
                    rating: assessment.rating,
                    confidence: assessment.confidence,
                    explanation: assessment.explanation,
                    ratingName: stat_name
                };
            });
            console.log('competitorPosts', competitorPosts);
            setPostsReferenced(competitorPosts);

            setRatingDisplayName('Net Add Rating');
            // Use setTimeout to ensure the DOM has updated before scrolling
        }

    };

    const handleSettingsClick = () => {
        setShowSettings(!showSettings);
    };

    const handlePointClick = (event, elements) => {
        if (elements.length > 0) {
            const { datasetIndex, index } = elements[0];
            const pointData = chartData.datasets[datasetIndex].data[index];
            const month = chartData.labels[index];
            
            const monthData = data.filter(d => 
                new Date(d.time * 1000) <= new Date(month) &&
                new Date(d.time * 1000) > new Date(new Date(month).setMonth(new Date(month).getMonth() - 6))
            );
            
            const postsReferenced = posts.filter(post => 
                monthData.some(item => item.id === post.id)
            ).map(post => {
                const matchingData = monthData.find(item => item.id === post.id);
                return {
                    ...post,
                    rating: matchingData.rating,
                    confidence: matchingData.confidence,
                    explanation: matchingData.explanation,
                    ratingName: stat_name
                };
            });

            setPostsReferenced(postsReferenced);
            setSelectedMonth(month);
            setRatingDisplayName('Net Add Rating');
            // Remove this line: setPostsReferenced(postsReferenced);
        }
    };

    const handleClosePostsReferenced = () => {
        setPostsReferenced([]);
        setSelectedMonth(null);
        setSelectedCompetitor(null);
    };

    const handleCompetitorSettingsClick = () => {
        setShowCompetitorSettings(!showCompetitorSettings);
    };

    const handleCompetitorSelectionChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCompetitors([...selectedCompetitors, value]);
        } else {
            setSelectedCompetitors(selectedCompetitors.filter(competitor => competitor !== value));
        }
    };

    const handleComparisonSettingsClick = () => {
        setShowComparisonSettings(!showComparisonSettings);
    };

    const handleComparisonSelectionChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedComparisons([...selectedComparisons, value]);
        } else {
            setSelectedComparisons(selectedComparisons.filter(competitor => competitor !== value));
        }
    };

    const checkboxLabelStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '5px 10px',
        borderRadius: '15px',
        backgroundColor: '#f0f0f0',
        transition: 'all 0.3s',
        marginRight: '10px',
        marginBottom: '10px',
    };

    const checkboxInputStyle = {
        position: 'absolute',
        opacity: 0,
        cursor: 'pointer',
        height: 0,
        width: 0,
    };

    const checkboxCustomStyle = (isChecked) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '20px',
        width: '20px',
        marginRight: '8px',
        backgroundColor: isChecked ? '#007bff' : '#fff',
        color: '#fff',
        borderRadius: '4px',
        border: '2px solid #007bff',
        transition: 'all 0.2s',
        fontSize: '14px',
        fontWeight: 'bold',
    });

    const scrollToPosts = () => {
        const postsSection = document.getElementById('posts');
        if (postsSection) {
            const padding = 80; // Adjust this value as needed
            const targetPosition = postsSection.getBoundingClientRect().top + window.pageYOffset - padding;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div>
            {chartData && (
            <>  
                <br />
                <br />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>{titles[0]}</h4>

                    {/* ... settings and download buttons ... */}
                </div>
                {/* ... settings UI ... */}
                <Bar
                    data={chartData}
                    options={{
                        responsive: true,
                        scales: {
                            x: { stacked: true },
                            y: { 
                                stacked: true,
                                title: { display: true, text: 'Count' }
                            },
                            // Remove y1 scale
                        },
                        onClick: handlePointClick,
                    }}
                />

                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4>{titles[1]}</h4>
                        <button onClick={handleCompetitorSettingsClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                            <SettingsIcon />
                        </button>
                    </div>
                    {showCompetitorSettings && (
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '15px' }}>
                            {Object.keys(competitors).map(competitor => (
                                <label key={competitor} style={{
                                    ...checkboxLabelStyle,
                                    backgroundColor: selectedCompetitors.includes(competitor) ? '#e6f2ff' : '#f0f0f0',
                                }}>
                                    <input
                                        type="checkbox"
                                        id={competitor}
                                        value={competitor}
                                        checked={selectedCompetitors.includes(competitor)}
                                        onChange={handleCompetitorSelectionChange}
                                        style={checkboxInputStyle}
                                    />
                                    <span style={checkboxCustomStyle(selectedCompetitors.includes(competitor))}>
                                        {selectedCompetitors.includes(competitor) ? '✓' : ''}
                                    </span>
                                    {competitor}
                                </label>
                            ))}
                        </div>
                    )}
                    <Bar
                        data={prepareCompetitorChartData()}
                        options={{
                            responsive: true,
                            scales: {
                                x: {
                                    stacked: true,
                                    title: {
                                        display: true,
                                        text: 'Competitors'
                                    }
                                },
                                y: {
                                    stacked: true,
                                    title: {
                                        display: true,
                                        text: 'Number of Mentions'
                                    }
                                }
                            },
                            plugins: {
                                title: {
                                    display: true,
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function(context) {
                                            const value = Math.abs(context.parsed.y);
                                            const dataset = context.dataset.label;
                                            return `${dataset}: ${value}`;
                                        }
                                    }
                                }
                            },
                            onClick: handleCompetitorChartClick,
                        }}
                    />
                </div>
            </> 
        )}

        {Object.keys(competitiveComparisons).length > 0 && (
            <div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>{titles[2]}</h4>
                    <button onClick={handleComparisonSettingsClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <SettingsIcon />
                    </button>
                </div>
                {showComparisonSettings && (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '15px' }}>
                        {Object.keys(competitiveComparisons).map(competitor => (
                            <label key={competitor} style={checkboxLabelStyle}>
                                <input
                                    type="checkbox"
                                    id={competitor}
                                    value={competitor}
                                    checked={selectedComparisons.includes(competitor)}
                                    onChange={handleComparisonSelectionChange}
                                    style={checkboxInputStyle}
                                />
                                <span style={checkboxCustomStyle}></span>
                                {competitor}
                            </label>
                        ))}
                    </div>
                )}
                <Bar
                    data={prepareCompetitiveComparisonChartData()}
                    options={{
                        responsive: true,
                        scales: {
                            x: {
                                stacked: true,
                                title: {
                                    display: true,
                                    text: 'Competitors'
                                }
                            },
                            y: {
                                stacked: true,
                                title: {
                                    display: true,
                                    text: 'Number of Mentions'
                                }
                            }
                        },
                        plugins: {
                            title: {
                                display: true,
                                text: 'Competitor Positive vs Negative Analysis'
                            },
                            tooltip: {
                                callbacks: {
                                    label: function(context) {
                                        const value = Math.abs(context.parsed.y);
                                        const dataset = context.dataset.label;
                                        return `${dataset}: ${value}`;
                                    }
                                }
                            }
                        },
                        onClick: handleCompetitiveComparisonChartClick,
                    }}
                />
            </div>
        )}

        {postsReferenced.length > 0 &&
           <div style={{ marginTop: '20px', borderTop: '1px solid #e0e0e0', paddingTop: '20px' }}>
                <br/>

                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '0px'
                }} id='posts'>
                    <h3 style={{ margin: 0, fontWeight: '500', color: '#333', border: 'none', paddingBottom: '0px' }}>Posts Referenced</h3>
                    <button 
                        onClick={handleClosePostsReferenced} 
                        style={{ 
                            background: 'none', 
                            border: 'none', 
                            cursor: 'pointer',
                            color: '#666',
                            fontSize: '24px'
                        }}
                    >
                        <CloseIcon />
                    </button>
                </div>
                {(selectedMonth || selectedCompetitor) && (
                    <p style={{ 
                        margin: '0 0 0px 0', 
                        color: '#666', 
                        fontSize: '14px'
                    }}>
                        {selectedMonth && `Period: ${new Date(selectedMonth).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}`}
                        {selectedMonth && selectedCompetitor && ' | '}
                        {selectedCompetitor && `${product_name} vs. ${selectedCompetitor}`}
                    </p>
                )}
                <PostDisplay 
                    data={postsReferenced} 
                    header={''} 
                    showBookmarkButton={false} 
                    query={''} 
                    ratingThresholds={ratingThresholds} 
                    ratingDisplayName={ratingDisplayName}
                />
            </div>
        }
        </div>
    )
}

export default LeaverJoinerPlot;