import React, { useState, useEffect } from 'react';
import { useAuth } from '../components/AuthContext';

const LoginForm = () => {


    return (
        <div>
            <h2>Login</h2>
        </div>
    );
};

export default LoginForm;