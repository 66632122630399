const Footer = (props) => {
    const handleClick = (e, targetId) => {
        e.preventDefault();
        const cleanTargetId = targetId.replace(/^\/+/, '');
        window.location.href = '/' + cleanTargetId;
    };

    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Company Info Section */}
                <div className="footer-company">
                    <div className="footer-logo">
                        <img src="/img/favicon-navbar.png" alt="Scuttlebutt Research Logo" />
                        <span>Scuttlebutt Research</span>
                    </div>
                    <div className="footer-email">
                        <a href={`mailto:${props.data ? props.data.email : ""}`} className="footer-email-link">
                            {props.data ? props.data.email : "loading"}
                        </a>
                    </div>
                </div>

                {/* Navigation Sections */}
                <div className="footer-nav">
                    <div className="footer-nav-section">
                        <h3>Platform</h3>
                        <ul>
                            <li><a href="/#industry-nps" onClick={(e) => handleClick(e, '#industry-nps')}>Industry NPS Benchmarks</a></li>
                            <li><a href="/#customer-switching" onClick={(e) => handleClick(e, '#customer-switching')}>Customer Switching</a></li>
                            <li><a href="/#pricing-insights" onClick={(e) => handleClick(e, '#pricing-insights')}>Pricing Insights</a></li>
                            <li><a href="/#quote-search" onClick={(e) => handleClick(e, '#quote-search')}>Quote Search</a></li>
                        </ul>
                    </div>

                    <div className="footer-nav-section">
                        <h3>Company</h3>
                        <ul>
                            <li><a href="/#philosophy" onClick={(e) => handleClick(e, '#philosophy')}>About Us</a></li>
                            <li><a href="https://scuttlebuttresearch.substack.com/" target="_blank" rel="noopener noreferrer">Blog</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Bottom Section */}
            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <div className="footer-legal">
                        <span>© 2024 Scuttlebutt Research, Inc.</span>
                        <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a>
                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    </div>
                    
                    <div className="footer-social">
                        <a href="https://x.com/scuttlebuttres" target="_blank" rel="noopener noreferrer">
                            <img src="/img/icons/x.svg" alt="Twitter" />
                        </a>
                        <a href="https://www.linkedin.com/company/scuttlebutt-research" target="_blank" rel="noopener noreferrer">
                            <img src="/img/icons/linkedin2.png" alt="LinkedIn" />
                        </a>
                        <a href="https://scuttlebuttresearch.substack.com/" target="_blank" rel="noopener noreferrer">
                            <img src="/img/icons/substack.png" alt="Substack" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;