import React from 'react'
import LogoGrid from './LogoGrid'

export const CustomerGrid = ({logoList, header}) => {
    
    return (
        <div id="logos" className="customer-grid">
            <div className="container">
                <div className="">
                    <h2 className='customer-header'>{header}</h2>
                </div>
                <br/>
                <LogoGrid logos={logoList} noHover={true} />
            </div>
        </div>
    )
};