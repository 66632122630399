// Scene that allows the user to edit the folder settings
// The folderId is a param on the route
// First, hit Supabase to get the name of the folder from bookmark_folders
// It has the name of the folder at the top, with an edit button right next to it
// It has a "back to folder" button that takes the user back to the bookmarks page
// It has a section for adding a description
// It has a section for adding sompany tags

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FolderShareButton from '../components/FolderViewer/FolderShareButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useApiDataContext } from '../components/ApiDataContext';
import { useNavigate } from 'react-router-dom';
import supabase from '../components/supabaseClient';
import EditIcon from '@mui/icons-material/Edit';
import Tag from '../components/Tag';
import CompanyTagAdder from '../components/CompanyTagAdder';
import FolderDeletor from '../components/FolderDeletor';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../components/AuthContext';

const FolderSettings = () => {
    const { folderId } = useParams();
    const {serverAddress} = useAuth();
    const [folder, setFolder] = useState(null);
    const { folders, removeTag } = useApiDataContext();
    const [editMode, setEditMode] = useState(false);
    const [newFolderName, setNewFolderName] = useState(''); // This is the new folder name that the user is typing in [edit mode]
    const navigate = useNavigate();

    const topicTags = [{'name': 'tag1', 'color': 'red'}, {'name': 'tag2', 'color': 'blue'}, {'name': 'tag3', 'color': 'green'}];

    useEffect(() => {
        if (folders && folders.length > 0 && folderId) {
            const folder = folders.find(folder => folder.folder_id === folderId);
            if (folder) {
                setFolder(folder);
            }
            else {
                console.log('folder not found!')
            }
        }
    }, [folderId, folders]);

    const handleBackToFolder = () => {
        navigate(`/folder/${folderId}`); // Navigate back to the home page
    }

    const activateEditMode = () => {
        setEditMode(true);
    }

    const finishEditing = () => {
        setEditMode(false);
        setNewFolderName(folder.name);
    }

    const refreshTags = async () => {
        const data = await fetch(serverAddress + `get-folder-tags`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                folder_id: folderId,
            }),
        })
        const company_tags = await data.json();
        setFolder({...folder, company_tags: company_tags});
    }

    const saveNewName = async (new_name) => {
        // Save the new folder name
        // TODO: Change this into a Node route
        const data = await fetch(serverAddress + `update-folder-name`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                folder_id: folderId,
                new_name: new_name,
            }),
        })
        setFolder({...folder, name: new_name});
        finishEditing();
    }

    const handleRemoveTag = (tag) => {
        console.log('handle remove tag for tag', tag)
        setFolder({...folder, company_tags: folder.company_tags.filter(t => t.id !== tag.id)});
        removeTag(folderId, tag.id);
    }

    useEffect(() => {
        if (folder) {
            setNewFolderName(folder.name);
        }
    }, [folder]);

    return (
        <>
        {folder && (
        <div className="folder-settings-container scene">
            <div className='folder-settings-header'>
                <div>
                    <button className="centered-icon-button gray-button" onClick={handleBackToFolder}>
                        <ArrowBackIcon className='icon'/>
                        Back to Folder
                    </button>
                </div>
                <div>
                    <FolderShareButton folder={folder} />
                </div>
            </div>

            <div className='folder-settings-middle'>
                <div className='folder-settings-main'>

                    <div className='folder-settings-name'>
                        <div>
                            {!editMode ?
                                <div className='folder-name'>{folder.name}</div>
                                :
                                <input 
                                    value={newFolderName} 
                                    placeholder={folder.name}
                                    onChange={(e) => setNewFolderName(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            saveNewName(newFolderName);
                                        }
                                    }}
                                ></input>
                            }
                        </div>
                        {!editMode ?
                            <div className='edit-icon-container'>
                                <button className="post-button faded-gray-button" onClick={activateEditMode}>
                                    <EditIcon/>
                                </button>
                            </div>
                            :
                            <div className='edit-folder-name-buttons'>
                                <button className="post-button faded-gray-button" onClick={finishEditing}>
                                    Cancel
                                </button>
                                <button className="centered-icon-button gray-button" onClick={() => saveNewName(newFolderName)}>
                                    Save
                                </button>
                            </div>
                        }
                    </div>

                    <div className='folder-settings-input-container'>
                        {/* <h4>Companies</h4> */}
                        <div className='tags-container'>
                            {folder.company_tags && folder.company_tags.length > 0 && folder.company_tags.map((tag, index) => (
                                <Tag key={index} tag={tag} handleRemoveTag={handleRemoveTag}/>
                            ))}
                        </div>
                        <CompanyTagAdder folder={folder} setFolder={setFolder}/>
                        {/* <button className='gray-button'>Save</button> */}
                    </div>

                    <div className='folder-settings-input-container'>
                        <h4>Description</h4>
                        <textarea></textarea>
                    </div>



                    <div className='folder-settings-input-container'>
                        <h4>Topics</h4>
                        <input></input>
                        <div className='tags-container'>
                            {topicTags.map((tag, index) => (
                                <Tag key={index} tag={tag} isCompanyTag={false}/>
                            ))}
                        </div>
                        {/* <button className='gray-button'>Save</button> */}
                    </div>

                    <FolderDeletor folder={folder}/>


                </div>


                {/* <div className='folder-settings-side-panel'>

                    <div className='folder-settings-side-panel-container'>

                        <div className='folder-settings-side-panel-section'>
                            <h4>Folder Sharing</h4>
                            <p>Share this folder with others</p>
                        </div>

                        <div className='folder-settings-side-panel-section'>
                            <h4>Folder Settings</h4>
                            <button className='gray-button'>Delete Folder</button>
                        </div>

                    </div>

                </div> */}
            </div>


        </div>
        )}
        {!folder && (
            <div className='progress-indicator'>
                <CircularProgress /> 
            </div>
        )}
        </>
        
    )
}

export default FolderSettings;