import React, { useState, useEffect, useRef, useCallback } from 'react';

const Carousel = ({ 
  items, 
  renderItem, 
  autoPlayInterval = 5000,
  className = '',
  slideClassName = '',
  containerClassName = ''
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState(0);
  const [currentTranslate, setCurrentTranslate] = useState(0);
  const containerRef = useRef(null);
  const timerRef = useRef(null);

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(() => {
      if (!isDragging) {
        setCurrentSlide((prev) => (prev + 1) % items.length);
      }
    }, autoPlayInterval);
  }, [isDragging, items.length, autoPlayInterval]);

  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isDragging, currentSlide, resetTimer]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
    resetTimer();
  };

  // Touch and mouse handlers
  const handleDragStart = (e) => {
    if (!e) return;
    
    const clientX = e.type === 'mousedown' ? e.pageX : (e.touches?.[0]?.clientX ?? null);
    if (clientX === null) return;
    
    setIsDragging(true);
    setStartPos(clientX);
    setCurrentTranslate(-currentSlide * 100);
    resetTimer();
  };

  const handleDragMove = (e) => {
    if (!isDragging || !e || !containerRef.current) return;
    
    const clientX = e.type === 'mousemove' ? e.pageX : (e.touches?.[0]?.clientX ?? null);
    if (clientX === null) return;
    
    const diff = (clientX - startPos) / containerRef.current.offsetWidth * 100;
    const newTranslate = currentTranslate + diff;
    
    containerRef.current.style.transform = `translateX(${newTranslate}%)`;
  };

  const handleDragEnd = (e) => {
    if (!isDragging || !e) return;
    
    const clientX = e.type === 'mouseup' ? e.pageX : (e.changedTouches?.[0]?.clientX ?? null);
    if (clientX === null) {
      setIsDragging(false);
      return;
    }
    
    const diff = clientX - startPos;
    
    if (Math.abs(diff) > 100) {
      if (diff > 0) {
        setCurrentSlide((prev) => prev === 0 ? items.length - 1 : prev - 1);
      } else {
        setCurrentSlide((prev) => prev === items.length - 1 ? 0 : prev + 1);
      }
    }
    
    setIsDragging(false);
  };

  useEffect(() => {
    const container = containerRef.current;
    
    container.addEventListener('touchstart', handleDragStart);
    container.addEventListener('touchmove', handleDragMove);
    container.addEventListener('touchend', handleDragEnd);
    container.addEventListener('mousedown', handleDragStart);
    container.addEventListener('mousemove', handleDragMove);
    container.addEventListener('mouseup', handleDragEnd);
    container.addEventListener('mouseleave', handleDragEnd);

    return () => {
      container.removeEventListener('touchstart', handleDragStart);
      container.removeEventListener('touchmove', handleDragMove);
      container.removeEventListener('touchend', handleDragEnd);
      container.removeEventListener('mousedown', handleDragStart);
      container.removeEventListener('mousemove', handleDragMove);
      container.removeEventListener('mouseup', handleDragEnd);
      container.removeEventListener('mouseleave', handleDragEnd);
    };
  }, [isDragging, startPos, currentTranslate, currentSlide]);

  return (
    <div className={`carousel ${className}`}>
      <div 
        ref={containerRef}
        className={`carousel-container ${containerClassName}`}
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {items.map((item, index) => (
          <div 
            key={index}
            className={`carousel-slide ${slideClassName} ${index === currentSlide ? 'active' : ''}`}
          >
            {renderItem(item, index === currentSlide)}
          </div>
        ))}
      </div>
      
      <div className="carousel-dots">
        {items.map((_, index) => (
          <button
            key={index}
            className={`carousel-dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel; 