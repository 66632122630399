import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import SearchPreview from './SearchPreview';
import { useAuth } from './AuthContext';

const SearchBar = ({ setData, startDate, endDate, setLoading, setNoResults, onSearch }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const initialQuery = searchParams.get('q') || '';

    const [inputValue, setInputValue] = useState(initialQuery);
    const searchBoxRef = useRef(null);
    const inputRef = useRef(null);
    const { userIsPremium, userId, flaskServerAddress, serverAddress } = useAuth();

    const [isInputFocused, setIsInputFocused] = useState(false);

    const fts_path = flaskServerAddress + 'supabasefts';
    const vector_path = flaskServerAddress + 'pineconesearch';
    // const vector_path = flaskServerAddress + 'vectorgensearch';


    const onlyShowCompanySearch = false;

    const hybridRanking = (ftsData, vectorData, query) => {
        const wordCount = query.trim().split(/\s+/).length;
        const useOnlyFTS = wordCount <= 1;

        if (useOnlyFTS) {
            return ftsData;  // Return only FTS results for 1-2 word queries
        }

        const k = 50;
        const alpha = 0.5;

        const allPosts = [...ftsData, ...vectorData];
        console.log('allPosts: ', allPosts);

        const ftsRank = ftsData.reduce((acc, curr, idx) => {
            acc[curr.id] = idx;
            return acc;
        }, {});

        const vectorRank = vectorData.reduce((acc, curr, idx) => {
            acc[curr.id] = idx;
            return acc;
        }, {});

        const hybridData = allPosts.map(post => {
            let ftsScore = 0;
            let vectorScore = 0;

            if (ftsRank[post.id] !== undefined) {
                ftsScore = 1 / (ftsRank[post.id] + k);
            }

            if (vectorRank[post.id] !== undefined) {
                vectorScore = 1 / (vectorRank[post.id] + k);
            }

            const score = ftsScore * alpha + vectorScore * (1 - alpha);

            return {
                ...post,
                score: score
            };
        });

        hybridData.sort((a, b) => b.score - a.score);

        console.log('ftsRank: ', ftsRank);
        console.log('vectorRank: ', vectorRank);
        console.log('hybridData: ', hybridData);

        return hybridData;
    };

    const fetchData = async (query) => {
        console.log('fetchData called with query:', query, 'startDate:', startDate, 'endDate:', endDate);
        try {
            if (query === '') {
                return;
            }
            setLoading(true);
            
            const fetchData = async (path, start = 0, end = 0) => {
                // Encode the query parameter
                const encodedQuery = encodeURIComponent(query);
                const url = `${path}/${encodedQuery}${start || end ? `/${start}/${end}` : ''}`;
                console.log('Fetching from URL:', url);
                const response = await fetch(url);
                return response.json();
            };
        
            try {
                const start = startDate ? startDate.valueOf() / 1000 : 0;
                const end = endDate ? endDate.valueOf() / 1000 : 0;

                console.log('Fetching with start:', start, 'end:', end);

                const wordCount = query.trim().split(/\s+/).length;
                const useOnlyFTS = wordCount <= 1;

                let ftsData, vectorData;

                if (useOnlyFTS) {
                    ftsData = await fetchData(fts_path, start, end);
                    vectorData = [];
                } else {
                    [ftsData, vectorData] = await Promise.all([
                        fetchData(fts_path, start, end),
                        fetchData(vector_path, start, end)
                    ]);
                }

                console.log('ftsData: ', ftsData);
                console.log('vectorData: ', vectorData);

                const hybridData = hybridRanking(ftsData, vectorData, query);
                console.log('hybridData: ', hybridData);
                if(hybridData.length == 0){
                    setNoResults(true);
                }
                else{
                    setNoResults(false);
                }
                setData(hybridData);
                
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleInputFocus = () => {
        setIsInputFocused(true);
      };
    
    const handleInputBlur = () => {
        // wait
        setTimeout(() => {
            setIsInputFocused(false);
        }, 200);
    };

    async function logSearch(query){
        const response = await fetch(serverAddress + 'log-search/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: new URLSearchParams({
                query: query || '',
                user_id: userId || null,
            }),
        });
        if (response.status !== 200){
            console.error('Error logging search:', response);
        }
    }

    const handleSearch = () => {
        onSearch(inputValue);
        fetchData(inputValue);
        logSearch(inputValue);
        setIsInputFocused(false);
        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        console.log('SearchBar useEffect - startDate:', startDate, 'endDate:', endDate);
        if (initialQuery) {
            fetchData(initialQuery);
        }
    }, [startDate, endDate, initialQuery]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
    <div className='search-box-exterior'>
        <div className='search-box' ref={searchBoxRef}>
            <input 
                className='search-bar-input' 
                value={inputValue} 
                onChange={handleInputChange} 
                placeholder="Search for a company, topic, or theme!" 
                onFocus={() => setIsInputFocused(true)} 
                onBlur={() => setTimeout(() => setIsInputFocused(false), 200)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
                ref={inputRef}
            />
            <button className='search-bar-button' onClick={handleSearch}>
                <SearchIcon />
            </button>
        </div>
        {isInputFocused && inputValue.length > 0 && userIsPremium &&
            <SearchPreview 
                query={inputValue} 
                parentRef={searchBoxRef} 
                onlyShowCompanySearch={onlyShowCompanySearch} 
                handleSearch={handleSearch}
            />
        }
        </div>
    );
}

export default SearchBar;
