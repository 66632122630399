import React from 'react'
import LogoGrid from './LogoGrid'
import { useState, useEffect } from 'react';
import QuoteDisplay from './QuoteDisplay';
import LandingSection from './LandingSection';

export const CoverageGrid = ({logoList}) => {
    const [selectedLogoIndex, setSelectedLogoIndex] = useState(null);
    const [quotes, setQuotes] = useState(null);
    const [companyName, setCompanyName] = useState(null);

    const handleLogoClick = (index) => {
        if (selectedLogoIndex === index) {
            handleClose();
        } else {
            setSelectedLogoIndex(index);
        }
    };

    const handleClose = () => {
        setSelectedLogoIndex(null);
        setQuotes(null);
        setCompanyName(null);
    };

    useEffect(() => {
        if (selectedLogoIndex !== null) {
            setQuotes(logoList[selectedLogoIndex].quotes);
            setCompanyName(logoList[selectedLogoIndex].company);
        }
    }, [selectedLogoIndex]);

    // Custom render function for the grid content
    const renderGrid = () => (
        <div id="scuttlebutt" className="logo-grid-container">
            <LogoGrid 
                logos={logoList} 
                onLogoClick={handleLogoClick} 
                selectedLogoIndex={selectedLogoIndex}
            />
            {selectedLogoIndex !== null && 
                <QuoteDisplay 
                    quotes={quotes} 
                    companyName={companyName} 
                    onClose={handleClose}
                />
            }
        </div>
    );

    return (
        <LandingSection
            id="quote-search"
            title="Find nuggets of gold, without the tedious mining"
            subtitle="Quickly track down key quotes and answers to your highly specific research questions - without manually digging through thousands of posts. Click any logo below to see sample insights."
            customContent={renderGrid()}
        />
    );
};