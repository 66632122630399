// component that allows the user to click in and visit the folder page
// This is just a simple button that takes in a folder and then routes to the folder page /folder/:folder_id
// It has an icon and a label (folder_name)

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useApiDataContext } from './ApiDataContext';

const FolderButton = ({folder, targetFolder}) => {
    const navigate = useNavigate();
    const [isTargetFolder, setIsTargetFolder] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState({x: 0, y: 0});
    const {deleteFolder} = useApiDataContext();

    const handleClick = () => {
      navigate(`/folder/${folder.folder_id}`);
    };

    useEffect(() => {
        if (targetFolder && targetFolder.folder_id === folder.folder_id) {
            setIsTargetFolder(true);
        } else {
            setIsTargetFolder(false);
        }
    }, [targetFolder]);

    const handleContextMenu = (event) => {
        
        event.stopPropagation();
        event.preventDefault();

        const customEvent = new Event('click');
        document.dispatchEvent(customEvent);

        setDropdownPosition({x: event.clientX, y: event.clientY});
        setShowDropdown(true);
    }

    // listener to close the dropdown when clicking outside of it
    useEffect(() => {
        if (showDropdown) {
            document.addEventListener('click', closeDropdown);
        } else {
            document.removeEventListener('click', closeDropdown);
        }

        return () => {
            document.removeEventListener('click', closeDropdown);
        }
    }, [showDropdown]);

    const closeDropdown = () => {
        setShowDropdown(false);
    }

    const handleDelete = () => {
        console.log('delete folder');
        deleteFolder(folder.folder_id);
    }

    const handleRename = () => {
        console.log('rename folder');
        navigate(`/folder-settings/${folder.folder_id}`);
    }


    return (
        <div 
            className={isTargetFolder ? 'folder-button folder-button-target' : 'folder-button'} 
            style={{ cursor: 'pointer' }}
            onContextMenu={handleContextMenu}
            onClick={handleClick}
        >   
            <FolderIcon className='folder-button-icon' />
            <div className='folder-button-text'>{folder.name}</div>

            {showDropdown && 
                <div className='folder-button-dropdown' 
                    style={{ 
                        position: 'absolute', 
                        left: `${dropdownPosition.x}px`, 
                        top: `${dropdownPosition.y}px`
                    }} 
                >
                    <div className='folder-button-dropdown-item' onClick={
                        (e) => {
                            e.stopPropagation();
                            handleRename();
                        }
                    }>
                        Rename
                    </div>
                    
                    <div className='folder-button-dropdown-item'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDelete();
                        }}
                    >
                        Delete
                    </div>
                </div>
            }

                
            <button className='folder-button-more-button'
                onClick={handleContextMenu}
            >
                <MoreVertIcon />
            </button>

        </div>
    );
}

export default FolderButton;