import './Pricing.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const PricingCard = ({isMobile, data}) => {
    
    console.log('is mobile? ', isMobile);

    const handleClick = () => {
        data.handle_click();
        if (!data.link) {
            return;
        }
        if (!data.link.includes('http')) {
            window.location.href = data.link;
        } else {
            window.open(data.link, '_blank');
        }
    }
    
    return (
      <div className={`pricing-card ${isMobile ? 'pricing-card-mobile' : ''}`}>
        <div className="pricing">
            <p className="name">{data.type_name}</p>
            <div className='pricing-section'>
                <p className="price">{data.price}</p>
                <p className="period">{data.period}</p>
            </div>
        </div>
        <button className={`cta-button ${data.cta_type}`} onClick={handleClick}>
            {data.cta}
        </button>
        <br/>
        <br/>

        <div className="features">
            <p className="feature-des">{data.feature_description}</p>
            <div className="feature-section" >
                {data.features.map((feature, index) => {
                    return (
                        <div className='feature-item'>
                            <CheckCircleOutlineIcon />
                            {feature}
                        </div>
                    )
                })}
            </div>
        </div>

        <div className='pricing-footer'>
            {data.footer}
        </div>
      </div>
    )
}
  
export default PricingCard;
