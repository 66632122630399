import React, { useState } from 'react';
import { useAuth } from './AuthContext';
import supabase from './supabaseClient';

const FileUploader = () => {
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    const { userId, userDetails, processingServerAddress } = useAuth();
    console.log(userDetails)
    const pathBase = "https://earxhhhgnpcjfqocieui.supabase.co/storage/v1/object/public/documents/"

    const handleFileChange = (event) => {
        setFiles(Array.from(event.target.files));
    };

    const uploadFiles = async () => {
        setUploading(true);
        setUploadStatus('');

        for (const file of files) {
            const filePath = `${userDetails.user_org.id}/${userId}/${file.name}`;
            const { data, error } = await supabase.storage
                .from('documents')
                .upload(filePath, file, {upsert: true,});
            if (error) {
                console.error('Error uploading file:', error);
                setUploadStatus(prevStatus => prevStatus + `Failed to upload ${file.name}. `);
            } else {
                console.log('data', data)
                const publicUrl = pathBase + data.path;
                console.log(`File uploaded successfully. Public URL: ${publicUrl}`);
                setUploadStatus(prevStatus => prevStatus + `${file.name} uploaded successfully. `);

                // TODO: Call the API with the file links
                // Call the API with the file link
                try {
                    const response = await fetch(`${processingServerAddress}parse-pdf/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ url: publicUrl }),
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const result = await response.json();
                    console.log('API response:', result);
                    setUploadStatus(prevStatus => prevStatus + `${file.name} processed successfully. `);
                } catch (error) {
                    console.error('Error calling API:', error);
                    setUploadStatus(prevStatus => prevStatus + `Failed to process ${file.name}. `);
                }
            }
        }

        setUploading(false);
        setFiles([]);
    };

    return (
        <div>
            <input 
                type="file" 
                multiple 
                onChange={handleFileChange} 
                disabled={uploading}
            />
            <button onClick={uploadFiles} disabled={uploading || files.length === 0}>
                {uploading ? 'Uploading...' : 'Upload Files'}
            </button>
            {uploadStatus && <p>{uploadStatus}</p>}
        </div>
    );
};

export default FileUploader;
