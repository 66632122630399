import React from 'react';
import UserTestimonial from './UserTestimonial';
import LandingSection from './LandingSection';
import Carousel from './Carousel';

const testimonialData = [
    {
        quote: "Very clean way to see what people are actually saying about the business.",
        title: "Hedge Fund Analyst"
    },
    {
        quote: "Parses through all the chaos/noise.",
        title: "Hedge Fund Analyst"
    },
    {
        quote: "Works as advertised!",
        title: "Private Equity Associate"
    },
    {
        quote: "Really impressed by the tool and excited to use it more.",
        title: "Private Equity Associate"
    },
    {
        quote: "Very cool.",
        title: "Investment Banker"
    },
    {
        quote: "I like this a lot!",
        title: "Hedge Fund Analyst"
    }
];

const UserTestimonials = () => {
  const renderTestimonial = (testimonial, isActive) => (
    <UserTestimonial {...testimonial} isActive={isActive} />
  );

  return (
    <div className="testimonials-section">
      <LandingSection
        title="Trusted by investors at leading firms"
        subtitle="Investors at top investment funds use Scuttlebutt to enhance their diligence process, get signal on companies, avoid costly bespoke surveys, and save time."
        customContent={
          <div className="testimonials-container">
            <div className="market-stats-banner">
              <div className="market-metric">
                <div className="metric-number">$250B+</div>
                <div className="metric-description">Total AUM of firms that use Scuttlebutt</div>
              </div>
              <div className="market-metric">
                <div className="metric-number">25%</div>
                <div className="metric-description">of the largest PE firms and hedge funds in the US have team members using Scuttlebutt</div>
              </div>
            </div>
            
            <Carousel
              items={testimonialData}
              renderItem={renderTestimonial}
              className="testimonial-carousel"
              slideClassName="testimonial-slide"
              containerClassName="testimonial-slides-container"
              autoPlayInterval={5000}
            />
          </div>
        }
        id="testimonials"
      />
    </div>
  );
};

export default UserTestimonials; 