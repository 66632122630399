import React, { useEffect, useState, useRef, useContext } from 'react';
import './ScuttleSift.css';
import supabase from '../components/supabaseClient';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TwitterSavedSearches = ({searches, setSearch}) => {

    const handleEdit = (event, search) => {
        // stop propagation
        event.stopPropagation();
        setSearch(search.search_details);
    }
    

    return (
        <div>
            {searches.length > 0 && (
                <div className='saved-searches-header'>
                    <h4 className='saved-searches-title'>Saved Searches</h4>
                    <p> Click on a saved search to run it. </p>
                    </div>
            )}
            <div className='search-item-container'>
                {searches.map(search => {
                    return (
                        <div key={search.search_details.id} 
                            // On click, go to the link
                            onClick={() => window.open(search.search_details.link, '_blank')}
                            className='search-item'
                        >
                                {search.search_details.name}
                            <button onClick={(event) => handleEdit(event, search)} className='edit-button'>
                                <EditIcon />
                            </button>
                        </div>
                    )
                })}
            </div>

            <div className='new-search-button-container'>
                <button className='new-search-button' onClick={() => setSearch(null)}>
                    <AddCircleIcon />
                    <div className='new-search-button-text'>
                        New Search
                    </div>
                </button>
            </div>
        </div>
    )

}

export default TwitterSavedSearches;