import React from 'react';

const UserTestimonial = ({ quote, title, isActive }) => {
  return (
    <div className={`testimonial-slide ${isActive ? 'active' : ''}`}>
      <div className="testimonial-content">
        <div className="testimonial-quote-mark">"</div>
        <div className="testimonial-quote">{quote}</div>
        <div className="testimonial-author">{title}</div>
      </div>
    </div>
  );
};

export default UserTestimonial; 