import React, { useEffect } from 'react';
import { useAuth } from '../components/AuthContext';

function Account() {
    const { session, userId, userDetails, logOut, fetchUserDetails } = useAuth();
    console.log('user details:', userDetails);

    useEffect(() => {
        if (userId){
            fetchUserDetails(userId);
        }
    }, [userId]);

    return (
        <div className='centered-container'>
            <br />
            <div className='standard-width'>
                <h1>Account</h1>
                {session && (
                    <div>
                        <div>
                            {userDetails && (
                                <>
                                    {userDetails.name && <p>{userDetails.name}</p>}
                                    {userId && <p>{userId}</p>}
                                    {userDetails.user_org && userDetails.user_org.name && <p>{userDetails.user_org.name}</p>}
                                </>
                            )}
                            <p>{session.user.email}</p>
                        </div>
                        <div>

                        </div>
                        <div>
                            <button className='button-blue log-out-button' onClick={logOut}>Sign Out</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Account;