import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useAuth } from '../components/AuthContext';

const RelatedPostsTester = () => {
  const [postIds, setPostIds] = useState('');
  const [relatedPosts, setRelatedPosts] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { serverAddress } = useAuth();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRelatedPosts(null);

    try {
      const ids = postIds.split(',').map(id => id.trim());
      const response = await axios.post(`${serverAddress}thread/related-posts`, { postIds: ids });
      setRelatedPosts(response.data.data);
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const renderTree = (node, depth = 0) => (
    <div key={node.id} style={{ marginLeft: `${depth * 20}px` }}>
      <p>{node.id}: {node.text}</p>
      {node.children && node.children.map(child => renderTree(child, depth + 1))}
    </div>
  );

  return (
    <div>
      <h2>Related Posts Tester</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Post IDs (comma-separated):
          <input
            type="text"
            value={postIds}
            onChange={(e) => setPostIds(e.target.value)}
            placeholder="e.g. post1, post2, post3"
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Fetch Related Posts'}
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {relatedPosts && (
        <div>
          <h3>Related Posts:</h3>
          {relatedPosts.map(tree => renderTree(tree))}
        </div>
      )}
    </div>
  );
};

export default RelatedPostsTester;