// Component that defines the preview for a search
// This is the dropdown that pops up below a search bar
// It needs to take in a parent ref (the search bar), as well as a boolean that controls whether or not to only show company search

import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';

const cache = new Map(); // In-memory cache for query results


const SearchPreview = ({query, parentRef, onlyShowCompanySearch, handleSearch, handleClick, previewTextOverride, setCompany}) => {
    const { serverAddress } = useAuth();
    // handleSearch is an optional function that defines what to do if the user clicks the
    // search preview button. By default, it will do nothing.
    // Currently, the SearchBar component uses this to search for the term in the search bar.
    if (handleSearch === undefined || handleSearch === null) {
        handleSearch = () => {};
    }

    // handleClick is a function that can be passed in to handle what happens when a user clicks on a search result
    // It is passed down to the SearchPreviewResultCompany component
    if (handleClick === undefined) {
        handleClick = null;
    }

    const [searchPreview, setSearchPreview] = useState([]);
    const searchPopupRef = useRef(null);
    const [popupWidth, setPopupWidth] = useState(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [toplinePreviewText, setToplinePreviewText] = useState('');
    const [focusedItem, setFocusedItem] = useState(0);
    const [isMouseHovering, setIsMouseHovering] = useState(false);
    const latestQueryRef = useRef(query);


    // Update the ref whenever the query changes
    useEffect(() => {
        latestQueryRef.current = query;
    }, [query]);

    const searchCompanies = async (query) => {
        if (cache.has(query)) {
            // Use cached result if it exists
            console.log('Using cached results for:', query);
            setSearchPreview(cache.get(query));
            return;
        }
    
        // Fetch from the server
        const response = await fetch(serverAddress + 'search-companies', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                query: query,
            }),
        });
    
        const data = await response.json();
        if (!response.ok) {
            // Suppress error logging
            setSearchPreview([]);
        } else {
            console.log('Fetched companies:', data.data);
            setSearchPreview(data.data);
    
            // Store the result in the cache
            cache.set(query, data.data);
        }
    };

    // Debounced wrapper for search
    const useDebouncedSearch = (query, delay) => {
        const [debouncedQuery, setDebouncedQuery] = useState(query);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedQuery(query);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [query, delay]);

        return debouncedQuery;
    };

    const debouncedQuery = useDebouncedSearch(query, 300); // Debounce with 300ms delay


    useEffect(() => {
        if (debouncedQuery) {
            searchCompanies(debouncedQuery); // Call the search function with debounced query
        }
    }, [debouncedQuery]);
        

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
          if (entries && entries.length > 0) {
            const entry = entries[0];
            const rect1 = entry.target.getBoundingClientRect();
    
            const x = rect1.left;
            const y = rect1.bottom + window.scrollY;

            // width: 
            const width = rect1.width;
            setPopupWidth(width);
    
            setPosition({ x, y });
          }
        });
    
        if (parentRef.current) {
          resizeObserver.observe(parentRef.current);
        }
    
        return () => {
          resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        searchCompanies(query);
        if (previewTextOverride) {
            setToplinePreviewText(previewTextOverride);
        }
        else {
            setToplinePreviewText('Search for Scuttlebutt about "' + query + '"');
        }
    }, [query]);

    // add listeners for the up arrow and down arrow - print message to console

    useEffect(() => {
        const onKeyPress = (e) => {
            if (e.key === 'ArrowUp') {
                console.log('up arrow pressed');
                if (searchPopupRef.current) {
                    const newFocusedItem = focusedItem - 1 < 0 ? searchPreview.length : focusedItem - 1;
                    setFocusedItem(newFocusedItem);
                }
            } else if (e.key === 'ArrowDown') {
                console.log('down arrow pressed');
                if (searchPopupRef.current) {
                    const newFocusedItem = (focusedItem + 1) > searchPreview.length ? 0 : focusedItem + 1;
                    setFocusedItem(newFocusedItem);
                }
            }
            else if (e.key === 'Enter') {
                console.log('enter pressed');
                if (setCompany) {
                    setCompany(searchPreview[focusedItem]);
                }
                if (focusedItem === 0) {
                    handleSearch({query: latestQueryRef.current});
                }
                else {
                    clickedButton(searchPreview[focusedItem - 1]);
                    
                }
            }
        }

        window.addEventListener('keyup', onKeyPress);

        return () => {
            window.removeEventListener('keyup', onKeyPress);
        }
    }, [focusedItem, searchPreview.length, handleSearch]);

    useEffect(() => {
        console.log('focused item:', focusedItem);
        if (searchPreview.length > 0) {
            const searchPreviewItems = searchPopupRef.current.getElementsByClassName('search-preview-item');
            console.log(searchPreviewItems);
            if (searchPreviewItems.length > 0 && focusedItem >= 0) {
                // Remove 'focused' class from all items
                Array.from(searchPreviewItems).forEach(item => item.classList.remove('focused'));
                // Add 'focused' class to the focused item
                if (focusedItem >= 0) {
                    const focusedElement = searchPreviewItems[focusedItem];
                    focusedElement.classList.add('focused');
                    
                    // Scroll the focused element into view
                    focusedElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'start'
                    });
                }
            }
        }
    }, [focusedItem, searchPreview]);

    const clickedButton = (company) => {
        console.log('clicked button');
        if (handleClick !== null && company) {
            console.log('handleClick:', company);
            handleClick(company);
        } else{
            // navigate to company page
            window.location.href = `/company/${company.id}`;
        }
    }
    

    return (
        <div>
            {searchPreview.length > 0 &&
                <div 
                    className='search-bar-results' 
                    style={{
                        width: popupWidth, 
                        position: 'absolute',
                        left: `${position.x}px`,
                        top: `${position.y}px`
                    }} 
                    ref={searchPopupRef}
                >
                    <div className='search-preview-results'>
                        {!onlyShowCompanySearch &&
                            <div 
                                key='text-search' 
                                className={`search-preview-item-container ${focusedItem === 0 ? 'focused' : ''}`}
                                onMouseEnter={() => setFocusedItem(0)}
                            >
                                <div className='search-preview-item'>
                                    <button 
                                        className='company-button' 
                                        onClick={() => handleSearch({query: query})}
                                    >
                                    {toplinePreviewText}
                                    </button>
                                    <div className='search-preview-spacer'></div>
                                </div>
                            </div>
                        }
                        {searchPreview.map((company, index) => {
                            const itemIndex = onlyShowCompanySearch ? index : index + 1;
                            return (
                            <div 
                                key={company.id} 
                                className={`search-preview-item-container ${itemIndex === focusedItem ? 'focused' : ''}`}
                                onMouseEnter={() => setFocusedItem(itemIndex)}
                                onClick={() => clickedButton(company)}
                            >
                                <div className='search-preview-item clickable'>
                                    {company.name} {company.ticker && `(${company.ticker})`}
                                </div>
                            </div>
                        );
                    })}
                    </div>
                </div>
            }
        </div>
    )
};

export default SearchPreview;