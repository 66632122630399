import { useState, useEffect} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useLocation } from 'react-router-dom';
import { useApiDataContext } from './ApiDataContext';


function HeaderBarPlain(props) {
    
    const {isMobile} = useApiDataContext();
    const [isSearchPage, setIsSearchPage] = useState(false);
    
    const paddingLeft = (isMobile || !isSearchPage) ? '0%' : '8.5%';

    return (
        <div className='header-bar-container'>
            <div className='header-bar'>
                <div className='toolbar-buttons-left' style={{paddingLeft: paddingLeft}}>
                    <div className='buttons-left'>
                        <div> 
                            <h1 className='navbar-header-title'> 
                                <a href={props.navIconLink || "/"} > 
                                    <img src='/img/favicon-navbar.png' /> 
                                </a> 
                            </h1> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}   

export default HeaderBarPlain;