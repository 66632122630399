// page that shows the payment confirmation

import React, {useState, useEffect, useContext} from 'react';
import {useAuth} from '../components/AuthContext';
import  supabase  from '../components/supabaseClient'; 
import TwitterHeaderBar from './TwitterHeaderBar';
import { CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import { ModalContext } from "../App";
import Login from '../scenes/Login';

const PaymentConfirmation = () => {
    const {userId, isMobile, scuttlesiftStatus, setScuttlesiftStatus} = useAuth();
    const [saved, setSaved] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    // const { openLoginModal } = useContext(ModalContext); // Add this line

    console.log(userId);


    // if the user is on this page, they have just paid - they may have refreshed it
    // so we need to update the user's subscription status
    const updateSubscriptionStatus = async () => {
        if (scuttlesiftStatus === 'pro') {
            return;
        }
        const {data, error} = await supabase
            .from('scuttlesift_status')
            .upsert({status: 'pro', user_id: userId});
        if (error) {
            console.log(error);
        } else {
            setSaved(true);
            setScuttlesiftStatus('pro');
        }
    };

    useEffect(() => {
        if (userId) {
            setShowLogin(false);
            updateSubscriptionStatus();
        } else {
            setShowLogin(true);
        }
    }, [userId]);

    return (
        <div className='centered-container'>
            <TwitterHeaderBar isMobile={isMobile} isLandingPage={false} />
            <br />

            {!showLogin && 
                <div className='centered-container'>
                    <h1>Payment Confirmation</h1>
                    <br />
                {saved && 
                    <div className='centered-container'>
                        <div className='centered'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                                <CheckCircleOutlineIcon style={{ color: '#1042c1', fontSize: '48px' }} />
                            </div>
                            Payment confirmed
                            <br />
                            <br />
                        </div>
                        <h1>You are now a ScuttleSift Pro user.</h1>
                        <button className='button-primary'
                            onClick={() => {
                                window.location.href = '/twitter-search-tool';
                            }}
                        >
                            Get Started
                        </button>
                    </div>
                }
                {!saved && 
                    <div className='centered-container'>
                        <h1>Payment Confirmation</h1>
                        <br />
                        <CircularProgress />
                    </div>
                }
                </div>
            }
            

            {showLogin && 
                <div className='login-container'>
                    <h1 style={{textAlign: 'center'}}>Thanks so much for subscribing! </h1>
                    <br/>
                    <h4>To access your Pro features, please create an account or log in.</h4>
                    <Login />
                </div>
            }
        </div>
    );
};

export default PaymentConfirmation;