import { useContext } from 'react';
import { useAuth } from './AuthContext';
import { ModalContext } from "../App";
import SearchIcon from '@mui/icons-material/Search';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation } from 'react-router-dom';
import { useApiDataContext } from './ApiDataContext';

// Move routes to constants outside component
const ROUTES_WITH_NO_HEADER = new Set([
    '/',
    '/twitter-landing',
    '/advanced-feature-pricing',
    '/twitter-pricing',
    '/twitter-search-tool',
    '/payment-confirmation',
    '/witg',
    '/witg-signup',
    '/witg-intake'
]);

// Add new constant for routes that should use the landing page header
const ROUTES_WITH_LANDING_HEADER = new Set([
    '/',
    '/case-study/NPS',
    '/case-study/leaver-joiner'
]);

const ROUTES_TO_HIDE_SIGNUP = new Set([
    '/book-demo'
]);

function HeaderBar({searchPage}) {
    const { userId } = useAuth();
    const modalContext = useContext(ModalContext);
    const {isMobile} = useApiDataContext();
    const location = useLocation();

    // Early return if we're on a route that shouldn't show the header
    // AND it's not a case study page
    if (ROUTES_WITH_NO_HEADER.has(location.pathname) && 
        !location.pathname.startsWith('/case-study/')) {
        return null;
    }

    // If this is a landing page or case study, use the landing page header style
    if (ROUTES_WITH_LANDING_HEADER.has(location.pathname) || 
        location.pathname.startsWith('/case-study/')) {
        return null;
    }

    const padding = isMobile ? '5px' : '20px';
    const isSearchPage = false; // Since routesToDisableSearch is empty, we can simplify this
    const paddingLeft = (isMobile || !isSearchPage) ? '0%' : '8.5%';

    return (
        <div className='header-bar-container'>
            <div className='header-bar'>
                <div className='toolbar-buttons-left' style={{paddingLeft: paddingLeft}}>
                    <div className='buttons-left'>
                        {!isSearchPage && (
                            <div>
                                <h1 className='navbar-header-title'>
                                    <a href="/">
                                        <img src='/img/favicon-navbar.png' alt="Logo" />
                                    </a>
                                </h1>
                            </div>
                        )}
                        {userId &&
                        <div className='buttons-hidden'>
                            <a href='/search'>
                                <div className='toolbar-button centered-icon-button'>
                                    <div className="button-icon">
                                        <SearchIcon />
                                    </div>
                                    <div className={`icon-button-label ${!isMobile ? 'label-padded' : ''}`}>{isMobile ? '' : 'Search'}</div>
                                </div>
                            </a>
                        </div>}
                    </div>
                </div>

                <div className='toolbar-buttons-right' style={{paddingRight: padding}}>
                    {userId && (
                        <div className='buttons-with-user'>
                            <div className='my-bookmarks-section'>
                                <a href='/bookmarks'>
                                    <div className='toolbar-button centered-icon-button'>
                                        <div className="button-icon">
                                            <BookmarkBorderIcon />
                                        </div>
                                        <div className={`icon-button-label ${!isMobile ? 'label-padded' : ''}`}>{isMobile ? '' : 'Bookmarks'}</div>
                                    </div>
                                </a>

                                <a href='/account'>
                                    <div className='toolbar-button centered-icon-button'>
                                        <div className="button-icon">
                                            <AccountCircleIcon />
                                        </div>
                                        <div className={`icon-button-label ${!isMobile ? 'label-padded' : ''}`}>{isMobile ? '' : 'Account'}</div>
                                    </div>
                                </a>
                            </div> 
                        </div>
                    )}
                    {!userId && !ROUTES_TO_HIDE_SIGNUP.has(location.pathname) && (
                        <button 
                            className='toolbar-button sign-up-button'
                            onClick={() => modalContext.openLoginModal(true)}
                        >
                            Sign Up
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default HeaderBar;