import React, { useEffect } from 'react';
import { Navigation } from "../components/Landing/navigation";
import Footer from "../components/Landing/Footer";
import JsonData from "../data/data.json";
import { Outlet, useLocation } from 'react-router-dom';

const LandingLayout = () => {
    const location = useLocation();

    useEffect(() => {
        // If there's a hash in the URL
        if (location.hash) {
            // Wait a bit for the page to load
            setTimeout(() => {
                const element = document.querySelector(location.hash);
                if (element) {
                    const headerOffset = 20;
                    const elementPosition = element.getBoundingClientRect().top;
                    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            }, 100);
        }
    }, [location]); // Re-run when location changes

    return (
        <div className="landing-layout">
            <Navigation 
                links={[
                    {href:'/#coverage', text: 'Examples'}, 
                    {href:'/#services', text: 'Features'}, 
                    {href:'/#philosophy', text: 'Philosophy'}
                ]} 
            />
            <Outlet />
            <Footer data={JsonData.Contact} />
        </div>
    );
};

export default LandingLayout; 