import React from 'react';
import PostV2 from './PostV2';

function PostDisplay({ header, data, showBookmarkButton, noResults, query, ratingThresholds, ratingDisplayName, sources }) {
    // Dedupe data array based on post IDs
    const dedupedData = [...new Map(data.map(post => [post.id, post])).values()];
    
    return (
        <div className="post-display">
            {dedupedData.length > 0 && (
                <div>
                    <h3>{header}</h3>
                    {dedupedData.map(post => (
                        <PostV2
                            key={post.id}
                            post={post}
                            showBookmarkButton={showBookmarkButton}
                            query={query}
                            ratingThresholds={ratingThresholds}
                            ratingDisplayName={ratingDisplayName}
                            sources={sources}
                            isTopLevel={true}
                        />
                    ))}
                </div>
            )}
            {noResults && (
                <div className='no-results'>
                    <p>No results found.</p>
                </div>
            )}
        </div>
    );
}

export default PostDisplay;
