import React from 'react';
import Modal from 'react-modal';
import Login from '../scenes/Login';
import '../App.css';
import CloseIcon from '@mui/icons-material/Close';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


function LoginModal({loginModalIsOpen, closeLoginModal, header}) {  
  return (
    <div className='login-modal'>
      <Modal
        isOpen={loginModalIsOpen}
        onRequestClose={closeLoginModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeLoginModal} className='modal-close-button'><CloseIcon /></button>
        <h4>{header}</h4>
        <Login closeLoginModal={closeLoginModal}/>
      </Modal>
    </div>
  );
}

export default LoginModal;