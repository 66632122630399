import React, { useState, useEffect } from 'react';
import PricingCard from './PricingCard';
import ReactGA from 'react-ga4';
import { useAuth } from '../components/AuthContext';

const TwitterPricing = () => {
    const {isMobile} = useAuth();
    const footer_content = '3-day free trial';

    const handleButtonClick = ({action}) => {
        console.log('clicked button: ', action);
        ReactGA.event({
            category: 'User',
            action: action
        });
    };

    return (
        <div className={`pricing-container vertical centered`}>
            {/* <div className='twitter-search-container' style={{paddingLeft:'10px'}}>
                <div className='twitter-search-header-container'>
                    <h1 className='twitter-search-header'> ScuttleSift </h1>
                    <h4 className='twitter-search-subheader'>by Scuttlebutt Research</h4>
                </div>
            </div> */}
            <div className={`pricing-box ${isMobile ? 'pricing-box-mobile' : ''}`}>
                <PricingCard isMobile={isMobile} 
                    data={{
                        price: 'Free',
                        period: '',
                        type_name: 'Starter',
                        feature_description: 'Search X Without the Spam',
                        features: [
                            'Basic search',
                            'Filters to remove 99% of spam',
                            'Search particular time periods'
                        ],
                        cta: 'Get Started (No CC Required)',
                        cta_type: 'neutral',
                        link: '/twitter-search-tool',
                        handle_click: () => handleButtonClick({action: 'starter_plan_cta'})
                    }
                } />

                <PricingCard isMobile={isMobile} 
                    data={{
                        price: '$19',
                        period: '/ month',
                        type_name: 'Pro',
                        feature_description: 'Everything in Starter, Plus:',
                        features: [
                            'Advanced search',
                            'Save custom searches',
                            'Create your own watchlist'
                        ],
                        cta: 'Put it on the corporate card!',
                        cta_type: '',
                        link: 'https://buy.stripe.com/28o5mha32fXw9EIfYY',
                        handle_click: () => handleButtonClick({action: 'pro_plan_cta'}),
                        footer: footer_content
                    }
                } />

                <PricingCard isMobile={isMobile} 
                    data={{
                        price: '$129',
                        period: '/ month',
                        type_name: 'Enterprise',
                        feature_description: 'Everything in Pro, Plus:',
                        features: [
                            'Share custom searches',
                            'Collaborative watchlists',
                            'Email digests'
                        ],
                        cta: 'Scale Up',
                        cta_type: 'extra',
                        link: 'https://buy.stripe.com/6oE6qleji3aK18ceUV',
                        handle_click: () => handleButtonClick({action: 'pro_plan_cta'}),
                        footer: footer_content
                    }
                } />

            </div>
            <br/>
        </div>
    );
};

export default TwitterPricing;