import React from 'react';

const ManagementTeam = ({ managerRoles }) => {
  return (
    <div className="management-team">
      <h2 className="management-team__title">Management Team</h2>
      <ul className="management-team__list">
        {managerRoles.map((role) => (
          <li key={role.id} className="management-team__item">
            <a
              href={`/managers/${role.manager.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="management-team__manager-link"
            >
              {role.manager.name}
            </a>
            <span className="management-team__role">{role.role}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManagementTeam;
