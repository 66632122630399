import react, {useEffect, useState} from 'react';


function TryNow({subtitle, link}) {
    return (
        <div className='try-now-section standard-width '>

            <div className='try-now-container' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <div className='try-now-text-container'>
                    <h2 className='try-now-title'>Ready to get started?</h2>
                    {/* <p className='try-now-subtitle'>Try it now for free!</p> */}
                </div>
                <a href={link || "/search"} className="try-now">
                    <button className="try-now-button">
                        Try It Now
                    </button>
                    <p className='try-now-subtitle'>{subtitle}</p>
                </a>

            </div>
        </div>

    );
}

export default TryNow;