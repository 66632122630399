import OpenAI from "openai";

const openai = new OpenAI(
    {
        organization: "org-u1rk2CfYNWVTKD5fHktGCcZ3",
        apiKey: "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",
        dangerouslyAllowBrowser: true
    }
);

const oaiCompletion = async (systemPrompt, userPrompt, json_schema=undefined) => {
    const completion = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
            { role: "system", content: systemPrompt },
            {
                role: "user",
                content: userPrompt,
            },
        ],
        response_format: json_schema ? { type: "json_schema", json_schema: {"strict":true, "schema": json_schema }} : undefined,
    });

    return completion.choices[0].message.content;
}

const extractKeywords = async (text, asString=false) => {
    const query = "Extract keywords from the following text. Return ONLY an array of strings. You should only return unique keywords, such as product/brand names, names of people, etc."
    const keywords = await oaiCompletion(query, text);
    // Parse the keywords string into an array
    const keywordsArray = JSON.parse(keywords);
    console.log('keywordsArray: ', keywordsArray);
    return asString ? keywordsArray.join(', ') : keywordsArray;
}

export default oaiCompletion;
export { extractKeywords }; 