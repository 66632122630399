import SmoothScroll from "smooth-scroll";
import { useState, useEffect } from "react";

const CitationScroller = () => {
    const scroll = new SmoothScroll();

    const customScroll = (target) => {
        const element = document.querySelector(target);
        if (element) {
            scroll.animateScroll(element, null, {
                speed: 1000,
                speedAsDuration: true,
                offset: 80,
            });
            
        // Add inline CSS to the element
        if (element) {
            element.classList.add('highlighted-post');
            setTimeout(() => {
                element.classList.remove('highlighted-post');
            }, 3000);
        }
        }
    };

    useEffect(() => {
        const handleClick = (event) => {
            const target = event.target.closest('a[href^="#"]');
            if (target) {
                event.preventDefault();
                customScroll(target.getAttribute('href'));
            }
        };

        const handleInitialScroll = () => {
            const hash = window.location.hash;
            if (hash) {
                const maxAttempts = 10;
                let attempts = 0;

                const tryScroll = () => {
                    const element = document.querySelector(hash);
                    if (element) {
                        customScroll(hash);
                    } else if (attempts < maxAttempts) {
                        attempts++;
                        setTimeout(tryScroll, 2000);
                    }
                };

                tryScroll();
            }
        };

        handleInitialScroll();

        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);
    return (
        <div>
            
        </div>
    );
};

export default CitationScroller;