import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useApiDataContext } from './ApiDataContext';

const AskQuestion = ({ folderId, topicName, show, sources, setSources }) => {
    const { queryPosts } = useApiDataContext();
    const [question, setQuestion] = useState('');
    const [questionAnswer, setQuestionAnswer] = useState('');
    const [answerLoading, setAnswerLoading] = useState(false);

    const answerQuestion = (question) => {
        if (!question) return;
        setAnswerLoading(true);
        queryPosts(question, folderId).then(res => {
            console.log('question answer: ', res);
            setQuestionAnswer(res.answer);
            setAnswerLoading(false);
        });
    };

    useEffect(() => {
        if (questionAnswer) {
            console.log('Answer:', questionAnswer);

            // Extract source IDs from the answer
            const sourceIdRegex = /<a href="#([^"]+)">/g;
            const newSourceIds = [...questionAnswer.matchAll(sourceIdRegex)]
                .map(match => match[1])
                .filter((id, index, self) => self.indexOf(id) === index); // Remove duplicates

            console.log('Extracted source IDs:', newSourceIds);

            // Combine new sources with existing sources, removing duplicates
            setSources(prevSources => {
                const combinedSources = [...new Set([...prevSources, ...newSourceIds])];
                return combinedSources;
            });
        }
    }, [questionAnswer]);

    if (!show) return null;

    return (
        <div className='question-answer-container open'>
            <div className='question-input-container'>
                <input
                    type='text'
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder={`Ask ${questionAnswer ? 'another' : 'a'} question about ${topicName}`}
                    className='question-input'
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            answerQuestion(question);
                        }
                    }}
                />
                <div className='qa-buttons-below'>
                    <button className='blue-button centered-icon-button qa-button' onClick={() => answerQuestion(question)}>
                        Ask Question
                    </button>
                </div>

                {answerLoading && (
                    <div className='progress-indicator' style={{height: '60px', paddingTop: '0px', paddingBottom: '10px'}}>
                        <CircularProgress />
                    </div>
                )}
                {!answerLoading && questionAnswer && 
                    <div dangerouslySetInnerHTML={{ __html: questionAnswer }} />
                }
            </div>
        </div>
    );
};

export default AskQuestion;