import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import './Article.css';

const Article = ({ 
  title,
  subtitle,
  markdown,
  heroImage,
  heroImageMobile,
  callToAction
}) => {
  // Pre-process markdown to handle our custom image syntax
  const processedMarkdown = markdown?.replace(
    /!\[(.*?)(?:\|max-width:(\d+)px)?\]\((.*?)\)/g,
    (match, alt, maxWidth, src) => {
      const [desktopSrc, mobileSrc] = src.split('||').map(s => s.trim());
      const styleAttr = maxWidth ? ` style="max-width: ${maxWidth}px"` : '';
      
      return `<div class="article-image-container">
        ${mobileSrc ? 
          `<img alt="${alt}" src="${desktopSrc}" class="article-image desktop-image"${styleAttr} />
           <img alt="${alt}" src="${mobileSrc}" class="article-image mobile-image"${styleAttr} />` 
          : 
          `<img alt="${alt}" src="${desktopSrc}" class="article-image"${styleAttr} />`
        }
        ${alt ? `<p class="article-image-caption">${alt}</p>` : ''}
      </div>`;
    }
  );

  return (
    <article className="article">
      {heroImage && (
        <div className="article-hero">
          <img
            src={heroImage}
            alt={title}
            className="desktop-hero"
          />
          {heroImageMobile && (
            <img
              src={heroImageMobile}
              alt={title}
              className="mobile-hero"
            />
          )}
        </div>
      )}
      
      <div className="article-container">
        <header className="article-header">
          <h1 className="article-title">{title}</h1>
          {subtitle && <p className="article-subtitle">{subtitle}</p>}
        </header>

        <div className="article-content">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {processedMarkdown}
          </ReactMarkdown>
        </div>

        {callToAction && (
          <div className="article-cta">
            {callToAction}
          </div>
        )}
      </div>
    </article>
  );
};

Article.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  markdown: PropTypes.string.isRequired,
  heroImage: PropTypes.string,
  heroImageMobile: PropTypes.string,
  callToAction: PropTypes.node
};

export default Article;