import '../styles/SupportReps.css';

import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, Title, Tooltip, Legend, ScatterController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import supportRepData from '../data/support_rep_average_scores.json';
import jstat from 'jstat';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  ScatterController,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

// This is a scene that shows the average scores for each support rep
// It is currently just used custom for NinjaTrader, but could easily expand
// The data is static at src\data\support_rep_average_scores.json
// I want to show confidence intervals for each support rep
// It should be a bar chart with the average score and the confidence interval
// Confidence internals need to be based on sample size.

// Schema:
// [
//     {
//       "name": "Tom",
//       "average_score": 10.0,
//       "number_of_reviews": 2,
//       "all_scores": [
//         10.0,
//         10.0
//       ],
//       "reviews": [
//         {
//           "first_name": "Tom",
//           "last_name": null,
//           "assessment": 10,
//           "explanation": "Tom is praised for providing a very educational morning program on YouTube, which the reviewer enjoys.",
//           "url": "https://www.trustpilot.com/reviews/66f5b43c11201f3acb6fcf39"
//         },
//         {
//           "first_name": "Mike",
//           "last_name": "Scinto",
//           "assessment": 10,
//           "explanation": "The reviewer specifically mentions Mike Scinto and suggests that he deserves a pay rise, indicating a high level of satisfaction with his performance.",
//           "url": "https://www.trustpilot.com/reviews/62dc167d4c35e69ec517ae6a"
//         }
//       ]
//     },


const calculateBayesianConfidenceInterval = (scores, confidenceLevel = 0.95) => {
    // Prior distribution parameters (assuming a beta distribution)
    const scalar = 1;
    const priorAverage = 7;

    // 7.2 and 3 are the average score and standard deviation for the prior
    const priorAlpha = priorAverage*scalar;
    const priorBeta = (10-priorAverage)*scalar;

    // Calculate sample mean and count for this rep
    const sampleMean = scores.reduce((sum, score) => sum + score, 0) / scores.length;
    const sampleCount = scores.length;

    // Combine prior with sample data
    const posteriorAlpha = priorAlpha + (sampleMean / 10) * sampleCount;
    const posteriorBeta = priorBeta + (1 - sampleMean / 10) * sampleCount;
    
    // Calculate posterior mean and credible interval
    const posteriorMean = (posteriorAlpha / (posteriorAlpha + posteriorBeta)) * 10;
    const lowerBound = jstat.beta.inv((1 - confidenceLevel) / 2, posteriorAlpha, posteriorBeta) * 10;
    const upperBound = jstat.beta.inv(1 - (1 - confidenceLevel) / 2, posteriorAlpha, posteriorBeta) * 10;

    return {
        mean: posteriorMean,
        interval: [lowerBound, upperBound]
    };
};

const calculateScore = (mean, interval) => {
    const [lower, upper] = interval;
    const lowValuePenalty = Math.max(0, 5 - lower) * 0.2; // Penalty for lower bound below 5
    return mean - lowValuePenalty;
};

const SupportReps = () => {
    const [chartData, setChartData] = useState(null);
    const [overallAverage, setOverallAverage] = useState(0);
    const [rankedData, setRankedData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [selectedRep, setSelectedRep] = useState(null);
    const [showAllReviews, setShowAllReviews] = useState(false);
    const chartRef = useRef(null);

    useEffect(() => {
        const processData = () => {
            const allScores = supportRepData.flatMap(rep => rep.all_scores);
           
 
            const newProcessedData = supportRepData.map(rep => {
                const { mean, interval } = calculateBayesianConfidenceInterval(rep.all_scores);
                const score = calculateScore(mean, interval);
                return {
                    ...rep,
                    confidenceInterval: interval,
                    posteriorMean: mean,
                    score: score
                };
            });

            const avgScore = newProcessedData.reduce((sum, rep) => sum + rep.score, 0) / newProcessedData.length;
            setOverallAverage(avgScore);


            // Rank the data
            const newRankedData = [...newProcessedData].sort((a, b) => b.score - a.score);
            console.log(newRankedData);
            setRankedData(newRankedData);
            setProcessedData(newProcessedData);
            
            setChartData({
                labels: newRankedData.map(rep => rep.name),
                datasets: [
                    {
                        type: 'scatter',
                        label: 'Score',
                        data: newRankedData.map(rep => ({
                            x: rep.score,
                            y: rep.name
                        })),
                        backgroundColor: 'rgba(255, 99, 132, 1)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 2,
                        pointStyle: 'circle',
                        pointRadius: 3,
                        pointHoverRadius: 8,
                    },
                    {
                        type: 'bar',
                        label: 'Confidence Interval',
                        data: newRankedData.map(rep => ({
                            x: [rep.confidenceInterval[0], rep.confidenceInterval[1]],
                            y: rep.name
                        })),
                        backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    }

                ]
            });
        };

        processData();
    }, []);

    const options = {
        indexAxis: 'y',
        responsive: true,
        scales: {
            x: {
                beginAtZero: true,
                max: 10,
            },
            y: {
                ticks: {
                    autoSkip: false,
                    font: {
                        size: 10
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const rep = rankedData[context.dataIndex];
                        if (rep && rep.confidenceInterval) {
                            const [lower, upper] = rep.confidenceInterval;
                            return `Avg: ${rep.average_score.toFixed(2)}, CI: ${lower.toFixed(2)} - ${upper.toFixed(2)}, N: ${rep.number_of_reviews}, Score: ${rep.score.toFixed(2)}`;
                        }
                        return '';
                    }
                }
            },
            legend: {
                display: true,
                labels: {
                    filter: (legendItem) => legendItem.text !== 'Confidence Interval'
                }
            },
            annotation: {
                annotations: {
                    averageLine: {
                        type: 'line',
                        xMin: overallAverage,
                        xMax: overallAverage,
                        borderColor: 'red',
                        borderWidth: 2,
                        borderDash: [6, 6],
                        label: {
                            content: `Average: ${overallAverage.toFixed(2)}`,
                            enabled: true,
                            position: 'start'
                        }
                    }
                }
            }
        },
        maintainAspectRatio: false // Add this line
    };

    const handleClick = (event) => {
        const chart = chartRef.current;
        if (!chart) {
            return;
        }

        const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
        if (elements.length > 0) {
            const { datasetIndex, index } = elements[0];
            console.log("Clicked element:", datasetIndex, index);
            const clickedRep = rankedData[index];
            console.log("Clicked rep:", clickedRep);
            setSelectedRep(clickedRep);
            setShowAllReviews(false);
        }
    };

    const renderRepDetails = () => {
        console.log("Rendering rep details, selectedRep:", selectedRep);
        if (!selectedRep) return null;

        return (
            
            <div className="rep-card">
            <h2 className="rep-name">{selectedRep.name}</h2>
            <div className="rep-stats">
                <div className="stat-item">
                <p className="stat-label">Average Rating</p>
                <p className="stat-value">{selectedRep.average_score.toFixed(1)}</p>
                </div>
                <div className="stat-item">
                <p className="stat-label">Average Trustpilot Score</p>
                <p className="stat-value">{(selectedRep.reviews.reduce((sum, review) => sum + review.rating, 0) / selectedRep.reviews.length).toFixed(1)}</p>
                </div>
                <div className="stat-item">
                <p className="stat-label">Number of Reviews</p>
                <p className="stat-value">{selectedRep.number_of_reviews}</p>
                </div>
                <div className="stat-item">
                <p className="stat-label">Bayesian Score</p>
                <p className="stat-value">{selectedRep.score.toFixed(2)}</p>
                </div>
                <div className="stat-item">
                <p className="stat-label">Confidence Range</p>
                <p className="stat-value">{selectedRep.confidenceInterval[0].toFixed(2)} - {selectedRep.confidenceInterval[1].toFixed(2)}</p>
                </div>

            </div>
                <div className="feedback-section">
                    <h3>Feedback</h3>
                    <div className="feedback-content">
                        <h4>Strengths</h4>
                        <p>{selectedRep.strengths || 'No strengths provided'}</p>
                    </div>
                    <div className="feedback-content">
                        <h4>Weaknesses</h4>
                        <p>{selectedRep.weaknesses || 'No weaknesses provided'}</p>
                    </div>
                    <div className="feedback-content">
                        <h4>Summary</h4>
                        <p>{selectedRep.summary || 'No summary provided'}</p>
                    </div>
                </div>
                <button onClick={() => setShowAllReviews(!showAllReviews)}>
                    {showAllReviews ? 'Hide Reviews' : 'Show All Reviews'}
                </button>
                {showAllReviews && (
                    <div className="all-reviews">
                        <h3>All Reviews</h3>
                        {selectedRep.reviews.map((review, index) => (
                            <div key={index} className="review">
                                <p>Assessment: {review.assessment}</p>
                                <p>Explanation: {review.explanation}</p>
                                <a href={review.url} target="_blank" rel="noopener noreferrer">View on Trustpilot</a>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    console.log("Current selectedRep:", selectedRep);

    return (
        <div className='centered-container support-reps-page'>
            <br/>
            <div className='standard-width'>
                <h1>Support Reps Performance (Ranked)</h1>
                <div style={{ height: '800px' }}>
                    {chartData && <Bar ref={chartRef} data={chartData} options={options} onClick={handleClick} />}
                </div>
                {renderRepDetails()}
            </div>
            <br/>
        </div>
    );
};

export default SupportReps;