import React, { useState, useEffect } from 'react';
import supabase from '../components/supabaseClient'; // Adjust the import path as needed
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router

function UpdatePassword() {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        // Redirect to login page if not authenticated
        navigate('/login');
      }
    };
    checkAuth();
  }, [navigate]);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      setMessage('Password updated successfully.');
      // Optionally, redirect to another page after successful update
      // navigate('/profile');
    } catch (error) {
      setMessage('Error: ' + error.message);
    }
  };

  return (
    <div>
      <h2>Update Password</h2>
      <form onSubmit={handleUpdatePassword}>
        <input
          type="password"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <button type="submit">Update Password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default UpdatePassword;