import React, { useState } from 'react';
import supabase from '../components/supabaseClient'; // Adjust the import path as needed

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const { error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: '/account/update-password',
            });
            
            if (error) throw error;
            setMessage('Password reset email sent. Check your inbox.');
        } catch (error) {
            setMessage('Error: ' + error.message);
        }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      <form onSubmit={handleResetPassword}>
        <input
          type="email"
          placeholder="Your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Reset Password</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ResetPassword;